<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Menu" [class.Open]="MenuOpen">
        <div class="Content">
            <div class="BtnClose" (click)="ToggleMenu()">X</div>
            <div class="Btns">
                <div class="BtnsContent">
                    <div class="Btn" [routerLink]="['/', App.AppId, 'Main', 'Episodes']" *ngIf="App.AllChapterBtn">
                        {{ Resource.Get("AllChapters") }}
                    </div>
                    <div class="Btn" [routerLink]="['/', App.AppId, 'Main', 'MyGroup']" *ngIf="App.PlayGroups">
                        {{ Resource.Get("MyGroup") }}
                    </div>
                    <div class="Btn" [routerLink]="['/', App.AppId, 'Main', 'Points']" *ngIf="!LoginService?.Anonymous">
                        {{ Resource.Get("PersonalArea") }}
                    </div>
                    <div class="Btn" [routerLink]="['/', App.AppId, 'Main', 'Champions']" *ngIf="!LoginService?.Anonymous">
                        {{ Resource.Get("HighScores") }}
                    </div>
                    <div class="Btn" [routerLink]="['/', App.AppId, 'Main', 'About']">
                        {{ Resource.Get("About") }}
                    </div>
                    <div class="Btn" (click)="Logout()">
                        {{ Resource.Get("LogOut") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
