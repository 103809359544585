import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LoginService } from "../Services/Login.service";
import { Observable } from "rxjs/Observable";
import { AppService } from "../../Shared/Services/App.service";


@Injectable()
export class LoginGuard implements CanActivate {
   // private previousUrl: string = undefined;
   // private currentUrl: string = undefined;
  // public test = 1;
    constructor(private LoginService: LoginService, private Router: Router, private activatedRoute: ActivatedRoute, public App: AppService) { 

        //this.currentUrl = this.Router.url;
        // Router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         this.previousUrl = this.currentUrl;
        //         this.currentUrl = event.currentUrl;
        //     }
        // });
    }

    public canActivate(Route: ActivatedRouteSnapshot, State: RouterStateSnapshot): Observable<boolean> {
        // this.activatedRoute.queryParams.subscribe(params => {
          
        //     console.log(params);
        //   });
        
        //console.log(this.currentUrl);
        //window.alert("hi3");
        return Observable.create(o => {
           // window.alert("hi3");
           // window.alert(Route.params.App);
            
           // console.log(Route.params.App);
          //  this.Router.navigateByUrl(State.url);
            // if(this.test == 0){
            //     this.Router.navigateByUrl("/2/Main/Home/768/DailyStudy/1524");
            // } else {
            //     this.LoginService.IsLoggedIn(Route.params.App || 1).subscribe(p => {
            //         console.log(p);
            //         o.next(p.IsLogin);
    
            //         if (p.status == 401)
            //         {
            //             this.Router.navigateByUrl("/" + (Route.params.App || 1) + "/Welcome");
            //         }
            //     });
            // }
            if(State.url.includes('DailyStudy')){
                
                this.LoginService.IsLoggedIn(Route.params.App || 1).subscribe(p => {
                   // console.log(p);
                   o.next(p.IsLogin);
    
                    if (p.status == 401)
                    {
                        this.LoginService.LoginAnonymous2(Route.params.App || 1).subscribe(s => {
                            if (s) {               
                                 this.Router.navigateByUrl(State.url);
                            }            
                        });
                    }
                });
            } else {
                this.LoginService.IsLoggedIn(Route.params.App || 1).subscribe(p => {
                   // console.log(p);
                    o.next(p.IsLogin);
    
                    if (p.status == 401)
                    {
                        this.Router.navigateByUrl("/" + (Route.params.App || 1) + "/Welcome");
                    }
                });
            }
            
        });
    }

    // public LoginAnonymous2() { 
    //     this.LoginService.LoginAnonymous().subscribe(p => {
    //         if (p) {               
    //             this.Router.navigate(["/", this.App.AppId, "Main", "Home", 768, "DailyStudy",1524]);
    //         }            
    //     });        
    //   };
}