import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MainModule } from "./Main/Main.module";
import { RoutingModule } from "./app.routes";
import { AppComponent } from './app.component';
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { FormsModule } from "@angular/forms"; 
import { APP_BASE_HREF } from '@angular/common';

import { SafeHtmlPipe } from "./Shared/Pipes/SafeHtml.pipe";

import { LoginService } from "./Shared/Services/Login.service";
import { PopupService } from "./Shared/Services/Popup.service";
import { DailyStudyService } from "./Shared/Services/DailyStudy.service";
import { CookiesService } from "./Shared/Services/Cookies.service";
import { AppService } from "./Shared/Services/App.service";
import { ResourcesService } from "./Shared/Services/Resources.service";
import { ScrollService } from "./Shared/Services/Scroll.service";

import { LoginGuard } from "./Shared/Guards/Login.guard";
import { NotFoundGuard } from "./Shared/Guards/NotFound.guard";
import { SplashGuard } from "./Shared/Guards/Splash.guard";
import { AppResolver } from "./Shared/Resolvers/App.resolver";
import { IsLoggedInResolver } from "./Shared/Resolvers/IsLoggedIn.resolver";

import { Model } from "./Shared/Services/Model.service";
import { DailyStudy } from "./Shared/Model/DailyStudy.model";
import { Users } from "./Shared/Model/Users.model";
import { Schools } from "./Shared/Model/Schools.model";
import { Groups } from "./Shared/Model/Groups.model";
import { Pages } from "./Shared/Model/Pages.model";
import { Nugets } from "./Shared/Model/Nugets.model";
import { PlayGroups } from "./Shared/Model/PlayGroups.model";

import 'rxjs/Rx';
import { Comments } from './Shared/Model/Comments.model';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpModule,
        RoutingModule,
        FormsModule,
        MainModule
    ],
    //for localhost
    // providers: [Model, DailyStudy, Users, LoginService, LoginGuard, Schools, Groups, Pages, Nugets, PlayGroups, PopupService, DailyStudyService, CookiesService, AppService, ResourcesService, ScrollService, AppResolver, IsLoggedInResolver, NotFoundGuard, SplashGuard, SafeHtmlPipe, Comments, { provide: "SystemURL", useValue: "http://localhost:4200/" }, { provide: "API", useValue: "http://localhost:11997/api" }], //http://localhost:11997/api // http://ec2-13-233-123-40.ap-south-1.compute.amazonaws.com/webapi/api
    //for test server
//    providers: [Model, DailyStudy, Users, LoginService, LoginGuard, Schools, Groups, Pages, Nugets, PlayGroups, PopupService, DailyStudyService, CookiesService, AppService, ResourcesService, ScrollService, AppResolver, IsLoggedInResolver, NotFoundGuard, SplashGuard, SafeHtmlPipe, Comments, { provide: "SystemURL", useValue: "http://demo.herzognew2.mailman.co.il/" }, { provide: "API", useValue: "http://demo.herzognew2.mailman.co.il/api" }],
    //for live site
  providers: [Model, DailyStudy, Users, LoginService, LoginGuard, Schools, Groups, Pages, Nugets, PlayGroups, PopupService, DailyStudyService, CookiesService, AppService, ResourcesService, ScrollService, AppResolver, IsLoggedInResolver, NotFoundGuard, SplashGuard, SafeHtmlPipe, Comments, { provide: "SystemURL", useValue: "https://herzognew2.mailman.co.il/" }, { provide: "API", useValue: "https://herzognew2.mailman.co.il/api" }],
   // bootstrap: [AppComponent]
    bootstrap: [AppComponent]
})
export class AppModule { }
