import { Injectable, Inject } from "@angular/core";
import { Http, URLSearchParams, Headers } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";
import { NugetData } from "../Interfaces/Nugets.interfaces";

@Injectable()
export class Nugets {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private App: AppService) { }

    public ShowSplashScreen(AppId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + AppId.toString()) != "" && localStorage.getItem("Token" + AppId.toString()) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + AppId.toString()));
        }

        let Params = new URLSearchParams();
        Params.append("App", AppId.toString());

        return this.Http.get(`${this.API}/Nugets/ShowSplashScreen`, { search: Params, headers: this.headers }).map(p => p.json());
    }

    public GetNugetInfo(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        let Params = new URLSearchParams();
        Params.append("App", this.App.AppId.toString());

        return this.Http.get(`${this.API}/Nugets/GetNugetInfo`, { search: Params, headers: this.headers, withCredentials: true }).map(p => p.json());
    }


    public GetNugetLang(): Observable<NugetData> {
        let Params = new URLSearchParams();
        Params.append("App", this.App.AppId.toString());

        return this.Http.get(`${this.API}/Nugets/GetNugetLang`, { search: Params, headers: this.headers, withCredentials: true }).map(p => p.json());
    }
}
