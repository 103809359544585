<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Container">
        <div class="Top" *ngIf="!App.PlayGroups">
            <div class="BtnClose" (click)="Close()">X</div>
            <div class="Profile">
                <div class="ProfileImg" [style.background-image]="ProfilePicURL"></div>
                <div class="Name">{{ UserName }}</div>
                <div class="TotalPoints">
                    <div class="Icon"></div>
                    {{ TotalPoints }} {{ Resource.Get("Points") }}
                </div>
            </div>
            <div class="TotalLearned">
                <h1>{{ Resource.Get("YouHaveLearnedMsg").replace("{0}",TotalLearned.toString()) }}</h1>
                <h2>{{ Resource.Get("OfPublishedMsg").replace("{0}",TotalDailyStudyAvailable.toString()) }}</h2>
            </div>
        </div>
        <div class="Top PlayGroups" *ngIf="App.PlayGroups">
            <div class="BtnClose" (click)="Close()">X</div>
            <div class="Profile">
                <div class="ProfileImg" [style.background-image]="ProfilePicURL"></div>
                <div class="Name">{{ UserName }}</div>
                <div class="PlayGroup">{{ Resource.Get("Group") }} {{ GroupName }}</div>
                <div class="TotalPoints">
                    <div class="My Points">
                        {{ TotalPoints }}<br />
                        <span class="Small">{{ Resource.Get("MyPoints") }}</span>
                    </div>
                    <div class="Cup"></div>
                    <div class="Group Points">
                        {{ TotalGroupPoints }}<br />
                        <span class="Small">{{ Resource.Get("GroupPoints") }}</span>
                    </div>
                </div>
            </div>
            <div class="TotalLearned">
                <h1>{{ Resource.Get("YouHaveLearnedMsg").replace("{0}",TotalLearned.toString()) }}</h1>
                <h2>{{ Resource.Get("OfPublishedMsg").replace("{0}",TotalDailyStudyAvailable.toString()) }}</h2>
            </div>
        </div>
        <div class="Points">
            <div class="Scroller">
                <div class="Bar" [class.Green]="(index + 1) % 4 == 1" [class.Orange]="(index + 1) % 4 == 2" [class.Blue]="(index + 1) % 4 == 3" [class.Purple]="(index + 1) % 4 == 0" *ngFor="let Item of DailyStudy;let index = index">
                    <div class="Title">{{ Item.Name }}</div>
                    <div class="Progress">
                        <div class="Content" [style.width]="(Item.TotalLearned / Item.TotalDailyStudies * 100) + '%'" *ngIf="Item.TotalLearned > 0">{{ Item.TotalLearned }}</div>
                    </div>
                    <div class="Total">
                        {{ Item.TotalLearned }} {{ Resource.Get("OutOf") }} {{ Item.TotalDailyStudies }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
