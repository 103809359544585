import { Inject, Injectable } from "@angular/core";
import { Http, URLSearchParams, Headers } from "@angular/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class PlayGroups {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, private Router: Router, @Inject("API") private API: string, private App: AppService) { }

    public IsPlayGroupExists(Code: string): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("App", this.App.AppId.toString());
        Params.append("Code", Code);

        return this.Http.get(`${this.API}/PlayGroups/IsPlayGroupExists`, { headers: this.headers, withCredentials: true, search: Params }).map(p => p.json());
    }

    public GetPlayGroupByCode(Code: string): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("App", this.App.AppId.toString());
        Params.append("Code", Code);

        return this.Http.get(`${this.API}/PlayGroups/GetPlayGroupByCode`, { headers: this.headers, withCredentials: true, search: Params }).map(p => p.json());
    }

    public GetCurrentPlayGroup(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/PlayGroups/GetCurrentPlayGroup`, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public GetCurrentPlayGroupMemebers(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/PlayGroups/GetCurrentPlayGroupMemebers`, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public GetMostPointsPlayGroups(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/PlayGroups/GetMostPointsPlayGroups`, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }
}