import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { Observer } from "rxjs/Observer";

@Injectable()
export class DailyStudyService {
    private Subject: Subject<any>;

    constructor() {
        this.Subject = new Subject<any>();
    }

    public GetListener(): Observable<any> {
        return this.Subject.asObservable();
    }

    public DailyStudyDone(DailyStudy: any): void {
        this.Subject.next({ Id: 1, Data: DailyStudy });
    }

    public LoadDailyStudyItemChildrens(DailyStudyId: number, DailyStudyItemId: number): void {
        this.Subject.next({
            Id: 2,
            Data: {
                DailyStudyId,
                DailyStudyItemId
            }
        });
    }
}