import { Component, ViewChild, ElementRef, NgZone, Inject } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Model } from "../../Shared/Services/Model.service";
import { LoginService, LoginResults } from "../../Shared/Services/Login.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";

import { AutoCompleteControlComponent } from "../AutoCompleteControl/AutoCompleteControl.component";
import { Observable } from "rxjs/Observable";
declare var EXIF: any;


@Component({
    templateUrl: "./Register.component.html",
    styleUrls: ["./Styles/Register.component.rtl.scss", "./Styles/Register.component.ltr.scss"]
})
export class RegisterComponent {
    public User = {
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        Mobile: "",
        Classroom: "",
        Classnumber: "",
        Roles: "תלמיד",
        SchoolId: -1
    };

    public Submitted: boolean = false;
    public Working: boolean = false;
    public Schools: Array<any>;
    public Cities: Array<any>;
    public Errors: any = {};
    public ErrorsList: Array<string> = [];
    public ImageFile: File;
    public UserImg: string;
    public Rotate: string;
    public RotationType: number;
    public LogoImg: SafeResourceUrl;
    dark: number = 1;
    isOpen = false;
    public SelectedSchool = {
        Title: "בית ספר",
        Id: -1
    };

    public SelectedCity = {
        Title: "בחר יישוב",
        Id: -1
    };

    @ViewChild("File")
    public FileUpload: ElementRef;

    constructor(private Model: Model, private Router: Router, private LoginService: LoginService, public App: AppService, public Resource: ResourcesService, private Zone: NgZone, private Sanitizer: DomSanitizer, @Inject("SystemURL") private SystemURL: string) {
        this.LogoImg = this.Sanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/Logos/${this.App.AppId}.jpg`);
    }

    private ngOnInit(): void {
        this.Working = false;

        if (this.App.PlayGroups && !this.LoginService.HaveLoginCode()) {
            this.Router.navigate(["/", this.App.AppId, "Welcome"]);
        }

        this.Model.Schools.GetCities().subscribe(p => {
            this.Schools = p;
        });
        this.Model.Nugets.GetNugetLang().subscribe(p => {

            this.App.EnableClass = p.EnableClass;

        });
    }

    public Register(Form: NgForm): void {
        this.Submitted = true;

        if (Form.valid) {
            this.Working = true;
            this.Model.Users.Register(this.User).subscribe(p => {
                if (p.Status == 1) {
                    this.LoginService.Login(this.User.Email, this.User.Password).subscribe(login => {
                        if (login.Success == LoginResults.Success || login.Success == LoginResults.AddNewGroup) {
                            if (this.ImageFile || (this.UserImg && this.UserImg != "")) {
                                this.Model.Users.UploadUserProfilePicture(this.ImageFile, this.RotationType, this.UserImg, this.User.Email, this.User.Password).subscribe(upload => {
                                    this.Zone.run(() => {
                                        this.Login(login);
                                    });
                                }, () => {
                                    alert(this.Resource.Get("UploadImgErr"));
                                    this.Login(login);
                                });
                            }
                            else {
                                this.Login(login);
                            }
                        } else {
                            alert(this.Resource.Get("RegisterErrorOccurred"));
                            this.Working = false;
                        }
                    });
                }
                else {
                    this.Working = false;
                }

                this.Errors = p.Errors;
                this.ErrorsList = [];

                if (this.Errors) {
                    for (var property in this.Errors) {
                        if (this.Errors.hasOwnProperty(property)) {
                            for (let i = 0; i < this.Errors[property].length; i++) {
                                this.ErrorsList.push(this.Errors[property][i]);
                            }
                        }
                    }
                }
                else {
                    this.Errors = {};
                }
            });
        }
    }

    public onFileChange(event: any) {
        this.Rotate = "";
        this.RotationType = 1;

        this.ImageFile = event.target.files[0];
        var reader = new FileReader();
        reader.onload = (e: any) => {
            let exif = EXIF.readFromBinaryFile(e.target.result);

            let r2 = new FileReader();

            r2.onload = (e2: any) => {


                switch (exif.Orientation) {

                    case 2:
                        // horizontal flip
                        this.Rotate = "Rotate2";
                        this.RotationType = 2;
                        break;
                    case 3:
                        // 180° rotate left
                        this.Rotate = "Rotate3";
                        this.RotationType = 3;
                        break;
                    case 4:
                        // vertical flip
                        this.Rotate = "Rotate4";
                        this.RotationType = 4;
                        break;
                    case 5:
                        // vertical flip + 90 rotate right
                        this.Rotate = "Rotate5";
                        this.RotationType = 5;
                        break;
                    case 6:
                        // 90° rotate right
                        this.Rotate = "Rotate6";
                        this.RotationType = 6;
                        break;
                    case 7:
                        // horizontal flip + 90 rotate right
                        this.Rotate = "Rotate7";
                        this.RotationType = 7;
                        break;
                    case 8:
                        // 90° rotate left
                        this.Rotate = "Rotate8";
                        this.RotationType = 8;
                        break;
                }


                this.UserImg = e2.target.result;

            };

            r2.readAsDataURL(event.target.files[0]);
        };

        reader.readAsArrayBuffer(event.target.files[0]);
    }

    public UploadFile(): void {
        if ((<any>navigator).camera) {
            (<any>navigator).camera.getPicture((p) => {
                this.Zone.run(() => {
                    this.UserImg = p;
                });
            }, (err) => {
                alert(err);
            },
                {
                    destinationType: (<any>navigator).camera.DestinationType.FILE_URI,
                    correctOrientation: true
                });
        }
        else {
            this.FileUpload.nativeElement.click();
        }
    }

    public CitySelected(City: { Title: string, Id: number }) {
        if (City.Id) {
            this.SelectedCity = City;

            this.SelectedSchool = { Title: "בית ספר", Id: -1 };
            this.User.SchoolId = -1;

            this.Model.Schools.GetSchools(City.Id).subscribe(p => {
                this.Schools = p;
            });
        }
    }

    public ShoolSelected(School: { Title: string, Id: number }) {
        if (School.Id) {
            this.SelectedSchool = School;
            this.User.SchoolId = School.Id;
        }
    }

    public Login(LoginResult: any): void {
        if (LoginResult.Success == LoginResults.Success) {
            /*Login Success redirect to home page*/
            this.Router.navigate(["/", this.App.AppId, "Main", "Home", 1]);
        }
        else if (LoginResult.Success == LoginResults.AddNewGroup) {
            /*User request add new group, redirect to add new group*/
            this.Router.navigate(["/", this.App.AppId, "AddGroup"]);
        }
    }

    public SearchSchools(Search: string): Observable<any> {
        return this.Model.Schools.SearchSchools(Search);
    }
    screenReader() {
        let element = document.body;

        if (element.className.substring(0).match("acc-randerScreen") == null) {
            element.classList.add("acc-randerScreen");
            document.getElementById("screen-render").classList.add("active");
            let element1 = document.getElementById('stickySidebar');
            let toelement = document.getElementById('accessibility');
            let closeelement = document.getElementById('accessibility-close');
            element1.classList.remove('toggle');
            toelement.classList.remove('toggle');
            closeelement.classList.remove('toggle');
            this.isOpen = false;
        }
        else {
            element.classList.remove("acc-randerScreen");
            document.getElementById("screen-render").classList.remove("active");
        }

    }
    brightContrast() {
        let element = document.body;
        if (element.className.substring(0).match("acc-bright") == null) {
            element.classList.add("acc-bright");
            document.getElementById("bright-contrast").classList.add("active");
            document.getElementById("bright-contrastb").classList.add("active");

        }
        else {
            element.classList.remove("acc-bright");
            document.getElementById("bright-contrast").classList.remove("active");
            document.getElementById("bright-contrastb").classList.remove("active");
        }
    }
    darkContrast() {
        let element = document.body;
        if (element.className.substring(0).match("acc-dark") == null) {
            element.classList.add("acc-dark1");
            document.getElementById("dark-contrast").classList.add("active");
            document.getElementById("dark-contrastd").classList.add("active");

        }
        else {
            element.classList.remove("acc-dark" + this.dark);
            this.dark = 1;
            document.getElementById("dark-contrast").classList.remove("active");
            document.getElementById("dark-contrastd").classList.remove("active");

        }
    }
    zeroContrast() {
        let element = document.body;
        element.classList.remove("acc-dark" + this.dark);
        element.classList.remove("acc-bright");
        element.classList.remove("acc-greyscale");
    }
    greyScale() {
        let element = document.body;
        if (element.className.substring(0).match("acc-greyscale") == null) {
            element.classList.add("acc-greyscale");
            document.getElementById("greyScale").classList.add("active");
        }
        else {
            element.classList.remove("acc-greyscale");
            document.getElementById("greyScale").classList.remove("active");
        }
    }
    marklinks() {
        let element = document.body;
        if (element.className.substring(0).match("acc-marklinks") == null) {
            element.classList.add("acc-marklinks");
            document.getElementById("coloring-links").classList.add("active");
        }
        else {
            element.classList.remove("acc-marklinks");
            document.getElementById("coloring-links").classList.remove("active");
        }

    }
    underline() {
        let element = document.body;
        if (element.className.substring(0).match("acc-underline") == null) {
            element.classList.add("acc-underline");
            document.getElementById("highlighting-links").classList.add("active");
        }
        else {
            element.classList.remove("acc-underline");
            document.getElementById("highlighting-links").classList.remove("active");
        }

    }
    animation() {
        let element = document.body;
        if (element.className.substring(0).match("acc-animation") == null) {
            element.classList.add("acc-animation");
            document.getElementById("restoration-souls").classList.add("active");
        }
        else {
            element.classList.remove("acc-animation");
            document.getElementById("restoration-souls").classList.remove("active");
        }
    }
    redBackground() {

        let element = document.body;
        element.classList.remove("bg-yellow");
        element.classList.remove("bg-white");
        if (element.className.substring(0).match("bg-red") == null)
            element.classList.add("bg-red");



    }
    yellowBackground() {
        let element = document.body;
        element.classList.remove("bg-white");
        element.classList.remove("bg-red");
        if (element.className.substring(0).match("bg-yellow") == null)
            element.classList.add("bg-yellow");


    }
    whiteBackground() {
        let element = document.body;
        element.classList.remove("bg-yellow");
        element.classList.remove("bg-red");

        if (element.className.substring(0).match("bg-white") == null)
            element.classList.add("bg-white");
    }
    cleanColor() {
        let element = document.body;
        element.classList.remove("bg-white");
        element.classList.remove("bg-yellow");
        element.classList.remove("bg-red");

    }
    cleanAll() {
        let element = document.body;
        element.className = "";
        jQuery('body *').each(function () {
            var my = parseInt(jQuery(this).css('font-size'));
            let fonta = my - 1 + "px";
            // fonting = fontSize + 1 + "px";
            jQuery(this).css({ 'font-size': "" });
        });
    }
    addFont() {
        let element = document.body;
        if (element.className.substring(0).match("acc-font") == null) {
            element.classList.add("acc-font");
            document.getElementById("dark-contrastf").classList.add("active");
            document.getElementById("btn-fonts").classList.add("active");
        }
        else {
            element.classList.remove("acc-font");
            document.getElementById("dark-contrastf").classList.remove("active");
            document.getElementById("btn-fonts").classList.remove("active");
            jQuery('body *').each(function () {
                var my = parseInt(jQuery(this).css('font-size'));
                let fonta = my - 1 + "px";
                // fonting = fontSize + 1 + "px";
                jQuery(this).css({ 'font-size': "" });
            });
        }
    }
    changeFont(operator) {
        // element.style.fontSize = `${this.fontSize}px`;
        // if (operator == '+')
        //   this.Popups.LoadIncreaseFont();
        // else
        //   this.Popups.LoadDecreaseFont();
        if (operator === '+') {
            jQuery('body *').each(function () {
                var my = parseInt(jQuery(this).css('font-size'));
                let fonta = my + 1 + "px";
                // fonting = fontSize + 1 + "px";
                jQuery(this).css({ 'font-size': fonta });
            });

        }
        else {
            jQuery('body *').each(function () {
                var my = parseInt(jQuery(this).css('font-size'));
                let fonta = my - 1 + "px";
                // fonting = fontSize + 1 + "px";
                jQuery(this).css({ 'font-size': fonta });
            });
        }
    }
    changeDark(operator) {
        let element = document.body;
        if (operator === '+') {
            if (this.dark == 10)
                element.classList.add("acc-dark" + 10);
            if (this.dark <= 9) {
                element.classList.remove("acc-dark" + this.dark)
                this.dark = this.dark + 1;
                element.classList.add("acc-dark" + this.dark)
            }
        }
        else {
            if (this.dark == 1)
                element.classList.add("acc-dark" + 1);
            if (this.dark <= 10 && this.dark != 1) {
                element.classList.remove("acc-dark" + this.dark)
                this.dark = this.dark - 1;
                element.classList.add("acc-dark" + this.dark)
            }
        }
    }

    clearFont() {
        let element = document.body;
        element.className = "";
        jQuery('body *').each(function () {
            var my = parseInt(jQuery(this).css('font-size'));
            let fonta = my - 1 + "px";
            // fonting = fontSize + 1 + "px";
            jQuery(this).css({ 'font-size': "" });
        });
    }
    zoomdata() {
        let element = document.body;
        if (element.className.substring(0).match("zoomdata") == null) {
            element.classList.add("zoomdata");
            // document.getElementById("screen-render").classList.add("active");
        }
        else {
            element.classList.remove("zoomdata");
            // document.getElementById("screen-render").classList.remove("active");
        }
    }
    keyboardNavigation() {
        let element = document.body;
        if (element.className.substring(0).match("acc-keyboard") == null) {
            element.classList.add("acc-keyboard");
            document.getElementById("keyboard-navigation").classList.add("active");
        }
        else {
            element.classList.remove("acc-keyboard");
            document.getElementById("keyboard-navigation").classList.remove("active");
        }
    }
    readableFonts() {
        let element = document.body;
        if (element.className.substring(0).match("acc-readable") == null) {
            element.classList.add("acc-readable");
            document.getElementById("readable-font").classList.add("active");
        }
        else {
            element.classList.remove("acc-readable");
            document.getElementById("readable-font").classList.remove("active");
        }
    }
    Open() {
        if (this.isOpen == false) {
            let element = document.getElementById('stickySidebar');
            let toelement = document.getElementById('accessibility');
            let closeelement = document.getElementById('accessibility-close');
            element.classList.add('toggle');
            toelement.classList.add('toggle');
            closeelement.classList.add('toggle');
            this.isOpen = true;
        } else {
            let element = document.getElementById('stickySidebar');
            let toelement = document.getElementById('accessibility');
            let closeelement = document.getElementById('accessibility-close');
            element.classList.remove('toggle');
            toelement.classList.remove('toggle');
            closeelement.classList.remove('toggle');
            this.isOpen = false;
        }
    }
    Close() {
        let element = document.getElementById('stickySidebar');
        let toelement = document.getElementById('accessibility');
        let closeelement = document.getElementById('accessibility-close');
        closeelement.classList.remove('toggle');
        element.classList.remove('toggle');
        toelement.classList.remove('toggle');
        this.isOpen = false;
    }
    whiteCursor()
    {
     
      let element = document.body;
      if (element.className.substring(0).match("whitecursor") == null) {
        element.classList.add("whitecursor");
        document.getElementById("cu-white").classList.add("active");
      }
      else {
        element.classList.remove("whitecursor");
        document.getElementById("cu-white").classList.remove("active");
      }
    }
    blackCursor()
    {
      let element = document.body;
      if (element.className.substring(0).match("blackcursor") == null) {
        element.classList.add("blackcursor");
        document.getElementById("cu-black").classList.add("active");
      }
      else {
        element.classList.remove("blackcursor");
        document.getElementById("cu-black").classList.remove("active");
      }
    }
    textLeftAlign()
    {
      let element = document.body;
      if (element.className.substring(0).match("text-left") == null) {
        element.classList.add("text-left");
        document.getElementById("align-left").classList.add("active");
      }
      else {
        element.classList.remove("text-left");
        document.getElementById("align-left").classList.remove("active");
      }
    }
    textCenterAlign()
    {
      let element = document.body;
      if (element.className.substring(0).match("text-center") == null) {
        element.classList.add("text-center");
        document.getElementById("align-center").classList.add("active");
      }
      else {
        element.classList.remove("text-center");
        document.getElementById("align-center").classList.remove("active");
      }
    }
    textRightAlign()
    {
      let element = document.body;
      if (element.className.substring(0).match("text-right") == null) {
        element.classList.add("text-right");
        document.getElementById("align-right").classList.add("active");
      }
      else {
        element.classList.remove("text-right");
        document.getElementById("align-right").classList.remove("active");
      }
    }
}