import { Component, ViewChild, Inject } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LoginService, LoginResults } from "../../Shared/Services/Login.service";
import { Router } from "@angular/router";
import { Model } from "../../Shared/Services/Model.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { SchoolSelectorComponent } from "../SchoolSelector/SchoolSelector.component";

@Component({
    templateUrl: "./Login.component.html",
    styleUrls: ["./Styles/Login.component.rtl.scss", "./Styles/Login.component.ltr.scss"]
})
export class LoginComponent {
    public UserName: string;
    public Password: string;
    public Lang: number;
    public Show: boolean;
    dark: number = 1;
    isOpen = false;
    public Langs: any[];

    public LogoImg: SafeResourceUrl;

    @ViewChild(SchoolSelectorComponent)
    private SchoolSelector: SchoolSelectorComponent;

    constructor(private LoginService: LoginService, private Router: Router, public App: AppService, public Resource: ResourcesService, private Model: Model, private Sanitizer: DomSanitizer, @Inject("SystemURL") private SystemURL: string) {
        this.Show = true;

        this.Model.Users.GetLangs().subscribe(p => {
            this.Langs = p;
            // if(this.App.AppId == 247)
            // this.Lang  = 4 ;
            // else
            this.Lang = App.Lang.Id;
        });

        this.LogoImg = this.Sanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/Logos/${this.App.AppId}.jpg`);
    }

    public Login(): void {
        this.LoginService.Login(this.UserName, this.Password).subscribe(p => {
            if (p.Success == LoginResults.Success) {
                /*Login Success - redirect to home page*/
                this.Model.DailyStudy.GetTodayDailyStudy(false).subscribe(p => {
                    this.Router.navigate(["/", this.App.AppId, "Main", "Home", p]);
                });
            }
            else if (p.Success == LoginResults.AddNewGroup) {
                /*User request add new group, redirect to add new group*/
                this.Router.navigate(["/", this.App.AppId, "AddGroup"]);
            }
            else if (p.Success == LoginResults.UserAlreadyBelongToGroup) {
                /*User tired join more than one group*/
                alert(this.Resource.Get("YouCantJoinMultipleGroupsMsg").replace("{0}", p.PlayGroup));

                this.Router.navigate(["/", this.App.AppId, "Welcome"]);
            }
            else if (p.Success == LoginResults.UserDoesntHavePlayGroup) {
                /*User does not belong to any group*/
                alert(this.Resource.Get("YouMustJoinGroupMsg"));
                this.Router.navigate(["/", this.App.AppId, "Welcome"]);
            }
            else if (p.Success == LoginResults.UserDoesntHaveNuget) {
                /*User does not have school*/
                this.Show = false;

                this.SchoolSelector.Show(this.UserName, this.Password);
            }
            else if (p.Success == LoginResults.WrongUserNameOrPassword) {
                /*Wrong username or password */
                alert(this.Resource.Get("WrongUserNameOrPasswordMsg"));
            }
        });
    }

    public OnLangChange(): void {
        let Lang = this.Langs.find(p => p.Id == this.Lang);

        // if(this.App.AppId == 247)
        // this.Lang  = 4 ;
        // else
        this.App.Lang.Id = Lang.Id;
        this.App.Lang.Direction = Lang.Rtl ? "RTL" : "LTR";

        localStorage.setItem("Lang" + this.App.AppId, JSON.stringify(this.App.Lang));
    }
    screenReader() {
        let element = document.body;

        if (element.className.substring(0).match("acc-randerScreen") == null) {
            element.classList.add("acc-randerScreen");
            document.getElementById("screen-render").classList.add("active");
            let element1 = document.getElementById('stickySidebar');
            let toelement = document.getElementById('accessibility');
            let closeelement = document.getElementById('accessibility-close');
            element1.classList.remove('toggle');
            toelement.classList.remove('toggle');
            closeelement.classList.remove('toggle');
            this.isOpen = false;
        }
        else {
            element.classList.remove("acc-randerScreen");
            document.getElementById("screen-render").classList.remove("active");
        }

    }
    brightContrast() {
        let element = document.body;
        if (element.className.substring(0).match("acc-bright") == null) {
            element.classList.add("acc-bright");
            document.getElementById("bright-contrast").classList.add("active");
            document.getElementById("bright-contrastb").classList.add("active");

        }
        else {
            element.classList.remove("acc-bright");
            document.getElementById("bright-contrast").classList.remove("active");
            document.getElementById("bright-contrastb").classList.remove("active");
        }
    }
    darkContrast() {
        let element = document.body;
        if (element.className.substring(0).match("acc-dark") == null) {
            element.classList.add("acc-dark1");
            document.getElementById("dark-contrast").classList.add("active");
            document.getElementById("dark-contrastd").classList.add("active");

        }
        else {
            element.classList.remove("acc-dark" + this.dark);
            this.dark = 1;
            document.getElementById("dark-contrast").classList.remove("active");
            document.getElementById("dark-contrastd").classList.remove("active");

        }
    }
    zeroContrast() {
        let element = document.body;
        element.classList.remove("acc-dark" + this.dark);
        element.classList.remove("acc-bright");
        element.classList.remove("acc-greyscale");
    }
    greyScale() {
        let element = document.body;
        if (element.className.substring(0).match("acc-greyscale") == null) {
            element.classList.add("acc-greyscale");
            document.getElementById("greyScale").classList.add("active");
        }
        else {
            element.classList.remove("acc-greyscale");
            document.getElementById("greyScale").classList.remove("active");
        }
    }
    marklinks() {
        let element = document.body;
        if (element.className.substring(0).match("acc-marklinks") == null) {
            element.classList.add("acc-marklinks");
            document.getElementById("coloring-links").classList.add("active");
        }
        else {
            element.classList.remove("acc-marklinks");
            document.getElementById("coloring-links").classList.remove("active");
        }

    }
    underline() {
        let element = document.body;
        if (element.className.substring(0).match("acc-underline") == null) {
            element.classList.add("acc-underline");
            document.getElementById("highlighting-links").classList.add("active");
        }
        else {
            element.classList.remove("acc-underline");
            document.getElementById("highlighting-links").classList.remove("active");
        }

    }
    animation() {
        let element = document.body;
        if (element.className.substring(0).match("acc-animation") == null) {
            element.classList.add("acc-animation");
            document.getElementById("restoration-souls").classList.add("active");
        }
        else {
            element.classList.remove("acc-animation");
            document.getElementById("restoration-souls").classList.remove("active");
        }
    }
    redBackground() {

        let element = document.body;
        element.classList.remove("bg-yellow");
        element.classList.remove("bg-white");
        if (element.className.substring(0).match("bg-red") == null)
            element.classList.add("bg-red");



    }
    yellowBackground() {
        let element = document.body;
        element.classList.remove("bg-white");
        element.classList.remove("bg-red");
        if (element.className.substring(0).match("bg-yellow") == null)
            element.classList.add("bg-yellow");


    }
    whiteBackground() {
        let element = document.body;
        element.classList.remove("bg-yellow");
        element.classList.remove("bg-red");

        if (element.className.substring(0).match("bg-white") == null)
            element.classList.add("bg-white");
    }
    cleanColor() {
        let element = document.body;
        element.classList.remove("bg-white");
        element.classList.remove("bg-yellow");
        element.classList.remove("bg-red");

    }
    cleanAll() {
        let element = document.body;
        element.className = "";
        jQuery('body *').each(function () {
            var my = parseInt(jQuery(this).css('font-size'));
            let fonta = my - 1 + "px";
            // fonting = fontSize + 1 + "px";
            jQuery(this).css({ 'font-size': "" });
        });
    }
    addFont() {
        let element = document.body;
        if (element.className.substring(0).match("acc-font") == null) {
            element.classList.add("acc-font");
            document.getElementById("dark-contrastf").classList.add("active");
            document.getElementById("btn-fonts").classList.add("active");
        }
        else {
            element.classList.remove("acc-font");
            document.getElementById("dark-contrastf").classList.remove("active");
            document.getElementById("btn-fonts").classList.remove("active");
            jQuery('body *').each(function () {
                var my = parseInt(jQuery(this).css('font-size'));
                let fonta = my - 1 + "px";
                // fonting = fontSize + 1 + "px";
                jQuery(this).css({ 'font-size': "" });
            });
        }
    }
    changeFont(operator) {
        // element.style.fontSize = `${this.fontSize}px`;
        // if (operator == '+')
        //   this.Popups.LoadIncreaseFont();
        // else
        //   this.Popups.LoadDecreaseFont();
        if (operator === '+') {
            jQuery('body *').each(function () {
                var my = parseInt(jQuery(this).css('font-size'));
                let fonta = my + 1 + "px";
                // fonting = fontSize + 1 + "px";
                jQuery(this).css({ 'font-size': fonta });
            });

        }
        else {
            jQuery('body *').each(function () {
                var my = parseInt(jQuery(this).css('font-size'));
                let fonta = my - 1 + "px";
                // fonting = fontSize + 1 + "px";
                jQuery(this).css({ 'font-size': fonta });
            });
        }
    }
    changeDark(operator) {
        let element = document.body;
        if (operator === '+') {
            if (this.dark == 10)
                element.classList.add("acc-dark" + 10);
            if (this.dark <= 9) {
                element.classList.remove("acc-dark" + this.dark)
                this.dark = this.dark + 1;
                element.classList.add("acc-dark" + this.dark)
            }
        }
        else {
            if (this.dark == 1)
                element.classList.add("acc-dark" + 1);
            if (this.dark <= 10 && this.dark != 1) {
                element.classList.remove("acc-dark" + this.dark)
                this.dark = this.dark - 1;
                element.classList.add("acc-dark" + this.dark)
            }
        }
    }

    clearFont() {
        let element = document.body;
        element.className = "";
        jQuery('body *').each(function () {
            var my = parseInt(jQuery(this).css('font-size'));
            let fonta = my - 1 + "px";
            // fonting = fontSize + 1 + "px";
            jQuery(this).css({ 'font-size': "" });
        });
    }
    zoomdata() {
        let element = document.body;
        if (element.className.substring(0).match("zoomdata") == null) {
            element.classList.add("zoomdata");
            // document.getElementById("screen-render").classList.add("active");
        }
        else {
            element.classList.remove("zoomdata");
            // document.getElementById("screen-render").classList.remove("active");
        }
    }
    keyboardNavigation() {
        let element = document.body;
        if (element.className.substring(0).match("acc-keyboard") == null) {
            element.classList.add("acc-keyboard");
            document.getElementById("keyboard-navigation").classList.add("active");
        }
        else {
            element.classList.remove("acc-keyboard");
            document.getElementById("keyboard-navigation").classList.remove("active");
        }
    }
    readableFonts() {
        let element = document.body;
        if (element.className.substring(0).match("acc-readable") == null) {
            element.classList.add("acc-readable");
            document.getElementById("readable-font").classList.add("active");
        }
        else {
            element.classList.remove("acc-readable");
            document.getElementById("readable-font").classList.remove("active");
        }
    }
    Open() {
        if (this.isOpen == false) {
            let element = document.getElementById('stickySidebar');
            let toelement = document.getElementById('accessibility');
            let closeelement = document.getElementById('accessibility-close');
            element.classList.add('toggle');
            toelement.classList.add('toggle');
            closeelement.classList.add('toggle');
            this.isOpen = true;
        } else {
            let element = document.getElementById('stickySidebar');
            let toelement = document.getElementById('accessibility');
            let closeelement = document.getElementById('accessibility-close');
            element.classList.remove('toggle');
            toelement.classList.remove('toggle');
            closeelement.classList.remove('toggle');
            this.isOpen = false;
        }
    }
    Close() {
        let element = document.getElementById('stickySidebar');
        let toelement = document.getElementById('accessibility');
        let closeelement = document.getElementById('accessibility-close');
        closeelement.classList.remove('toggle');
        element.classList.remove('toggle');
        toelement.classList.remove('toggle');
        this.isOpen = false;
    }
    whiteCursor()
    {
     
      let element = document.body;
      if (element.className.substring(0).match("whitecursor") == null) {
        element.classList.add("whitecursor");
        document.getElementById("cu-white").classList.add("active");
      }
      else {
        element.classList.remove("whitecursor");
        document.getElementById("cu-white").classList.remove("active");
      }
    }
    blackCursor()
    {
      let element = document.body;
      if (element.className.substring(0).match("blackcursor") == null) {
        element.classList.add("blackcursor");
        document.getElementById("cu-black").classList.add("active");
      }
      else {
        element.classList.remove("blackcursor");
        document.getElementById("cu-black").classList.remove("active");
      }
    }
    textLeftAlign()
    {
      let element = document.body;
      if (element.className.substring(0).match("text-left") == null) {
        element.classList.add("text-left");
        document.getElementById("align-left").classList.add("active");
      }
      else {
        element.classList.remove("text-left");
        document.getElementById("align-left").classList.remove("active");
      }
    }
    textCenterAlign()
    {
      let element = document.body;
      if (element.className.substring(0).match("text-center") == null) {
        element.classList.add("text-center");
        document.getElementById("align-center").classList.add("active");
      }
      else {
        element.classList.remove("text-center");
        document.getElementById("align-center").classList.remove("active");
      }
    }
    textRightAlign()
    {
      let element = document.body;
      if (element.className.substring(0).match("text-right") == null) {
        element.classList.add("text-right");
        document.getElementById("align-right").classList.add("active");
      }
      else {
        element.classList.remove("text-right");
        document.getElementById("align-right").classList.remove("active");
      }
    }
}