import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, Inject } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Model } from "../../Shared/Services/Model.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { DailyStudyService } from "../../Shared/Services/DailyStudy.service";
import { CookiesService } from "../../Shared/Services/Cookies.service";
import { ScrollService } from "../../Shared/Services/Scroll.service";
import { Comments } from "../../Shared/Model/Comments.model";

@Component({
    selector: "DailyStudyPopup",
    templateUrl: './DailyStudyItemPopup.component.html',
    styleUrls: ['./Styles/DailyStudyItemPopup.component.rtl.scss', './Styles/DailyStudyItemPopup.component.ltr.scss']
})
export class DailyStudyItemPopupComponent {
    public AudioFile: HTMLAudioElement;
    public DailyStudyItem: any;
    public ShowAudio: boolean = false;
    public ShowBtnRead: boolean = false;
    public ShowTipMessage: boolean = false;
    public isCommentSent: boolean = false;
    public IsCommentListFlag : boolean = false;;
    public DailyStudyId: number;
    public DailyStudyItemChildId: number;
    public TotalCommentCount : number;


    public Comments: any[] = [];
    public pageNumber: number = 0;
    public showMore:boolean = true;

    @ViewChild("Script")
    public Script: ElementRef;

    constructor(private Model: Model,
        private DomSanitizer: DomSanitizer,
        private ActivatedRoute: ActivatedRoute,
        private DailyStudyService: DailyStudyService,
        private Router: Router,
        private CookiesService: CookiesService,
        private Scroll: ScrollService,
        public App: AppService,
        public Resource: ResourcesService,
        public CommentsService: Comments,
        @Inject("SystemURL") private SystemURL: string) {
          //  window.alert("hi2");
        this.Router.events.filter(p => p instanceof NavigationEnd).subscribe(p => {
            this.Scroll.Position = 0;
            window.scrollTo(0, 0);

            if (this.AudioFile) {
                this.AudioFile.pause();
                this.AudioFile.currentTime = 0;
            }
        });
    }
      
    private ngOnInit(): void {
       // window.alert("hi");
        this.ShowBtnRead = false;
        this.DailyStudyId = this.ActivatedRoute.parent.snapshot.params["id"];
        this.DailyStudyItemChildId = this.ActivatedRoute.snapshot.params["id"];

        if (!localStorage.getItem("ShowTip") && !this.CookiesService.Get("ShowTip")) {
            this.ShowTipMessage = true;
        }

        if (this.DailyStudyItemChildId && this.DailyStudyItemChildId != -1 && this.DailyStudyId && this.DailyStudyId != -1) {
            this.Model.DailyStudy.GetDailyStudyItemChild(this.DailyStudyId, this.DailyStudyItemChildId).subscribe(p => {
                this.DailyStudyService.LoadDailyStudyItemChildrens(this.DailyStudyId, p.DailyStudyItemId);
                this.DailyStudyItem = p;
                this.DailyStudyItem.Content = this.DomSanitizer.bypassSecurityTrustHtml(p.Content);
                this.DailyStudyItem.SrcURL = this.DomSanitizer.bypassSecurityTrustResourceUrl(p.Url);

                while (this.Script.nativeElement.firstChild) {
                    this.Script.nativeElement.removeChild(this.Script.nativeElement.firstChild);
                }

                let tmp = document.createElement("div");

                if (this.DailyStudyItem.Script) {
                    tmp.innerHTML = this.DailyStudyItem.Script.replace("src=\"//cdn.", "src=\"https://cdn.");

                    var Scripts = tmp.getElementsByTagName("script");

                    for (let i = 0; i < Scripts.length; i++) {
                        let Script = Scripts[i];

                        let Src = Script.getAttribute("src");

                        if (Src != "") {
                            let JScript: HTMLScriptElement = document.createElement("script");
                            JScript.src = Src;

                            this.Script.nativeElement.appendChild(JScript);
                        }
                    }

                    var JS = document.createElement('script');
                    JS.text = tmp.textContent || tmp.innerText || "";

                    this.Script.nativeElement.appendChild(JS);

                    JS = document.createElement('script');
                    JS.text = "window.Playbuzz && window.Playbuzz.render()";

                    this.Script.nativeElement.appendChild(JS);

                    setTimeout(() => {
                        let obj: HTMLIFrameElement = document.getElementsByTagName("iframe")[0];

                        obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';

                        console.log(obj.contentWindow.document.body.scrollHeight + 'px');
                    }, 1000);
                }

                if (this.DailyStudyItem.HasAudio) {
                    this.AudioFile = new Audio(this.SystemURL + "/sound/" + this.DailyStudyItem.AudioFileName);
                    this.AudioFile.load();
                }

                this.getComments();
                setTimeout(() => {
                    this.ShowBtnRead = true;
                }, 1000);
            });
        }
    }

    private ngOnDestroy(): void {
        if (this.AudioFile) {
            this.AudioFile.pause();
            this.AudioFile.currentTime = 0;
        }
    }

    getComments(pageNumber = 0) {
        this.pageNumber = pageNumber == 0 ? (this.pageNumber + 1) : 1;

        let strURL = 'Main' + window.document.URL.split('Main')[1];
        this.CommentsService.GetComments(strURL, this.pageNumber).subscribe(
            (response: any) => {
                if (response.flag && response.Comments.length) {
                    this.TotalCommentCount = response.Count;
                    response.Comments.length < 10 ? this.showMore = false :this.showMore = true ;
                    this.Comments = this.pageNumber == 1 ? [] : this.Comments; 
                    response.Comments.forEach(element => {
                        if(element.LastName != null)
                        element.LastName = element.LastName.charAt(0);
                        this.Comments.push(element);       
                    });
                } else {
                    this.showMore = false;
                }
            },
            (error: any) => {
                console.log(error);
            },
        )
    }

    saveComments(commentText?: any) {
        if (!commentText || commentText==null || commentText ==undefined || commentText =='') {
            return
        }
        let strURL = 'Main' + window.document.URL.split('Main')[1];
       
        this.IsCommentListFlag = true;
        let DailyStudyId = this.ActivatedRoute.parent.snapshot.params["id"];
        let DailyStudyItemChildId = this.ActivatedRoute.snapshot.params["id"];
        this.CommentsService.AddComment(commentText, strURL,DailyStudyId,DailyStudyItemChildId).subscribe(
            (response: any) => {
                this.isCommentSent = true;
                
                this.getComments(99);
            },
            (error: any) => {
                this.isCommentSent = false;
                console.log(error);
            },
        )
    }

    public TogglePopup(): void {
        this.Router.navigate(["../../"], { relativeTo: this.ActivatedRoute });
    }

    public ToggleAudio(): void {
        this.ShowAudio = !this.ShowAudio;

        if (this.ShowAudio && this.AudioFile.paused) {
            this.Play();
        }
        else {
            this.AudioFile.pause();
            this.AudioFile.currentTime = 0;
        }
    }

    public Done(): void {
        if (!this.DailyStudyItem.Done) {
            let Points = 0;

            if ((<any>window).cordova) {
                localStorage.setItem("ShowTip", "1");
            }
            else {
                this.CookiesService.Create("ShowTip", "1", 10000);
            }

            this.Model.DailyStudy.ReadDailyStudyItem(this.DailyStudyId, this.DailyStudyItemChildId, Points).subscribe(p => {
                if (p.Status == 1) {
                    p.DailyStudyId = this.DailyStudyId;
                    p.DailyStudySortId = this.DailyStudyItem.DailyStudySortId;

                    this.DailyStudyService.DailyStudyDone(p);
                    this.DailyStudyItem.Done = true;
                }

                this.Router.navigate(["../../"], { relativeTo: this.ActivatedRoute });
            });
        }
    }

    public Play(): void {
        if (this.AudioFile.paused)
            this.AudioFile.play();
        else
            this.AudioFile.pause();
    }


}
