export const LANG_HEB_NAME = "Lang-1";

export const LANG_HEB_RES = {
    "AppName": "היינו כחולמים",
    "AppDesc": "לימוד יומי של פרקי נביאים",
    "LogIn": "כניסה",
    "GuestLogin": "כניסת אורח",
    "RegistredLogin": "כניסת משתמשים רשומים",
    "LogOut": "התנתק",
    "Register": "הירשם",
    "Registeration": "הרשמה",
    "Unregister": "לא רשום?",
    "Registred": "משתמש רשום?",
    "ClickHere": " לחץ כאן",
    "StartHere": "התחל כאן",
    "FirstName": "שם פרטי",
    "LastName": "שם משפחה",
    "Email": "דוא\"ל",
    "Password": "סיסמה",
    "Mobile": "נייד",
    "ForgatPassword": "שכחתי סיסמה",
    "Back": "חזרה",
    "Continue": "המשך",
    "Done": "סיימתי",
    "Like": "אהבתי",
    "Nice": "יפה!",
    "VeryGood": "טוב מאוד!",
    "WellDone": "כל הכבוד!",
    "Excellent": "מצויין",
    "Share": "שתף עם חברים",
    "School": "בית ספר",
    "Schools": "בתי-ספר",
    "SelectSchool": "בחר בית ספר",
    "Chapter": "פרק",
    "Hours": "שעות",
    "Scores": "ניקוד",
    "Rating": "דירוג",
    "Name": "שם",
    "AllChapters": "כל ימי הלימוד",
    "PersonalArea": "איזור אישי",
    "HighScores": "המובילים",
    "About": "אודות",
    "Points": "נקודות",
    "OutOf": "מתוך",
    "Players": "שחקנים",
    "OmerFrankel": "עומר פרנקל",
    "IReadChapter": "קראתי את הפרק",
    "LearnNextChapter": "התקדם ללימוד הבא",
    "SelectBook": "בחר ספר",
    "JoinGroup": "הצטרפות לקבוצה",
    "Join": "הצרפות",
    "Code": "קוד",
    "AddComment": "רשום תגובה",
    "Publish": "פרסם",
    "ShowMore": "הצג עוד...",
    "Next": "הבא",
    "Backward": "הקודם",
    "NewUser": "אני משתמש חדש",
    "D1": "א'",
    "D2": "ב'",
    "D3": "ג'",
    "D4": "ד'",
    "D5": "ה'",
    "D6": "ו'",
    "D7": "ש'",
    "ShowCommentsBtn": "ראה <strong>{0}</strong> תגובות לנושא זה",
    "WeekDoneMsg": "סיימנו עוד שבוע של  <br /> לימוד יומי!",
    "TypeYourCommentMsg": "הקלד כאן את תגובתך",
    "DoneClickHereMsg": "סיימת? לחצ/י כאן לצבירת נקודות",
    "PasswordSentToEmailMsg": "פרטי הכניסה נשלחו לדוא\"ל שלך!",
    "EmailNotExistMsg": "דוא\"ל לא נמצא במערכת!",
    "WrongUserNameOrPasswordMsg": "שם משתמש או סיסמה שגויים!",
    "YouRecivedPointsMsg": "קיבלת {0} נקודות על פרק זה",
    "MoreIdeasMsg": "לרעיונות נוספים והרחבות",
    "CreditsMsg": "השאלות חוברו על ידי דורון קדוש, איתן יאיר שטרסברג וחברי ההנהגה הצעירה בתנ\"ך, סיירת חות\"ם",
    "JoinBibleQuizMsg": "להצטרפות לחידון התנ\"ך",
    "YouHaveFinishedAnotherChapterMsg": "סיימת לימוד נוסף!",
    "NextChapterInMsg": "היום לימוד הבא יפתח עוד",
    "YouHaveLearnedMsg": "למדת {0} פרקים",
    "OfPublishedMsg": "מתוך {0} שפורסמו",
    "LoginErrorOccurred": "אירעה שגיאה במהלך הכניסה, יש לנסות שוב במועד מאוחר יותר!",
    "RegisterErrorOccurred": "אירעה שגיאה בעת ההרשמה, יש לנסות שוב במועד מאוחר יותר",
    "UploadImgErr": "אירעה שגיאה בעת העלאת התמונה",
    "JoinedGroupMsg": "הצרפת בהצלחה לקבוצה {0}",
    "UserPlayGroupExistMsg": "אתה כבר מצורף לקבוצה {0}",
    "GroupNotFoundMsg": "הקבוצה לא נמצאה!",
    "EnterGroupCode": "הזן קוד קבוצה",
    "ToLearning": "ללימוד",
    "CreateGroupAndGoMsg": "!צרו קבוצת לימוד וצאו לדרך",
    "WhatIsYourGroupName": "איך קוראים לקבוצה שלך?",
    "CreateGroup": "צור קבוצה",
    "CreatePlayGroup": "יצירת קבוצת לימוד",
    "YouCantJoinMultipleGroupsMsg": "אתה כבר רשום לפרוייקט זה בקבוצה של {0} לכניסה, יש להכנס דרך הקבוצה שלך.",
    "YouMustJoinGroupMsg": "יש להצטרף או ליצור קבוצת לימוד",
    "PlayGroupCodeNotFound": "קוד קבוצה לא קיים במערכת!",
    "NewGroupCreatedMsg": "יצרת קבוצת לימוד חדשה!",
    "GroupCode": "קוד הקבוצה",
    "CopyCode": "העתק קוד",
    "InviteFriendsByShare": "הזמן חברים ללימוד ע\"י שיתוף לקבוצה",
    "ShareGroupLink": "שתף לינק לקבוצה",
    "ShowGroup": "הצג קבוצה",
    "CodeCopied": "הקוד הועתק!",
    "Group": "קבוצת",
    "TotalGroupPoints": "סך ניקוד הקבוצה",
    "GroupMembers": "חברים בקבוצה",
    "GroupAdmin": "מנהל הקבוצה",
    "MyPoints": "נקודות שלי",
    "GroupPoints": "נקודות קבוצה",
    "You": "את/ה",
    "MyGroup": "הקבוצה שלי",
    "ForgatGroupCode": "שכחת קוד קבוצה? לחץ כאן",
    "Month": [
        "תשרי",
        "מרחשוון",
        "כסלו",
        "טבת",
        "שבט",
        "אדר",
        "ניסן",
        "אייר",
        "סיוון",
        "תמוז",
        "אב",
        "אלול"
    ]
};