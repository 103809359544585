import { Component, Input } from "@angular/core";
import { LoginService, LoginResults } from "../../Shared/Services/Login.service";
import { Router } from "@angular/router";
import { Model } from "../../Shared/Services/Model.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { Observable } from "rxjs/Observable";

@Component({
    selector: "SchoolSelector",
    templateUrl: "./SchoolSelector.component.html",
    styleUrls: ["./Styles/SchoolSelector.component.rtl.scss", "./Styles/SchoolSelector.component.ltr.scss"]
})
export class SchoolSelectorComponent {
    private UserName: string;
    private Password: string;

    public ShowSelector: boolean;
    public School: number;

    constructor(private LoginService: LoginService, private Router: Router, private Model: Model, public App: AppService, public Resource: ResourcesService) {
        this.ShowSelector = false;
    }

    public Login(): void {
        if (this.School) {
            this.LoginService.Login(this.UserName, this.Password, this.School).subscribe(p => {
                if (p.Success == LoginResults.Success) {
                    /*Login Success redirect to home page*/
                    this.Model.DailyStudy.GetTodayDailyStudy(false).subscribe(p => {
                        this.Router.navigate(["/", this.App.AppId, "Main", "Home", p]);
                    });
                }
                else if (p.Success == LoginResults.AddNewGroup) {
                    /*User request add new group, redirect to add new group*/
                    this.Router.navigate(["/", this.App.AppId, "AddGroup"]);
                }
                else if (p.Success == LoginResults.UserDoesntHavePlayGroup) {
                    /*User does not belong to any group*/
                    alert(this.Resource.Get("YouMustJoinGroupMsg"));
                    this.Router.navigate(["/", this.App.AppId, "Welcome"]);
                }
                else if (p.Success == LoginResults.WrongUserNameOrPassword) {
                    /*Wrong username and password */
                    alert(this.Resource.Get("WrongUserNameOrPasswordMsg"));
                }
            });
        }
    }


    public ShoolSelected(School: { Title: string, Id: number }) {
        if (School.Id) {
            this.School = School.Id;
        }
        else {
            this.School = null;
        }
    }

    public SearchSchools(Search: string): Observable<any> {
        return this.Model.Schools.SearchSchools(Search);
    }

    public Show(UserName: string, Password: string): void {
        this.UserName = UserName;
        this.Password = Password;

        this.ShowSelector = true;
    }
}