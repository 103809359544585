import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { AppService } from "../../Shared/Services/App.service";
import { PopupService } from "../../Shared/Services/Popup.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { Model } from "../../Shared/Services/Model.service";

@Component({
    templateUrl: "./MyGroup.component.html",
    styleUrls: ["./Styles/MyGroup.component.rtl.scss", "./Styles/MyGroup.component.ltr.scss"]
})
export class MyGroupComponent {
    public PlayGroup: any;
    public Members: any[];

    constructor(public App: AppService, private PopupService: PopupService, private Location: Location, private Sanitizer: DomSanitizer, private Model: Model, public Resource: ResourcesService) {
        this.Members = [];
    };

    public ngOnInit(): void {
        this.Model.PlayGroups.GetCurrentPlayGroup().subscribe(p => {
            this.PlayGroup = p;
        });

        this.Model.PlayGroups.GetCurrentPlayGroupMemebers().subscribe(p => {
            this.Members = p.map(m => {
                m.ProfilePic = this.Sanitizer.bypassSecurityTrustStyle(`url('http://herzognew.mailman.co.il/Uploads/UserProfile/${m.Id}.jpg'),url(/assets/Images/profile-man.png)`);

                return m;
            });
        });
    };

    public Share(): void {
        this.PopupService.ShowShareGroupPopup(this.PlayGroup.Code, this.PlayGroup.Name);
    };

    public Close(): void {
        this.Location.back();
    };
}