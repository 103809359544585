export const LANG_ENG_NAME = "Lang-2";

export const LANG_ENG_RES = {
    "AppName": "Hemed Yomi",
    "AppDesc": "Daily study",
    "LogIn": "Log In",
    "GuestLogin": "Guest Login",
    "RegistredLogin": "Login to registered users",
    "LogOut": "Logout",
    "Register": "Register",
    "Registeration": "Registration",
    "Unregister": "Not registered?",
    "Registred": "Already registered?",
    "ClickHere": "click here",
    "StartHere": "Start Here",
    "FirstName": "First Name",
    "LastName": "Last Name",
    "Email": "Email",
    "Password": "Password",
    "Mobile": "Mobile",
    "ForgatPassword": "Forgot password",
    "Back": "Back",
    "Continue": "Continue",
    "Done": "Done",
    "Like": "Like",
    "Nice": "Nice!",
    "VeryGood": "Very Good!",
    "WellDone": "Well Done!",
    "Excellent": "Excellent!",
    "Share": "Share with friends",
    "School": "School",
    "Schools": "Schools",
    "SelectSchool": "Select a school",
    "Chapter": "Chapter",
    "Hours": "Hours",
    "Scores": "Scores",
    "Rating": "Rating",
    "Name": "Name",
    "AllChapters": "All Chapters",
    "PersonalArea": "Personal Area",
    "HighScores": "High Scores",
    "About": "About",
    "Points": "Points",
    "OutOf": "Out of",
    "Players": "Players",
    "OmerFrankel": "Omer Frankel",
    "IReadChapter": "I read the chapter",
    "LearnNextChapter": "Learn the next chapter",
    "SelectBook": "Select a book",
    "JoinGroup": "Join to group",
    "Join": "Join",
    "Code": "Code",
    "AddComment": "Add Comment",
    "Publish": "Publish",
    "Next": "Next",
    "Backward": "Back",
    "NewUser": "I am a new user",
    "D1": "S'",
    "D2": "M'",
    "D3": "T'",
    "D4": "W'",
    "D5": "T'",
    "D6": "F'",
    "D7": "S'",
    "ShowMore": "Show more...",
    "ShowCommentsBtn": "Show <strong>{0}</strong> comments to this topic",
    "WeekDoneMsg": "We finished another week of <br /> daily study!",
    "TypeYourCommentMsg": "Type your comment here",
    "DoneClickHereMsg": "Are you done? Click here to get points",
    "PasswordSentToEmailMsg": "Your login information has been sent to your email!",
    "EmailNotExistMsg": "The email does not exist!",
    "WrongUserNameOrPasswordMsg": "Wrong username or password!",
    "YouRecivedPointsMsg": "You have received {0} points for this chapter",
    "MoreIdeasMsg": "For more ideas and extensions",
    "CreditsMsg": "The questions were written by Doron Kadosh, Eitan Yair Strasberg and members of the Young Leadership in the Bible, the Patrol Hotem",
    "JoinBibleQuizMsg": "To join the Bible Quiz",
    "YouHaveFinishedAnotherChapterMsg": "You've finished another chapter in the book",
    "NextChapterInMsg": "The next chapter will open in",
    "YouHaveLearnedMsg": "You have learned {0}",
    "OfPublishedMsg": "out of {0} chapters",
    "LoginErrorOccurred": "An error occurred while signing in, please try again later!",
    "JoinedGroupMsg": "You have successfully joined Group {0}",
    "UserPlayGroupExistMsg": "You have already joined Group {0}",
    "GroupNotFoundMsg": "Group not found!",
    "EnterGroupCode": "Enter your group code",
    "ToLearning": "To the study",
    "CreateGroupAndGoMsg": "Create a study group and begin!",
    "WhatIsYourGroupName": "What is your group's name?",
    "CreateGroup": "Create a group",
    "CreatePlayGroup": "Create a study group",
    "YouCantJoinMultipleGroupsMsg": "You cannot join more than one group",
    "PlayGroupCodeNotFound": "The group code does not exist in the system!",
    "NewGroupCreatedMsg": "You created a new study group!",
    "GroupCode": "The group code",
    "CopyCode": "Copy code",
    "InviteFriendsByShare": "Invite friends by having them join the group",
    "ShareGroupLink": "Share a link with the group",
    "ShowGroup": "Show group",
    "CodeCopied": "The code was copied!",
    "Group": "Group",
    "TotalGroupPoints": "Total group points",
    "GroupMembers": "Group members",
    "GroupAdmin": "Group director",
    "MyPoints": "My points",
    "GroupPoints": "Group points",
    "You": "You",
    "MyGroup": "My Group",
    "ForgatGroupCode": "Forgot your group code? Click here",
    "Month": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]
};
