import { Component, ViewChild, ElementRef, HostListener, Inject } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../../Shared/Services/Login.service";
import { Model } from "../../Shared/Services/Model.service";
import { AppService } from "../../Shared/Services/App.service";

@Component({
    templateUrl: "./SplashScreen.component.html",
    styleUrls: ["./SplashScreen.component.css"]
})
export class SplashScreenComponent {
    public MinHeight: number;
    public Max: boolean;
    public SplashImg: SafeStyle

    private LoginResponse: boolean = false;
    private IsLogedIn: boolean = false;
    private DailyStudyResponse: boolean = false;
    private DailyStudyId: number;

    constructor(private Router: Router, private ActivatedRoute: ActivatedRoute, private LoginService: LoginService, private Model: Model, public App: AppService, private Sanitizer: DomSanitizer, @Inject("SystemURL") private SystemURL: string) {
        this.SplashImg = this.Sanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/Splash/${this.App.AppId}.jpg')`);
    }

    ngAfterContentInit(): void {
        this.OnResize();

        this.LoginService.IsLoggedIn(this.ActivatedRoute.snapshot.params.App || 1).subscribe(p => {
            this.LoginResponse = true;
            this.IsLogedIn = p.IsLogin;
        });

        this.Model.DailyStudy.GetTodayDailyStudy(false).subscribe(p => {
            this.DailyStudyId = p;
            this.DailyStudyResponse = true;
        }, err => {
            this.DailyStudyId = 1;
            this.DailyStudyResponse = true;
        });

        setTimeout(() => {
            this.Login();
        }, 5000);
    }

    private Login(): void {
        if (this.LoginResponse && this.DailyStudyResponse) {
            if (this.IsLogedIn) {
                this.Router.navigate(["/", this.App.AppId, "Main", "Home", this.DailyStudyId]);
            }
            else {
                this.Router.navigate(["/", this.App.AppId, "Welcome"]);
            }
        }
        else {
            setTimeout(() => { this.Login() }, 500);
        }
    }

    @HostListener("window:resize")
    private OnResize(): void {
        let Ratio = 1.514;
        let Width = window.innerWidth > 640 ? 640 : window.innerWidth;

        this.MinHeight = Width * Ratio;
        this.Max = this.MinHeight > window.innerHeight && window.innerWidth <= 640;
    }
}