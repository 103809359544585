import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LoginService } from "../Services/Login.service";
import { Observable } from "rxjs/Observable";

@Injectable()
export class NotFoundGuard implements CanActivate {
    constructor() { }

    public canActivate(Route: ActivatedRouteSnapshot, State: RouterStateSnapshot): boolean {
        if (Route.params.App) {
            return !isNaN(parseInt(Route.params.App));
        }

        return true;
    }
}