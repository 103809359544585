import { Injectable, Inject } from "@angular/core";
import { Http, URLSearchParams, Headers } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class Pages {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private App: AppService) { }

    public GetPage(Type: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        let Params = new URLSearchParams();
        Params.append("Type", Type.toString());

        return this.Http.get(`${this.API}/Page/GetPage`, { headers: this.headers, search: Params }).map(p => p.json());
    }

    public GetPopup(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        return this.Http.get(`${this.API}/Page/GetPopup`, { headers: this.headers }).map(p => p.json());
    }
}