<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Bonus" [style.background-position-y.px]="BackgroundY" [hidden]="!ShowPopup">
        <div class="BtnClose" (click)="Close()">X</div>
        <img class="Animation" src="assets/Images/animation.gif" #Anim />
        <img class="Icon" [src]="'assets/Images/' + SelectedIcon + '.png'" #Icon />
        <div class="BonusPoints" [style.height.px]="BonusHeight" [hidden]="!Data?.BonusPoints">
            <div class="Circle">
                <div class="Content">
                    <img src="assets/Images/gift-white.png" />
                    <div class="BonusAmount" [style.font-size.px]="(6 * BonusHeight / 100)">
                        +{{ Data?.BonusPoints }}
                    </div>
                </div>
            </div>
        </div>
        <div class="Txt" [style.height.px]="MinHeight">
            <div class="Content" [class.Single]="!ShowBtn">
                <div #Text>
                    <h1>{{ Title }}</h1>
                    <div class="Text" [innerHTML]="Content"></div>
                </div>

                <div class="Btns" #Btns *ngIf="ShowBtn" (click)="OnBtnClick()">
                    <div class="Btn">
                        {{ BtnText }}
                        <div class="BtnIcon"></div>
                    </div>
                </div>

            </div>
        </div>
        <div class="Footer" #Footer>
            <div class="Lock" *ngIf="!NextDailyStudyAvailable && HasNextDailyStudyId">
                <div class="LockIcon"></div>
                <div class="Content">
                    <div class="Title">{{ Resource.Get("NextChapterInMsg") }}</div>
                    <div class="Time">
                        {{ Hours < 10 ? '0' + Hours : Hours }}:{{ Minitues < 10 ? '0' + Minitues : Minitues }}:{{ Seconds < 10 ? '0' + Seconds : Seconds }} {{ Resource.Get("Hours") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>