<div class="SharePopup" [hidden]="!Show">
    <div class="BtnClose">
        <img src="assets/Images/close.png" (click)="Close()" />
    </div>

    <div class="ShareBtns">
        <a [href]="EmailURL">
            <div class="Btn">
                <img src="assets/Images/email.png" />
            </div>
        </a>
        <a [href]="FacebookURL" target="_blank">
            <div class="Btn">
                <img src="assets/Images/facebook.png" />
            </div>
        </a>
        <a [href]="WhatsappURL" data-action="share/whatsapp/share" target="_blank">
            <div class="Btn">
                <img src="assets/Images/whats-up.png" />
            </div>
        </a>
    </div>
</div>