import { Directive, forwardRef, Attribute } from "@angular/core";
import { NG_VALIDATORS, Validator, Validators , AbstractControl, ValidatorFn } from "@angular/forms";

@Directive({
    selector: "[ValueValidator][ngModel]",
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValueValidator), multi: true }
    ]
})
export class ValueValidator implements Validator {
    constructor( @Attribute('ValueValidator') public Value: string) { }

    validate(Control: AbstractControl): { [key: string]: any } {
        if (Control.value == this.Value)
        {
            return {
                ValueValidator: "Wrong value"
            };
        }
        else
        {
            return null;
        }
    }
}