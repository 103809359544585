import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { Subject } from "rxjs/Subject";

import { NgModule } from "@angular/core";

import { MasterComponent } from "./Master/Master.component";
import { HomeComponent } from "./Home/Home.component";
import { LoginComponent } from "./Login/Login.component";
import { SplashScreenComponent } from "./SplashScreen/SplashScreen.component";
import { RegisterComponent } from "./Register/Register.component";
import { PointsComponent } from "./Points/Points.component";
import { EpisodesComponent } from "./Episodes/Episodes.component";
import { CircleButtonControlComponent } from "./CircleButtonControl/CircleButtonControl.component";
import { DailyStudyItemPopupComponent } from "./DailyStudyItemPopup/DailyStudyItemPopup.component";
import { MenuComponent } from "./Menu/Menu.component";
import { BonusPopupComponent } from "./BonusPopup/BonusPopup.component";
import { PageComponent } from "./Page/Page.component";
import { AutoCompleteControlComponent } from "./AutoCompleteControl/AutoCompleteControl.component";
import { SharePopupComponent } from "./SharePopup/SharePopup.component";
import { ForgatPasswordComponent } from "./ForgatPassword/ForgatPassword.component";
import { PlaceHolderComponent } from "./PlaceHolder/PlaceHolder.component";
import { ChampionsComponent } from "./Champions/Champions.component";
import { UpdatePopupComponent } from "./UpdatesPopup/UpdatePopup.component";
import { WelcomeScreenComponent } from "./WelcomeScreen/WelcomeScreen.component";
import { SchoolSelectorComponent } from "./SchoolSelector/SchoolSelector.component";
import { AddGroupComponent } from "./AddGroup/AddGroup.component";
import { AddGroupSuccessComponent } from "./AddGroupSuccess/AddGroupSuccess.component";
import { MyGroupComponent } from "./MyGroup/MyGroup.component";

import { NgForFilterPipe } from "../Shared/Pipes/NgForFilter.pipe";
import { SafeHtmlPipe } from "../Shared/Pipes/SafeHtml.pipe";
import { SafeStylePipe } from "../Shared/Pipes/SafeStyle.pipe";

import { ValueValidator } from "../Shared/Directives/ValueValidator.directive";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { AccessibilityComponent } from './accessibility/accessibility.component';

const MenuSubject = new Subject<any>();
const MasterSubject = new Subject<any>();

export function GetMenuSubject() {
    return MenuSubject;
}

export function GetMasterSubject() {
    return MasterSubject;
}

@NgModule({
    declarations: [
        MasterComponent,
        HomeComponent,
        CircleButtonControlComponent,
        LoginComponent,
        SplashScreenComponent,
        RegisterComponent,
        ValueValidator,
        PointsComponent,
        EpisodesComponent,
        DailyStudyItemPopupComponent,
        MenuComponent,
        BonusPopupComponent,
        PageComponent,
        AutoCompleteControlComponent,
        NgForFilterPipe,
        SharePopupComponent,
        ForgatPasswordComponent,
        PlaceHolderComponent,
        ChampionsComponent,
        UpdatePopupComponent,
        WelcomeScreenComponent,
        SchoolSelectorComponent,
        AddGroupComponent,
        AddGroupSuccessComponent,
        MyGroupComponent,
        SafeHtmlPipe,
        SafeStylePipe,
        AccessibilityComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        FormsModule,
        MalihuScrollbarModule.forRoot()
    ],
    providers: [{ provide: "MenuSubject", useFactory: GetMenuSubject }, { provide: "MasterSubject", useFactory: GetMasterSubject }]
})
export class MainModule { }