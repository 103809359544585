<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]" *ngIf="ShowSelector">  
    <div class="Selector">
        <div class="Container">
            <h1>{{ Resource.Get("SelectSchool") }}</h1>
            <div class="Input">
                <AutoCompleteControl [SearchMethod]="SearchSchools" (change)="ShoolSelected($event)"></AutoCompleteControl>
            </div>

            <div class="BtnLogin" (click)="Login()">
                <div class="Txt">
                    {{ Resource.Get("Continue") }}
                </div>
            </div>
        </div>
    </div>
</div>