<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Episodes">
        <div class="BtnClose" (click)="Close()">X</div>
        <h1>{{ Resource.Get("AllChapters") }}</h1>
        <select [(ngModel)]="SelectedGroup" (change)="Change()">
            <option value="0">{{ Resource.Get("SelectBook") }}</option>
            <option *ngFor="let item of MainGroups" [value]="item.Id">{{ item.Name }}</option>
        </select>
        <div *ngFor="let Group of Groups; let index = index">
            <div class="Title" *ngIf="Group.Childrens.length > 0" #Table [id]="Group.GroupId">
                {{ Group.GroupName }}
                <div class="Corner"></div>
            </div>
            <div class="Table Green" *ngIf="Group.Childrens.length > 0">
                <div class="Row" *ngFor="let Group2 of Group.Childrens">
                    <div class="Group A">
                        <div class="Col" [ngStyle]="{'background-color' : Group2.LastEnabled ? Colors[index % Colors.length].Bold : Colors[index % Colors.length].Regular, 'color':  Group2.LastEnabled ? '#ffffff' : ''}">
                            <div class="Content" [class.Disabled]="!Group2.GroupEnabled || !Group2.Enabled">
                                {{ Group2.GroupName }}
                            </div>
                        </div>
                    </div>
                    <div class="Group B">
                        <div class="Col" *ngFor="let Group3 of Group2.Childrens" [ngStyle]="{'background-color' : Group3.LastEnabled ? Colors[index % Colors.length].Bold : Colors[index % Colors.length].Regular, 'color':  Group3.LastEnabled ? '#ffffff' : '' }">
                            <div class="Content" [class.Disabled]="!Group3.GroupEnabled || !Group3.Enabled">
                                {{ Group3.GroupName }}
                            </div>
                            <div class="Sep"></div>
                            <div class="Group C" [ngStyle]="{'background-color' : Group3.Childrens.length < 2 ? Group3.Childrens[0].LastEnabled ? Colors[index % Colors.length].Bold : Colors[index % Colors.length].Regular : 'inherit' }">
                                <div class="Col" *ngFor="let Group4 of Group3.Childrens" (click)="GoToDailyStudyItem(Group4.DailyStudyId,Group4.Enabled)" [ngStyle]="{'background-color' : Group4.LastEnabled ? Colors[index % Colors.length].Bold : Colors[index % Colors.length].Regular, 'color':  Group4.LastEnabled ? '#ffffff' : ''}">
                                    <div class="Content" [class.Disabled]="!Group4.Enabled">
                                        {{ Group4.DailyStudyTitle }}
                                    </div>
                                    <div class="StatusIcon" [class.Enabled]="Group4.Enabled && !Group4.Done" [class.Done]="Group4.Done && Group4.Enabled" [class.Disabled]="!Group4.Enabled"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
