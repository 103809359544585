import { Injectable } from "@angular/core";
import { DailyStudy } from "../Model/DailyStudy.model";
import { Users } from "../Model/Users.model";
import { Schools } from "../Model/Schools.model";
import { Groups } from "../Model/Groups.model";
import { Pages } from "../Model/Pages.model";
import { Nugets } from "../Model/Nugets.model";
import { PlayGroups } from "../Model/PlayGroups.model";

@Injectable()
export class Model {
    constructor(public DailyStudy: DailyStudy,
        public Users: Users,
        public Schools: Schools,
        public Groups: Groups,
        public Pages: Pages,
        public PlayGroups: PlayGroups,
        public Nugets: Nugets) { }
}