<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <header [class.Styled]="App.IsStyled" [style.background-image]="PageStyle?.Header">
        <div class="Content">
            <div class="Logo" (click)="GoTodayDailyStudy()" [style.background-color]="App.LogoColor">
                <img [src]="LogoImg" />
            </div>
            <div class="Points" *ngIf="App.IsStyled">
                <div class="Text" [ngStyle]="PageStyle?.Score">
                    <div class="Star" [style.background-image]="PageStyle?.Points"></div>
                    {{ TotalPoints }}
                </div>
            </div>
            <div class="Title" *ngIf="!App.IsStyled">
                <div class="Content">
                    <h1 *ngIf="App.ShowTitle">{{ DailyStudy?.Title }}</h1>
                    <h2 *ngIf="App.ShowDate">
                        {{ DateEng }}
                        <ng-container *ngIf="App.Lang.Id == 1">
                            | {{ DateHeb }}
                        </ng-container>
                    </h2>
                </div>
            </div>
            <div class="Menu">
                <div class="BtnMenu" (click)="ToggleMenu()" *ngIf="App.Lang.Direction == 'RTL'">
                    <img *ngIf="App.IsStyled; else MenuIcon" [src]="PageStyle?.Menu" />
                </div>
                <div class="BtnUser" [routerLink]="['/', App.AppId, 'Main', 'Points']" *ngIf="!LoginService?.Anonymous && !App.ShowLogo">
                    <div class="ProfileImg" [style.background-image]="ProfilePicURL"></div>
                </div>

                <img class="LogoImg" [src]="LogoSmallImg" *ngIf="App.ShowLogo && App.IsStyled" />
 
                <div class="BtnMenu" (click)="ToggleMenu()" *ngIf="App.Lang.Direction == 'LTR'">
                    <img *ngIf="App.IsStyled; else MenuIcon" [src]="PageStyle?.Menu" />
                </div>
                <ng-template #MenuIcon>
                    <img src="assets/Images/menu.png" />
                </ng-template>
            </div>
            <div class="Scoreboard" *ngIf="!LoginService?.Anonymous && !App.IsStyled">
                <div class="Points Score" [class.Anim]="AnimScore">
                    <div class="Txt">
                        {{ TotalPoints }}
                    </div>
                    <div class="Icon"></div>

                </div>
                <div class="Points Bonus">
                    <div class="Txt">
                        +{{ TotalBonus }}
                    </div>
                    <div class="Icon"></div>
                </div>
            </div>
        </div>
    </header>


    <div class="Home" #Home [class.Styled]="App.IsStyled" [class.OverFlowHidden]="ShowBonus" [style.height]="PageStyle?.HeaderSize">
        <div class="Circles" #Container [style.height]="PageStyle?.ContentSize">
            <CircleButton #CircleButton *ngFor="let item of Circles; let index = index" [Size]="item.Size" [PositionX]="item.PosX" [PositionY]="item.PosY" [ParentW]="ParentWidth" [ParentH]="0" [Color]="item.Color" [Done]="item.Done" [AnimEnabled]="AnimEnabled" [IsStart]="item.IsStart" (click)="LoadChildrens(index, BtnC)" [hidden]="Circles.length < 2">
                <div class="BtnContent" #BtnC>
                    <div class="Container">
                        <div class="Content">
                            <div class="Text" [style.color]="item.Color?.EndColor" [style.font-size]="GetFontSize(25,item.Size) + 'px'" [style.line-height]="GetFontSize(27.75,item.Size) + 'px'">
                                {{ item.Title }}
                            </div>
                        </div>
                    </div>
                </div>
            </CircleButton>
            <CircleButton #CircleButton *ngFor="let item of ChildCircles; let index = index" [Size]="item.Size" [PositionX]="item.PosX" [PositionY]="item.PosY" [ParentW]="ChildParentWidth" [ParentH]="0" [Color]="item.KindColor" [Done]="item.Done" [AnimEnabled]="AnimEnabled" [KindId]="item.KindId" (click)="OnChildCircleButtonClick(index)">
                <div class="BtnContent">
                    <div class="Container">
                        <div class="Content">
                            <div class="Text" [style.font-family]="item.Font" [style.font-size]="GetFontSize(item.FontSize,item.Size) + 'px'" [style.line-height]="GetFontSize(item.LineHeight,item.Size) + 'px'">
                                {{ item.Title }}
                            </div>
                            <img *ngIf="!item.Fake && !item.Done" [src]="SystemURL + '/Kinds/' + item.KindId + '_B2.jpg'" />
                            <img *ngIf="item.CustomDoneIcon && item.Done" [src]="SystemURL + '/Kinds/' + item.KindId + '_D.jpg'"/>
                            <!-- {{item.CustomDoneIcon}} 1 -->
                            <!-- <div *ngIf="!item.CustomDoneIcon && item.Done " [src]="SystemURL + '/Kinds/' + item.KindId + '_D.jpg'"  class="DoneIcon " [class.Anim]="item.DoneAnim" [hidden]="item.Fake || !item.Done" style="background-image: url({{SystemURL}}/Kinds/{{item.KindId}}_D.jpg) "></div> -->
                            <img src="{{SystemURL}}/Kinds/{{item.KindId}}_D.jpg" *ngIf="!item.CustomDoneIcon && item.Done " onerror="this.onerror=null; this.src='https://herzognew2.mailman.co.il//Kinds/4_D.jpg'"/>
                            <!-- <div *ngIf="!item.CustomDoneIcon && item.Done" [src]="SystemURL + '/Kinds/' + item.KindId + '_D.jpg'"  class="DoneIcon" [class.Anim]="item.DoneAnim" [hidden]="item.Fake || !item.Done" style="background-image: url(assets/Images/Done.png);"></div> -->

                        </div>
                    </div>
                </div>
            </CircleButton>
        </div>

        <div class="Pager" [style.background-image]="PageStyle?.Footer">
            <div class="Content">
                <div class="BtnBack" [class.Enabled]="BackChapter != null && BackChapter?.Enabled" (click)="BackChapter?.Enabled ? GoToChapter(BackChapter?.Id) : ToogleTimer(BackChapter?.Id)">
                    <div class="BtnContainer">
                        <div class="BtnContent">
                            <div class="Icon" [style.background-image]="App.Lang.Direction == 'RTL' ? PageStyle?.Left : PageStyle?.Right"></div>
                            <div class="Txt" [style.color]="PageStyle?.NavTxtColor">
                                {{ App.IsStyled ? Resource.Get('Next') : BackChapter?.Title }}
                            </div>
                        </div>
                        <div class="Timer" *ngIf="ShowTimer" [style.background-image]="PageStyle?.Timer">
                            <div class="Inner">
                                <div class="Lock" *ngIf="App.IsStyled">
                                    <img [src]="PageStyle?.Lock" />
                                </div>
                                <div class="TimerTxt">
                                    {{ TimerHours < 10 ? '0' + TimerHours : TimerHours }}:{{ TimerMinitues < 10 ? '0' + TimerMinitues : TimerMinitues  }}:{{ TimerSeconds < 10 ? '0' + TimerSeconds : TimerSeconds }}
                                </div>
                                <div class="Lock" *ngIf="!App.IsStyled">
                                    <img src="assets/Images/lock.png" />
                                </div>
                                <div class="Arrow" *ngIf="!App.IsStyled"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Date" *ngIf="App.IsStyled" [style.color]="PageStyle?.DateTxtColor">
                    <div class="Name" *ngIf="App.ShowTitle">
                        {{ DailyStudy?.Title }}
                    </div>
                    <time *ngIf="App.ShowDate" [class.Space]="App.ShowTitle">
                        {{ DateEng }}
                    </time>                 
                </div>
                <div class="BtnNext" [class.Enabled]="NextChapter != null && NextChapter.Enabled" (click)="NextChapter?.Enabled ? GoToChapter(NextChapter?.Id) : GoToChapter(null)">
                    <div class="BtnContainer">
                        <div class="BtnContent">
                            <div class="Icon" [style.background-image]="App.Lang.Direction == 'RTL' ? PageStyle?.Right : PageStyle?.Left"></div>
                            <div class="Txt" [style.color]="PageStyle?.NavTxtColor">
                                {{ App.IsStyled ? Resource.Get('Backward') : NextChapter?.Title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <router-outlet></router-outlet>
    </div>

    <BonusPopup #BonusPopup (Close)="OnBonusClose()"></BonusPopup>
    <Updates></Updates>
    <div class="Preaload"></div>
</div> 
