import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Model } from "../Services/Model.service";
import { LoginService } from "../Services/Login.service";
import { Observable } from "rxjs/Observable";

@Injectable()
export class SplashGuard implements CanActivate {
    constructor(private Model: Model, private LoginService: LoginService, private Router: Router) { }

    public canActivate(Route: ActivatedRouteSnapshot, State: RouterStateSnapshot): Observable<boolean> {
        return Observable.create(o => {
            let AppId = Route.params.App || 1;

            this.Model.Nugets.ShowSplashScreen(AppId).subscribe(p => {
                if (p) {
                    o.next(true);
                }
                else {
                    o.next(false);

                    this.Model.DailyStudy.GetTodayDailyStudy(false, AppId).subscribe(p => {
                        this.Router.navigate(["/", AppId, "Main", "Home", p]);
                    }, e => {
                        console.log(AppId);

                        this.Router.navigate(["/", AppId, "Welcome"]);
                    });
                }
            });
        });
    }
}