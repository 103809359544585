import { Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl, SafeStyle } from "@angular/platform-browser";
import { LoginService } from "../../Shared/Services/Login.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { Model } from "../../Shared/Services/Model.service";
import { Observer } from "rxjs/Observer";

@Component({
    templateUrl: "./Champions.component.html",
    styleUrls: ["./Styles/Champions.component.rtl.scss", "./Styles/Champions.component.ltr.scss"]
})
export class ChampionsComponent {
    public ProfilePicURL: SafeStyle;
    public LogoImg: SafeResourceUrl;
    public Users: Array<any>;
    public Schools: Array<any>;
    public SelectedTab: number = 1;

    constructor( @Inject("MenuSubject") private Menu: Observer<any>, @Inject("SystemURL") private SystemURL: string, private LoginService: LoginService, public App: AppService, public Resource: ResourcesService, private Model: Model, private DomSanitizer: DomSanitizer, private Router: Router) {
        this.LogoImg = this.DomSanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/Logos/${this.App.AppId}.jpg`);
        this.ProfilePicURL = this.DomSanitizer.bypassSecurityTrustStyle(`url('http://herzognew.mailman.co.il/Uploads/UserProfile/${this.LoginService.UserId}.jpg')`);
    }

    private ngOnInit(): void {
        this.Model.DailyStudy.GetMostPointsUsers().subscribe(p => {
            this.Users = p.map(User => {
                User.ProfilePicURL = this.DomSanitizer.bypassSecurityTrustStyle(`url('http://herzognew.mailman.co.il/Uploads/UserProfile/${User.Id}.jpg'),url('http://herzognew2.mailman.co.il/assets/Images/profile-man.png')`);

                return User;
            });
        });

        if (this.App.PlayGroups) {
            this.Model.PlayGroups.GetMostPointsPlayGroups().subscribe(p => {
                this.Schools = p;
            });
        }
        else {
            this.Model.DailyStudy.GetMostPointsSchools().subscribe(p => {
                this.Schools = p;
            });
        }
    }

    public ToggleMenu(): void {
        this.Menu.next(0);
    }

    public SetTab(Tab: number): void {
        this.SelectedTab = Tab;
    }

    public ProfileImgNotFound(Img: HTMLImageElement): void {
        Img.src = "assets/Images/profile-man.png";
    }

    public Sanitize(Url: string) {
        return this.DomSanitizer.bypassSecurityTrustStyle(Url);
    }

    public GoTodayDailyStudy(): void {
        this.Model.DailyStudy.GetTodayDailyStudy(true).subscribe(p => {
            this.Router.navigate(["/", this.App.AppId, "Main", "Home", p]);
        });
    }
}