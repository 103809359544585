import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AppModes } from "../Interfaces/Nugets.interfaces";

@Injectable()
export class AppService {
  public AppId: number;
  public StyleId: number;
  public Mode: AppModes;
  public PlayGroups: boolean;
  public EnableClass: boolean;
  public AllChapterBtn: boolean;
  public ShowTitle: boolean;
  public ShowDate: boolean;
  public ShowLogo: boolean;
  public LogoColor: string;
  public SplashColor: string;
  public IsStyled: boolean;
  public ScoreBackground: string;
  public ScoreTxtColor: string;
  public ScoreTxtShadowColor: string;
  public NavTxtColor: string;
  public DateTxtColor: string;
  public Url: string;
  public AppName: string;
  public TrackId: string;

  public Lang = {
    Id: 1,
    Direction: "RTL"
  };

  constructor(private router: Router) {
    this.router.events.filter(p => p instanceof NavigationEnd).subscribe((p: NavigationEnd) => {
      if ((<any>window).gtag) {
        (<any>window).gtag('config', this.TrackId, {
          'page_path': p.urlAfterRedirects,
          'page_title': this.AppName
        });
      }
    });
  };
}

