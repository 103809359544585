import { Injectable, Inject } from "@angular/core";
import { URLSearchParams, Headers, Http } from "@angular/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class Schools {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private App: AppService) { }

    public GetSchools(CityId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("CityId", CityId.toString());
        Params.append("Lang", this.App.Lang.toString());

        return this.Http.get(`${this.API}/Schools/GetSchools`, { headers: this.headers, search: Params, withCredentials: true }).map(p => p.json());
    }

    public GetCities(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("Lang", this.App.Lang.toString());

        return this.Http.get(`${this.API}/Schools/GetCities`, { headers: this.headers, withCredentials: true, search: Params }).map(p => p.json());
    }

    public SearchSchools(Keys: string) {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("Lang", this.App.Lang.toString());
        Params.append("App", this.App.AppId.toString());

        if (Keys)
            Params.append("Keys", Keys);

        return this.Http.get(`${this.API}/Schools/SearchSchool`, { headers: this.headers, search: Params, withCredentials: true }).map(p => p.json());
    }
}