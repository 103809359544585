import { Component, HostListener, ViewChild, ElementRef, Inject, Output, EventEmitter } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Observer } from "rxjs/Observer";
import { Model } from "../../Shared/Services/Model.service";
import { PopupService } from "../../Shared/Services/Popup.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";

const AnimRatio = 1.288;
const IconRatio = 1;

@Component({
    selector: "BonusPopup",
    templateUrl: "./BonusPopup.component.html",
    styleUrls: ["./Styles/BonusPopup.component.rtl.scss", "./Styles/BonusPopup.component.ltr.scss"]
})
export class BonusPopupComponent {
    public BackgroundY: number = 0;
    public MinHeight: number = 0;
    public BonusHeight: number = 0;
    public SelectedIcon: number;

    public Data: any;
    public Title: string;
    public BtnText: string;
    public BtnLink: string;
    public Content: SafeHtml;
    public ShowBtn: boolean;
    public ShowPopup: boolean = false;
    public NextDailyStudyAvailable: boolean = true;
    public HasNextDailyStudyId: boolean;

    public Hours: number;
    public Minitues: number;
    public Seconds: number;

    private TM: any;
    private NextDailyStudyId: number;
  

    constructor( @Inject("MasterSubject") private MasterObserver: Observer<any>,
        private Model: Model,
        private Router: Router,
        private Sanitizer: DomSanitizer,
        private PopupService: PopupService,
        public App: AppService,
        public Resource: ResourcesService) { }

    private ngAfterContentInit(): void {
        this.OnResize();
    }

    @ViewChild("Footer")
    private Footer: ElementRef;

    @ViewChild("Text")
    private Text: ElementRef;

    @ViewChild("Btns")
    private Btns: ElementRef;

    @Output("Close")
    private Closed = new EventEmitter();

    @HostListener("window:resize")
    public OnResize(): void {
        if (this.Footer && this.ShowPopup) {
            let Width = document.getElementById("Main").clientWidth;
            let Height = window.innerHeight;

            if (Width > 640)
                Width = 640;

            let W = (Width * 20) / 100;
            let H = this.Text.nativeElement.clientHeight + (this.Btns ? this.Btns.nativeElement.clientHeight + 45 : 0);

            let IconHeight = (((Width * 51.25) / 100) + ((Width * 20) / 100)) / IconRatio;

            this.BackgroundY = (W - 354) + ((IconHeight * 40) / 100);
            this.BonusHeight = IconHeight;

            this.MinHeight = Height - (Width / AnimRatio) - this.Footer.nativeElement.clientHeight;

            if (this.MinHeight < H) {
                this.MinHeight = H;
            }
        }
    }

    public Open(Data: any): void {
        this.Data = Data;
        this.ShowPopup = true;

        if (Data.UserPoints <= 30) {
            this.SelectedIcon = 4;
        }
        else if (Data.UserPoints > 30 && Data.UserPoints < 40) {
            this.SelectedIcon = 3;
        }
        else if (Data.UserPoints > 40 && Data.UserPoints < 70) {
            this.SelectedIcon = 2;
        }
        else {
            this.SelectedIcon = 1;
        }

        this.Model.DailyStudy.IsDailyStudyAvailable(Data.DailyStudySortId).subscribe(p => {
            this.NextDailyStudyAvailable = p.Status == "Enabled";
            this.NextDailyStudyId = p.DailyStudyId;
            this.HasNextDailyStudyId = p.DailyStudyId ? true : false;
            this.Hours = p.TotalHours;
            this.Minitues = p.TotalMinitues;
            this.Seconds = p.TotalSeconds;

            if (!this.NextDailyStudyAvailable) {
                this.TM = setTimeout(() => { this.Timer() }, 1000);
            }
        });

        this.Model.Pages.GetPage(3).subscribe(p => {
            this.Content = this.Sanitizer.bypassSecurityTrustHtml(p.Page.Content);
            this.Title = p.Page.Title;
            this.ShowBtn = p.Page.IsBtn;
            this.BtnText = p.Page.BtnTxt;
            this.BtnLink = p.Page.PageLink;

            this.OnResize();

            setTimeout(this.OnResize.bind(this), 10);
        });

        this.OnResize();

        setTimeout(this.OnResize.bind(this), 100);
        setTimeout(this.OnResize.bind(this), 500);
    }

    public Timer(): void {
        if (this.Seconds > 0) {
            this.Seconds--;
        }
        else {
            if (this.Minitues > 0) {
                this.Seconds = 59;
                this.Minitues--;
            }
            else {
                if (this.Hours > 0) {
                    this.Hours--;
                    this.Minitues = 59;
                    this.Seconds = 59;
                }
                else {
                    return;
                }
            }
        }

        this.TM = setTimeout(() => { this.Timer() }, 1000);
    }

    public Close(): void {
        clearTimeout(this.TM);
        this.NextDailyStudyAvailable = true;
        this.ShowPopup = false;
        this.MasterObserver.next(500);
        this.Closed.emit();
    }

    public Next(): void {
        clearTimeout(this.TM);
        this.ShowPopup = false;
        this.MasterObserver.next(500);

        this.Router.navigate(["/", this.App.AppId, "Main", "Home", this.NextDailyStudyId]);
    }

    public OnBtnClick(): void {
        if (this.BtnLink) {
            window.open(this.BtnLink, "_blank");
        }
        else {
            this.Share();
        }
    }

    private Share(): void {
        this.PopupService.ShowSharePopup(this.Data.UserPoints);
    }

    private ngOnDestroy(): void {
        this.MasterObserver.next(500);
    }
} 