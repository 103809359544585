import { Component } from "@angular/core";
import { Model } from "../../Shared/Services/Model.service";
import { CookiesService } from "../../Shared/Services/Cookies.service";
import { AppService } from "../../Shared/Services/App.service";

@Component({
    selector: "Updates",
    templateUrl: "./UpdatePopup.component.html",
    styleUrls: ["./UpdatePopup.component.css"]
})
export class UpdatePopupComponent {
    public ShowPopup: boolean = false;
    public Page: any;

    constructor(private Model: Model, private CookiesService: CookiesService, private App: AppService) { }

    private ngOnInit(): void {
        this.Model.Pages.GetPopup().subscribe(p => {
            if (p.Page != -1) {
                this.Page = p.Page;

                let UpdateDate = new Date(p.Year, p.Month, p.Day, p.Hours, p.Minits);
                let Cookie: any = null;

                if (localStorage) {
                    Cookie = JSON.parse(localStorage.getItem("UpdatePopup" + this.App.AppId));
                }
                else {
                    Cookie = JSON.parse(this.CookiesService.Get("UpdatePopup" + this.App.AppId));
                }

                let D;

                if (Cookie != null) {
                    D = new Date(Cookie.Year, Cookie.Month, Cookie.Day, Cookie.Hours, Cookie.Minits);
                }

                if (Cookie == null || D < UpdateDate) {
                    this.ShowPopup = true;

                    if (localStorage) {
                        localStorage.setItem("UpdatePopup" + this.App.AppId, JSON.stringify({ Day: UpdateDate.getDate(), Month: UpdateDate.getMonth(), Year: UpdateDate.getFullYear(), Hours: UpdateDate.getHours(), Minits: UpdateDate.getMinutes() }));
                    }
                    else {
                        this.CookiesService.Create("UpdatePopup" + this.App.AppId, JSON.stringify({ Day: UpdateDate.getDate(), Month: UpdateDate.getMonth(), Year: UpdateDate.getFullYear(), Hours: UpdateDate.getHours(), Minits: UpdateDate.getMinutes() }), 1000);
                    }
                }
            }
        });

    }

    public Close(): void {
        this.ShowPopup = false;
    }
}