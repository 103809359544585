import { Inject, Injectable } from "@angular/core";
import { Http, URLSearchParams, Headers } from "@angular/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class DailyStudy {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private Router: Router, private App: AppService) { }

    public GetDailyStudy(id: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("Id", id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetDailyStudy`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetDailyStudyItemChildrens(DailyStudyId: number, DailyStudyItemId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("DailyStudyId", DailyStudyId.toString());
        Params.append("DailyStudyItemId", DailyStudyItemId.toString());
        return this.Http.get(`${this.API}/DailyStudy/GetDailyStudyItemChildrens`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }


    public GetTotalDailyStudyAvailable(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetTotalDailyStudyAvailable`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }


    public GetDailyStudyItemChild(DailyStudyId: number, DailyStudyItemChildId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("DailyStudyId", DailyStudyId.toString());
        Params.append("DailyStudyItemChildId", DailyStudyItemChildId.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetDailyStudyItemChild`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }
            return Observable.throw(p);
        }).map(p => p.json());
    }

    public ReadDailyStudyItem(DailyStudyId: number, DailyStudyItemChildId: number, TestPoints: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.post(`${this.API}/DailyStudy/ReadDailyStudyItem`, { DailyStudyId: DailyStudyId, DailyStudyItemChildId: DailyStudyItemChildId, TestPoints: TestPoints }, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetFirstDailyStudy(RedirectWhenFail: boolean): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetFirstDailyStudy`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401 && RedirectWhenFail) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetTodayDailyStudy(RedirectWhenFail: boolean, App?: number): Observable<any> {
        let AppId = App ? App : this.App.AppId;

        if (localStorage && localStorage.getItem("Token" + AppId) != "" && localStorage.getItem("Token" + AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetTodayDailyStudy`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401 && RedirectWhenFail) {
                this.Router.navigateByUrl("/" + AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetMostPointsUsers(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetMostPointsUsers`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetMostPointsSchools(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetMostPointsSchools`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public IsDailyStudyAvailable(DailyStudySort: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("DailyStudySort", DailyStudySort.toString());

        return this.Http.get(`${this.API}/DailyStudy/IsDailyStudyAvailable`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetDailyStudyWeeklyStatus(DailyStudyId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("DailyStudyId", DailyStudyId.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetDailyStudyWeeklyStatus`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetDailyStudyRemainingTime(id: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("id", id.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetDailyStudyRemainingTime`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetDailyStudyList(Month: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("Month", Month.toString());

        return this.Http.get(`${this.API}/DailyStudy/GetDailyStudyList`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    /* public GetBonusMessage(Points: number): Observable<any> {
         if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
             this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
         }
 
         this.headers.set("Lang", this.App.Lang.Id.toString());
 
         let Params = new URLSearchParams();
         Params.append("Points", Points.toString());
 
         return this.Http.get(`${this.API}/DailyStudy/GetBonusMessage`, { headers: this.headers, search: Params, withCredentials: true }).catch(p => {
             if (p.status == 401) {
                 this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
             }
 
             return Observable.throw(p);
         }).map(p => p.json());
     }*/
}