<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <header>
        <div class="BtnClose" (click)="Close()">X</div>
        <h1>{{ Resource.Get("Group") }} {{ PlayGroup?.Name }}</h1> 
    </header>
    <div class="MyGroup">
        <section class="Points">
            <img src="/assets/Images/CupBig.png" />
            <div class="Total">
                <span class="Big">{{ PlayGroup?.Points }}</span> {{ Resource.Get("Points") }}<br />
                <span class="Small">{{ Resource.Get("TotalGroupPoints") }}</span>
            </div>
        </section>
        <section class="Members">
            <div class="Code">
                {{ Resource.Get("GroupCode") }} <strong>{{ PlayGroup?.Code }}</strong>
            </div>
            <h1>{{ Members.length }} {{ Resource.Get("GroupMembers") }}</h1>     
            <div class="Content">
                <div class="Inner">
                    <div class="Member" *ngFor="let Member of Members">
                        <div class="Profile" [style.background-image]="Member.ProfilePic"></div>
                        <div class="Name" *ngIf="!Member.IsCurrentUser">
                            {{ Member.FirstName }} {{ Member.LastName }}
                            <ng-container *ngIf="Member.IsAdmin">
                                {{ Resource.Get("GroupAdmin") }}
                            </ng-container>
                        </div>
                        <div class="Name" *ngIf="Member.IsCurrentUser">
                            {{ Resource.Get("You") }}
                            <ng-container *ngIf="Member.IsAdmin">
                                {{ Resource.Get("GroupAdmin") }}
                            </ng-container>
                        </div>
                    </div>   
                </div>
            </div>
        </section>
        <section class="Share" (click)="Share()">      
            <div class="Icon" *ngIf="App.Lang.Direction == 'LTR'"></div>    
            <div class="Text">
                {{ Resource.Get("InviteFriendsByShare") }}<br />
                <span class="TxtBig">{{ Resource.Get("ShareGroupLink") }}</span>
            </div>
            <div class="Icon" *ngIf="App.Lang.Direction == 'RTL'"></div>
        </section>
    </div>
</div>