import { Component, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { Model } from "../../Shared/Services/Model.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { Subscription } from "rxjs/Subscription";

@Component({
    selector: "AutoCompleteControl",
    templateUrl: "./AutoCompleteControl.component.html",
    styleUrls: ["./Styles/AutoCompleteControl.component.rtl.scss", "./Styles/AutoCompleteControl.component.ltr.scss"]
})
export class AutoCompleteControlComponent {
    @Input()
    private SearchMethod: Function;

    @Input()
    public Error: boolean = false;

    @Output("change")
    public OnChange = new EventEmitter();

    public Text: string = "";
    public Show: boolean = false;
    public SelectedItem: any;
    public Items: Array<{ Title: string, Id: string }>;
    public Scroll: boolean;
    private Request: Subscription;

    constructor(private Model: Model, public App: AppService, public Resource: ResourcesService) { }

    public ngOnInit(): void {
        this.Search();
    }

    public Select(Event: Event, TextBox: HTMLElement, Id: string) {
        if (!this.Scroll) {
            Event.preventDefault();
            Event.stopPropagation();

            TextBox.blur();
            if(Id== "9999")
            {
                this.SelectedItem ="Other";
                this.Text = "Other";
            }
            else
            {
            this.SelectedItem = this.Items.find(p => p.Id == Id);

            this.OnChange.emit(this.SelectedItem);
            this.Text = this.SelectedItem.Title;

            this.Search();
            }

        }
    }

    public Search() {
        if (this.Request) {
            this.Request.unsubscribe();
        }

        this.Request = this.SearchMethod(this.Text).subscribe(p => {
            this.Items = p;
        });
    }

    public Toggle() {
        this.Show = !this.Show;
    }

    public OnFocus() {
        this.Show = true;
    }

    public OnFocusOut() {
        this.Show = false;
    }

    public OnTouchMove(): void {
        this.Scroll = true;
    }

    public OnTouchEnd() {
        this.Scroll = false;
    }
}