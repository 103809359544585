export interface NugetData {
  Id: number,
  Rtl: string,
  Mode: AppModes,
  LogoColor: string,
  SplashColor: string,
  PlayGroups: boolean,
  EnableClass: boolean,
  AllChapterBtn: boolean,
  StyleSet: number,
  ScoreBackground: string,
  ScoreTxtColor: string,
  ScoreTxtShadowColor: string,
  NavTxtColor: string,
  DateTxtColor: string,
  Url: string,
  Name: string,
  Description: string,
  IsStyled: boolean,
  TrackingId: string,
  ShowTitle: boolean,
  ShowDate: boolean,
  ShowLogo: boolean,
  Fonts: NugetFont[]
};

export interface NugetFont {
  Id: number
  Name: string,
  SystemURL: string
};

export enum AppModes {
  ByDate = 1,
  PersonalProgress = 2,
  Free = 3
};
