import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { AppService } from "../../Shared/Services/App.service";
import { PopupService } from "../../Shared/Services/Popup.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { Model } from "../../Shared/Services/Model.service";

@Component({
    selector: "AddGroupSuccess",
    templateUrl: "./AddGroupSuccess.component.html",
    styleUrls: ["./Styles/AddGroupSuccess.component.rtl.scss", "./Styles/AddGroupSuccess.component.ltr.scss"]
})
export class AddGroupSuccessComponent {
    public Show: boolean = false;
    public PlayGroup: any;

    constructor(public App: AppService, private Model: Model, private PopupService: PopupService, private Router: Router, private Location: Location, public Resource: ResourcesService) { }

    public Open(): void {
        this.Show = true;

        this.Model.PlayGroups.GetCurrentPlayGroup().subscribe(p => {
            this.PlayGroup = p;
        });
    };

    public Close(): void {
        this.Model.DailyStudy.GetTodayDailyStudy(false).subscribe(p => {
            this.Router.navigate(["/", this.App.AppId, "Main", "Home", p]);
        });
    };

    public GoToMyGroup(): void {
        this.Model.DailyStudy.GetTodayDailyStudy(false).subscribe(p => {
            this.Location.replaceState(`/${this.App.AppId}/Main/Home/${p}`);

            this.Router.navigate(["/", this.App.AppId, "Main", "MyGroup"]);
        });
    };

    public Share(): void {
        this.PopupService.ShowShareGroupPopup(this.PlayGroup.Code, this.PlayGroup.Name);
    };

    public Copy(TextBox: HTMLInputElement): void {
        TextBox.select();

        document.execCommand("copy");

        alert(this.Resource.Get("CodeCopied"));
    };
}