import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Http, URLSearchParams, Headers } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class Comments {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private Router: Router, private App: AppService) { }

    public GetTotalComments(Url: string): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("Url", Url);

        return this.Http.get(`${this.API}/Comments/GetTotalComments`, { search: Params, headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetComments(Url: string, Page: number): Observable<any> {

        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        let Params = new URLSearchParams();
        Params.append("Url", Url);
        Params.append("Page", Page.toString());
        Params.append("PageSize", '10');

        return this.Http.get(`${this.API}/Comments/GetComments`, { search: Params, headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public AddComment(Message: string, Url: string , DailyStudyId:string, DailyStudyItemChildId:string): Observable<any> {

        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.post(`${this.API}/Comments/AddComment`, { Message, Url,DailyStudyId,DailyStudyItemChildId } , { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }
}