<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <header>
        <div class="Logo" (click)="GoTodayDailyStudy()" [style.background-color]="App.LogoColor">
            <img [src]="LogoImg" />
        </div>
        <div class="Title">
            <div class="Content">
                <h1>{{ Resource.Get("Leaders") }}</h1>
            </div>
        </div> 
        <div class="Menu">
            <div class="BtnMenu" (click)="ToggleMenu()" *ngIf="App.Lang.Direction == 'RTL'">
                <img src="assets/Images/menu.png" />
            </div>
            <div class="BtnUser" [routerLink]="['/', App.AppId, 'Main', 'Points']">
                <div class="ProfileImg" [style.background-image]="ProfilePicURL"></div>
            </div>
            <div class="BtnMenu" (click)="ToggleMenu()" *ngIf="App.Lang.Direction == 'LTR'">
                <img src="assets/Images/menu.png" />
            </div>
        </div>
    </header>
    <div class="Champions">
        <div class="Container">
            <div class="Btns">
                <div class="Btn" (click)="SetTab(1)" [class.Selected]="SelectedTab == 1">
                    {{ Resource.Get("Players") }}
                </div>
                <div class="Btn" (click)="SetTab(2)" [class.Selected]="SelectedTab == 2" *ngIf="!App.PlayGroups && App.Lang.Id!=4">
                    {{ Resource.Get("Schools") }}
                </div>
                <div class="Btn" (click)="SetTab(2)" [class.Selected]="SelectedTab == 2" *ngIf="App.PlayGroups">
                    קבוצות
                </div>
            </div>
            <div class="Table" *ngIf="SelectedTab == 1">
                <div class="Headers">
                    <div class="Title Rank">
                        {{ Resource.Get("Rating") }}
                    </div>
                    <div class="Title Name">
                        {{ Resource.Get("Name") }}
                    </div>
                    <div class="Title Score">
                        {{ Resource.Get("Scores") }}
                    </div>
                </div>
                <div class="Rows">
                    <div class="Row" *ngFor="let Item of Users; let i = index;">
                        <div class="Col Rank">
                            <span class="Num">{{ i + 1 }}.</span>
                            <div class="ProfileImg" [style.background-image]="Item.ProfilePicURL"></div>
                        </div>
                        <div class="Col Name">
                            {{ Item.Title }}
                        </div>
                        <div class="Col Score">
                            {{ Item.Total }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="Table" *ngIf="SelectedTab == 2 ">
                <div class="Headers">
                    <div class="Title Rank">
                        {{ Resource.Get("Rating") }}
                    </div>
                    <div class="Title Name">
                        {{ Resource.Get("Name") }}
                    </div>
                    <div class="Title Score">
                        {{ Resource.Get("Scores") }}
                    </div>
                </div>
                <div class="Rows">
                    <div class="Row" *ngFor="let Item of Schools; let i = index;">
                        <div class="Col Rank">
                            <span class="Num">{{ i + 1 }}.</span>
                        </div>
                        <div class="Col Name">
                            {{ Item.Title }}
                        </div>
                        <div class="Col Score">
                            {{ Item.Total }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>