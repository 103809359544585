<div class="accessibility-close" id="accessibility-close" (click)="Close()">

</div>
<div class="accessibility-toggle" id="accessibility"  (click)="Open()">
    <img src="/assets/Images/Accessibility/disabled.png" alt="">
</div>
<div class="accessibility-tool"  id="stickySidebar">

    <h2>נגישות הגדרות</h2>
    <ul class="accessibility-tool-list">
       <!-- <li class="accessibility-tool-item first">
           <div class="accessibility-tool-top">
               <img src="/assets/Images/Accessibility/disabled.png" alt="">
               הצהרת נגישות
           </div>
       </li> -->
        <!-- <li class="accessibility-tool-item">
            <button id="screen-render" class="button-full" (click)="screenReader()">
            </button>
            <div class="accessibility-tool-info">
                <span>Screen reader</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/volume.png" alt=""></div>
            </div>
        </li> -->
        <li class="accessibility-tool-item">
            <button id="greyScale" class="button-full" (click)="greyScale()">
            </button>
            <div class="accessibility-tool-info">
                <span>Black / white screen</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/monitor-levels-range.png" alt=""></div>
            </div>
        </li>
        <!-- <li class="accessibility-tool-item">
            <button id="keyboard-navigation" class="button-full" (click)="keyboardNavigation()">
            </button>
            <div class="accessibility-tool-info">
                <span>Keyboard navigation</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/keyboard.png" alt=""></div>
            </div>
        </li> -->
        <!-- <li class="accessibility-tool-item">
            <button id="coloring-links" class="button-full" (click)="marklinks()">
            </button>
            <div class="accessibility-tool-info">
                <span>Coloring links</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/url.png" alt=""></div>
            </div>
        </li> -->
        <!-- <li class="accessibility-tool-item">
            <button id="highlighting-links" class="button-full" (click)="underline()">
            </button>
            <div class="accessibility-tool-info">
                <span>Marking links</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/underline.png" alt=""></div>
            </div>
        </li> -->
        <li class="accessibility-tool-item" id="dark-contrastd">
            <button id="dark-contrast" class="button-half" (click)="darkContrast()" >
            </button>
            <!-- (click)="darkContrast()" -->
            <div class="fonts-controls" style="background-color: rgba(0,0,0,dark) !important;color: white !important;">
                <button id="dark-con-in" (click)="changeDark('+')">+</button>
                <button id="dark-con-de" (click)="changeDark('-')">-</button>
            </div>
            <div class="accessibility-tool-info">
                <span>Level of contrast</span>
                <!-- <mat-slider min="0" max="10" step="0.5" value="1.5"></mat-slider> -->
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/brightness.png" alt=""></div>
            </div>
        </li>
       
         <li class="accessibility-tool-item" id="bright-contrastb">
            <button id="bright-contrast" class="button-half" (click)="brightContrast()">
            </button>
            <!-- (click)="brightContrast()" -->
            <!-- <div class="fonts-controls" >
                <button id="bright-con-in" (click)="changeBright('+')">+</button>
                <button id="bright-con-de" (click)="changeBright('-')">-</button>
            </div> -->
            <div class="accessibility-tool-info">
                <span>Bright contrast</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/brightness.png" alt=""></div>
            </div>
        </li>  
        <li class="accessibility-tool-item" id="dark-contrastf">
            <button id="btn-fonts" class="button-half" (click)="addFont()">
            </button>
            <div class="fonts-controls">
                <button id="enlarge-font" (click)="changeFont('+')">+</button>
                <button id="small-font" (click)="changeFont('-')">-</button>
            </div>
            <div class="accessibility-tool-info">
                <span>Font size</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/font-change.png" alt=""></div>
            </div>
        </li>  
        
         <li class="accessibility-tool-item">
            <button id="readable-font" class="button-full" (click)="readableFonts()">
            </button>
            <div class="accessibility-tool-info">
                <span>Readable font</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/readable.png" alt=""></div>
            </div>
        </li>
        <!-- <li class="accessibility-tool-item">
            <button id="restoration-souls" class="button-full" (click)="animation()">
            </button>
            <div class="accessibility-tool-info">
                <span>Animation</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/360-video.png" alt=""></div>
            </div>
        </li> -->
        <li class="accessibility-tool-item hide-onmobile">
            <button id="cu-black" class="button-full" (click)="blackCursor()">
            </button>
            <div class="accessibility-tool-info">
                <span>Black Marker</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/cursor-black.png" alt=""></div>
            </div>
        </li>
        <li class="accessibility-tool-item hide-onmobile">
            <button id="cu-white" class="button-full" (click)="whiteCursor()">
            </button>
            <div class="accessibility-tool-info">
                <span>White Marker</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/cursor-white.png" alt=""></div>
            </div>
        </li>   
        <li class="accessibility-tool-item">
            <button id="align-left" class="button-full" (click)="textLeftAlign()">
            </button>
            <div class="accessibility-tool-info">
                <span>Text align left</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/text-align-left.png" alt=""></div>
            </div>
        </li> 
        <li class="accessibility-tool-item">
            <button id="align-center" class="button-full" (click)="textCenterAlign()">
            </button>
            <div class="accessibility-tool-info">
                <span>Text align center</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/text-align-center.png" alt=""></div>
            </div>
        </li>
        <li class="accessibility-tool-item">
            <button id="align-right" class="button-full" (click)="textRightAlign()">
            </button>
            <div class="accessibility-tool-info">
                <span>Text align right</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/text-align-right.png" alt=""></div>
            </div>
        </li>   
        <!--<li class="accessibility-tool-item">
            <button id="li-height" class="button-full">
            </button>
            <div class="accessibility-tool-info">
                <span>Line height</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/line-height.png" alt=""></div>
            </div>
        </li> 
        <li class="accessibility-tool-item">
            <button id="le-space" class="button-full">
            </button>
            <div class="accessibility-tool-info">
                <span>Letter spacing</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/letter-spacing.png" alt=""></div>
            </div>
        </li>-->          
        <!--<li class="accessibility-tool-item">
            <button id="enlarge-font" class="button-half" (click)="zoomdata()">
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/zoom.png" alt="" style="width:20px;height:20px;">
                <span>Enlarge screen</span>
            </button>
            <button id="reset-font-icon" class="button-half" (click)="clearFont()">
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/f_r.png" alt="">
                <span>Reset fonts and icons</span>
            </button>
        </li>
        
        <li class="accessibility-tool-item">
            <button id="restoration-souls" class="button-full">
                <i class="custom-color" style="background: #FFFFFF !important" data-color="#000000" data-backgroud="#FFFFFF" (click)="whiteBackground()"></i>
                <i class="custom-color" style="background: #F3FF3B !important" data-color="#FFFFFF" data-backgroud="#F3FF3B" (click)="yellowBackground()"></i>
                <i class="custom-color" style="background: #FF2644 !important" data-color="#FCFDFF" data-backgroud="#FF2644" (click)="redBackground()"></i>
                <span>color change</span>
            </button>
        </li>
        <li class="accessibility-tool-item">
            <button id="clean-color" class="button-full" (click)="cleanColor()">
                <span>Clean color</span>
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/f_r.png" alt="">
            </button>
        </li>
        <li class="accessibility-tool-item">
            <button id="clear-setings" class="button-full" (click)="cleanAll()">
                <span>Clear settings</span>
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/f_r.png" alt="">
            </button>
        </li>-->
    </ul>

</div>
<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Main">
        <header>
            <div class="Logo" [style.background-color]="App.LogoColor">
                <img [src]="LogoImg" />
            </div>
        </header>
        <div class="ForgatPassword">
            <div class="BtnBack" (click)="Back()">
                {{ Resource.Get("Back") }}
                <div class="Icon"></div>
            </div>
            <div class="Container">

                <h1>{{ Resource.Get("ForgatPassword") }}</h1>

                <input type="text" name="Email" [placeholder]="Resource.Get('Email')" [(ngModel)]="Email" />

                <div class="BtnOK" (click)="ForgatPassword()">
                    {{ Resource.Get("Continue") }}
                </div>
            </div>
        </div>
    </div>
</div>
<style>
    body.blackcursor, body.blackcursor * {/*cursor: url(/assets/Images/Accessibility/cursor.png),default !important;*/}
body.whitecursor, body.whitecursor * {/*cursor: url(/assets/Images/Accessibility/cursor2.png),default !important;*/}
</style>