import { Component, ElementRef, ViewChild, HostListener, QueryList, ViewChildren, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeStyle, SafeResourceUrl } from "@angular/platform-browser";
import { CircleButtonControlComponent } from "../CircleButtonControl/CircleButtonControl.component";
import { Model } from "../../Shared/Services/Model.service";
import { LoginService } from "../../Shared/Services/Login.service";
import { AppService } from "../../Shared/Services/App.service";
import { AppModes } from "../../Shared/Interfaces/Nugets.interfaces";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { DailyStudyService } from "../../Shared/Services/DailyStudy.service";
import { BonusPopupComponent } from "../BonusPopup/BonusPopup.component";

import { Subscription } from "rxjs/Subscription";
import { Observer } from "rxjs/Observer";

const MinPoints: number = 10;
const MaxPoints: number = 100;
const PointAdditon: number = 80;
const MaxiumCollision: number = 3999999;

const Colors: string[] = [
    'rgba(151, 99, 226, 0.8)',
    'rgba(132, 252, 99, 0.8)',
    'rgba(252, 234, 99, 0.8)',
    'rgba(99, 252, 229, 0.8)',
    'rgba(253, 133, 174, 0.8)',
    'rgba(252, 154, 99, 0.8)',
    'rgba(230, 25, 60, 0.8)'
];

const Angles: number[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139,
    140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179,
    180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219,
    220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259,
    260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299,
    300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339,
    340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360
];

@Component({
    templateUrl: "./Home.component.html",
    styleUrls: ["./Styles/Home.component.rtl.scss", "./Styles/Home.component.ltr.scss"]
})
export class HomeComponent {
    @ViewChild("Container")
    public Container: ElementRef;

    @ViewChildren("CircleButton")
    public CircleButton: QueryList<CircleButtonControlComponent>;

    @ViewChild("BonusPopup")
    public BonusPopup: BonusPopupComponent;

    public ParentWidth: number = 0;
    public ChildParentWidth: number = 0;
    public DailyStudy: any;
    public NextChapter: any = null;
    public BackChapter: any = null;
    public AnimEnabled: boolean = false;

    private TM;
    private TimerTM;
    private DailyStudyId: number;
    private OldWidth: number = 0;
    private TempPoint: number = 0;
    private Subscribe: Subscription;
    private SelectedIndex: number;

    public DateEng: string;
    public DateHeb: string;

    public TotalPoints: number = 0;
    public TotalBonus: number = 0;

    public Circles: any[] = [];
    public ChildCircles: any[] = [];

    public AnimScore: boolean = false;
    public ShowBonus: boolean = false;
    public ShowTimer: boolean = false;

    public TimerHours: number = 0;
    public TimerMinitues: number = 0;
    public TimerSeconds: number = 0;

    public ProfilePicURL: SafeStyle;
    public LogoImg: SafeResourceUrl;
    public LogoSmallImg: SafeResourceUrl;

    public PageStyle: any;

    constructor(private Model: Model,
        private Router: Router,
        private ActivatedRoute: ActivatedRoute,
        private DomSanitizer: DomSanitizer,
        private DailyStudyService: DailyStudyService,
        public LoginService: LoginService,
        public App: AppService,
        public Resource: ResourcesService,
        @Inject("SystemURL") public SystemURL: string,
        @Inject("MenuSubject") private Menu: Observer<any>) {

        this.Model.Users.GetUserPoints().subscribe(p => {
            this.TotalPoints = p.TotalPoints;
        });
       
        this.LogoImg = this.DomSanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/Logos/${this.App.AppId}.jpg`);
        this.LogoSmallImg = this.DomSanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/Logos/${this.App.AppId}_s.jpg`);
        this.ProfilePicURL = this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/Uploads/UserProfile/${this.LoginService.UserId}.jpg')`);

        if (this.App.IsStyled) {
            this.PageStyle = {
                Header: this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/StylesSets/${this.App.StyleId}/header.png')`),
                Footer: this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/StylesSets/${this.App.StyleId}/footer.png')`),
                Points: this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/StylesSets/${this.App.StyleId}/star.png')`),
                Right: this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/StylesSets/${this.App.StyleId}/arrow-right.png')`),
                Left: this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/StylesSets/${this.App.StyleId}/arrow-lef.png')`),
                Timer: this.DomSanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/StylesSets/${this.App.StyleId}/timer.png')`),
                Lock: this.DomSanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/StylesSets/${this.App.StyleId}/lock.png`),
                Menu: this.DomSanitizer.bypassSecurityTrustResourceUrl(`${this.SystemURL}/StylesSets/${this.App.StyleId}/menu.png`),
                NavTxtColor: this.App.NavTxtColor,
                DateTxtColor: this.App.DateTxtColor,
                Score: {
                    backgroundColor: this.App.ScoreBackground,
                    color: this.App.ScoreTxtColor,
                    textShadow: `4px 2px 2px ${this.App.ScoreTxtShadowColor}`,
                }
            };
        }

        this.DailyStudyService.GetListener().subscribe(p => {
            if (p.Id == 1) {
                this.OnDone(p.Data);
            }
            else if (p.Id == 2 && this.ChildCircles.length == 0) {
                let ReadyTM;

                let Ready = () => {
                    if (this.Circles.length > 1) {
                        var Index = this.Circles.findIndex(c => c.Id == p.Data.DailyStudyItemId);

                        this.LoadChildrens(Index, null);
                    }
                    else if (this.Circles.length == 0) {
                        ReadyTM = setTimeout(Ready, 500);
                    }
                };

                ReadyTM = setTimeout(Ready, 300);
            }
        });
    }

    @HostListener("window:resize")
    private OnResize(): void {
        clearTimeout(this.TM);

        if (this.App.IsStyled) {
            let Width = this.Container.nativeElement.clientWidth;

            let HeaderSize = (Width > 640 ? 640 : Width) * 22.03125 / 100;
            let ContentSize = (Width > 640 ? 640 : Width) * 19.0625 / 100;

            this.PageStyle.HeaderSize = this.DomSanitizer.bypassSecurityTrustStyle(`calc(100% - ${HeaderSize}px)`);
            this.PageStyle.ContentSize = this.DomSanitizer.bypassSecurityTrustStyle(`calc(100% - ${ContentSize}px)`);
        }

        this.TM = setTimeout(() => {
            if ((this.OldWidth < 640 || window.innerWidth < 640) && (this.OldWidth != window.innerWidth)) {
                this.Refresh();
                this.RefreshChildrens();
            }

            this.OldWidth = window.innerWidth;
        }, 100);
    }

    private ngAfterViewInit(): void {
        this.Subscribe = this.ActivatedRoute.params.subscribe(params => {
            this.DailyStudyId = params["id"];

            this.Model.DailyStudy.GetDailyStudy(params["id"]).subscribe(p => {
                if (p.Status) {
                    this.ShowBonus = false;
                    this.DailyStudy = p.Study;
                    this.DateEng = p.DateHeb;
                    this.DateHeb = p.Date;

                    this.NextChapter = p.Next;
                    this.BackChapter = p.Back;

                    this.ChildCircles = [];
                    this.Circles = p.Study.Items;

                    this.TotalBonus = this.DailyStudy.HasBonus ? this.DailyStudy.BonusAmount : 0;

                    if (this.Circles.length == 1) {
                        this.LoadChildrens(0, null);
                    }
                    else {
                        setTimeout(() => {
                            this.Refresh();
                        }, 1);
                    }
                }
                else {
                    this.Model.DailyStudy.GetFirstDailyStudy(true).subscribe(p => {
                        this.Router.navigate(["/", this.App.AppId, "Main", "Home", p]);
                    });
                }
            });
        });

        setTimeout(this.OnResize.bind(this), 1);
    }

    private ngOnDestroy(): void {
        this.Subscribe.unsubscribe();
        this.ShowTimer = false;
        clearTimeout(this.TimerTM);
    }

    private Refresh(): void {
        this.AnimEnabled = false;

        this.OldWidth = window.innerWidth;
        this.ParentWidth = this.Container.nativeElement.clientWidth;

        let ReduceSize = 1;
        let PointSize = 0;
        let TotalCirclesPoints = 0;
        let BiggestCircle = 0;
        let Width = (this.Container.nativeElement.clientWidth * 30) / 100;
        let Height = this.Container.nativeElement.clientHeight;
        let TotalScreenPoints = ((this.Container.nativeElement.clientWidth * 60) / 100) * Height;

        for (let i = 0; i < this.Circles.length; i++) {
            if (!this.Circles[i].Fake) {
                this.Circles[i].Points += 30;
            }

            if (this.Circles[i].Points > this.Circles[BiggestCircle].Points) {
                BiggestCircle = i;
            }

            TotalCirclesPoints += this.Circles[i].Points;
        }

        PointSize = (TotalScreenPoints / TotalCirclesPoints) * 0.7;
        let Size = Math.sqrt((this.Circles[BiggestCircle].Points * PointSize) / Math.PI) * 1.5;

        if (Size > Width) {
            ReduceSize -= ((100 - (Width / Size) * 100) / 100);
        }

        for (let i = 0; i < this.Circles.length; i++) {
            this.Circles[i].Size = (Math.sqrt((this.Circles[i].Points * PointSize) / Math.PI) * 1.5) * ReduceSize;
            this.Circles[i].PosX = (this.ParentWidth / 2) - (this.Circles[i].Size / 2);
            this.Circles[i].PosY = (Height / 2) - (this.Circles[i].Size / 2);
        }

        setTimeout(() => {
            this.AnimEnabled = true;

            let Factor = 1;
            let CollisionCount = MaxiumCollision;

            for (let i = 0; i < this.Circles.length; i++) {
                this.Circles[i].PosX = null;
                this.Circles[i].PosY = null;
            }

            for (let i = 0; i < this.Circles.length; i++) {
                this.Circles[i].PosX = Math.floor(Math.random() * (Width - this.Circles[i].Size)) + 1;
                this.Circles[i].PosY = Math.floor(Math.random() * (Height - this.Circles[i].Size)) + 1;

                if (i >= Math.floor(this.Circles.length / 2)) {
                    this.Circles[i].PosX += (this.ParentWidth - Width);
                }

                let Collision = false;
                let CollisionWith = -1;

                let R0 = this.Circles[i].Size / 2 * Factor;
                let X0 = this.Circles[i].PosX;
                let Y0 = this.Circles[i].PosY;

                for (let j = 0; j < this.Circles.length; j++) {
                    if (i != j && this.Circles[j].PosX != null && this.Circles[j].PosY != null) {
                        let R1 = this.Circles[j].Size / 2 * Factor;
                        let X1 = this.Circles[j].PosX;
                        let Y1 = this.Circles[j].PosY;

                        var dx = (X0 + R0) - (X1 + R1);
                        var dy = (Y0 + R0) - (Y1 + R1);
                        var distance = Math.sqrt(dx * dx + dy * dy);

                        if (distance < (R0 + R1)) {
                            Collision = true;
                            CollisionWith = j;
                            break;
                        }
                    }
                }

                if (Collision) {
                    if (CollisionCount % 1000 > 0) {
                        i--;
                        CollisionCount--;
                        continue;
                    }
                    else if (CollisionCount > 0) {
                        if (CollisionWith > i) {
                            this.Circles[CollisionWith].PosX = null;
                            this.Circles[CollisionWith].PosY = null;

                            i--;
                        }
                        else {
                            this.Circles[i].PosX = null;
                            this.Circles[i].PosY = null;

                            i = CollisionWith - 1;
                        }

                        CollisionCount--;

                        continue;
                    }

                    CollisionCount = MaxiumCollision;
                }
            }
        }, 100);
    }

    private RefreshChildrens(): void {
        if (this.Circles.filter(p => !p.Fake).length > 1) {
            this.AnimEnabled = false;
            this.OldWidth = window.innerWidth;
            this.ChildParentWidth = this.Container.nativeElement.clientWidth;

            let IsMultipleChildrens = this.Circles.length > 1;
            let ChildsPercent = 0;
            let ParentsPercent = 0;


            if (IsMultipleChildrens) {
                ChildsPercent = 40;
                ParentsPercent = 30;
            }
            else {
                ChildsPercent = 100;
                ParentsPercent = 0;
            }

            let ReduceSize = 1;
            let PointSize = 0;
            let BiggestCircle = 0;
            let TotalCirclesPoints = 0;
            let ScreenWidth = this.Container.nativeElement.clientWidth;
            let Width = (ScreenWidth * ChildsPercent) / 100;
            let Height = this.Container.nativeElement.clientHeight;
            let TotalScreenPoints = Width * Height;

            for (let i = 0; i < this.ChildCircles.length; i++) {
                if (this.ChildCircles[i].Points == 0) {
                    this.ChildCircles[i].Points++;
                }

                if (!this.ChildCircles[i].Fake) {
                    this.ChildCircles[i].Points += 30;
                }

                TotalCirclesPoints += this.ChildCircles[i].Points;

                if (this.ChildCircles[i].Points > this.ChildCircles[BiggestCircle].Points) {
                    BiggestCircle = i;
                }
            }

            PointSize = (TotalScreenPoints / TotalCirclesPoints) * 0.7;

            if (IsMultipleChildrens) {
                let ParentSize = Math.pow((this.Circles[this.SelectedIndex].Size) / 1.5, 2) * Math.PI;

                if (this.ChildCircles[BiggestCircle].Points * PointSize > ParentSize * 0.25) {
                    PointSize = (ParentSize / this.ChildCircles[BiggestCircle].Points) / 2;
                }
            }

            let Size = Math.sqrt((this.ChildCircles[BiggestCircle].Points * PointSize) / Math.PI) * 1.5;

            if (Size > Width * 0.95) {
                ReduceSize -= ((100 - (Width * 0.95 / Size) * 100) / 100);
            }

            for (let i = 0; i < this.ChildCircles.length; i++) {
                this.ChildCircles[i].Size = (Math.sqrt((this.ChildCircles[i].Points * PointSize) / Math.PI) * 1.5) * ReduceSize;

                this.ChildCircles[i].PosX = this.ChildCircles[i].OrgX - (this.ChildCircles[i].Size / 2);
                this.ChildCircles[i].PosY = this.ChildCircles[i].OrgY - (this.ChildCircles[i].Size / 2);
            }

            setTimeout(() => {
                this.AnimEnabled = true;

                let ParentSizeInPx = ((ScreenWidth * ParentsPercent) / 100);
                let ChildsSizeInPx = ((ScreenWidth * ChildsPercent) / 100);
                let Circles = [];

                for (let i = 0; i < this.ChildCircles.length; i++) {
                    this.ChildCircles[i].PosX = null;
                    this.ChildCircles[i].PosY = null;
                }

                if (IsMultipleChildrens) {
                    let IsRight = this.Circles[this.SelectedIndex].PosX + this.Circles[this.SelectedIndex].Size > ParentSizeInPx + ChildsSizeInPx;

                    if (IsRight) {
                        this.ChildCircles[0].PosX = this.Circles[this.SelectedIndex].PosX - this.ChildCircles[0].Size * 0.85;
                    }
                    else {
                        this.ChildCircles[0].PosX = this.Circles[this.SelectedIndex].PosX + this.Circles[this.SelectedIndex].Size - this.ChildCircles[0].Size + this.ChildCircles[0].Size * 0.85;
                    }

                    this.ChildCircles[0].PosY = this.Circles[this.SelectedIndex].PosY + this.Circles[this.SelectedIndex].Size / 2 - this.ChildCircles[0].Size / 2;
                }
                else if (!IsMultipleChildrens) {
                    this.ChildCircles[0].PosY = Math.floor(Math.random() * (Height - this.ChildCircles[0].Size)) + 1;
                    this.ChildCircles[0].PosX = Math.floor(Math.random() * (Width - this.ChildCircles[0].Size)) + 1;
                    this.ChildCircles[0].PosX += ((ScreenWidth * ParentsPercent) / 100);
                }

                for (let i = 0; i < this.ChildCircles.length; i++) {
                    Circles.push(i);
                }

                for (let i = 1; i < this.ChildCircles.length; i++) {
                    let C = Circles.slice();

                    for (let x = 0; x < C.length; x++) {
                        if (this.ChildCircles[C[x]].PosX == null) {
                            C.splice(x, 1);
                            x--;
                        }
                    }

                    while (C.length > 0) {
                        let Index = Math.floor(Math.random() * C.length);
                        let Success = false;
                        let Angels = Angles.slice();

                        if (Index == i) {
                            C.splice(Index, 1);
                            continue;
                        }

                        while (Angels.length > 0) {
                            let AngelsIndex = Math.floor(Math.random() * Angels.length);

                            let R0 = (this.ChildCircles[i].Size / 2);
                            let X0 = this.ChildCircles[C[Index]].PosX + (this.ChildCircles[C[Index]].Size / 2) - (this.ChildCircles[i].Size / 2) + (Math.cos(Angels[AngelsIndex] * (Math.PI / 180)) * ((this.ChildCircles[i].Size / 2) + (this.ChildCircles[C[Index]].Size / 2)) * 0.85);
                            let Y0 = this.ChildCircles[C[Index]].PosY + (this.ChildCircles[C[Index]].Size / 2) - (this.ChildCircles[i].Size / 2) + (Math.sin(Angels[AngelsIndex] * (Math.PI / 180)) * ((this.ChildCircles[i].Size / 2) + (this.ChildCircles[C[Index]].Size / 2)) * 0.85);

                            let Collision = false;
                            let Overflow = 0;

                            if (IsMultipleChildrens) {
                                Overflow = ParentSizeInPx - this.Circles[this.SelectedIndex].Size
                            }
                            else {
                                Overflow = 0;
                            }

                            if (X0 > ParentSizeInPx - Overflow && X0 + (R0 * 2) < ParentSizeInPx + ChildsSizeInPx + Overflow && Y0 > 0 && Y0 + (R0 * 2) < Height) {
                                R0 *= 0.85;

                                for (let ii = 0; ii < this.ChildCircles.length; ii++) {

                                    if (i != ii && ii != C[Index] && this.ChildCircles[ii].PosX != null && this.ChildCircles[ii].PosY != null) {
                                        let R1 = (this.ChildCircles[ii].Size / 2) * 0.85;
                                        let X1 = this.ChildCircles[ii].PosX;
                                        let Y1 = this.ChildCircles[ii].PosY;

                                        let dx = (X0 + R0) - (X1 + R1);
                                        let dy = (Y0 + R0) - (Y1 + R1);
                                        let distance = Math.sqrt(dx * dx + dy * dy);

                                        if (distance < (R0 + R1)) {
                                            Collision = true;
                                            break;
                                        }

                                    }
                                }

                                if (!Collision) {
                                    this.ChildCircles[i].PosX = X0;
                                    this.ChildCircles[i].PosY = Y0;

                                    Success = true;

                                    break;
                                }
                            }

                            Angels.splice(AngelsIndex, 1);
                        }

                        if (!Success) {
                            C.splice(Index, 1);
                        }
                        else {
                            break;
                        }
                    }
                }
            }, 100);
        }
        else {
            this.RefreshChildrensFullScreen();
        }
    }

    private RefreshChildrensFullScreen(): void {
        this.AnimEnabled = false;

        this.OldWidth = window.innerWidth;
        this.ChildParentWidth = this.Container.nativeElement.clientWidth;

        let ReduceSize = 1;
        let PointSize = 0;
        let BiggestCircle = 0;
        let TotalCirclesPoints = 0;
        let Width = this.Container.nativeElement.clientWidth;
        let Height = this.Container.nativeElement.clientHeight;
        let TotalScreenPoints = Width * Height;

        for (let i = 0; i < this.ChildCircles.length; i++) {
            if (this.ChildCircles[i].Points > this.ChildCircles[BiggestCircle].Points) {
                BiggestCircle = i;
            }

            TotalCirclesPoints += (this.ChildCircles[i].Points * 10) + PointAdditon;
        }

        PointSize = (TotalScreenPoints / TotalCirclesPoints) * 0.7;

        for (let i = 0; i < this.ChildCircles.length; i++) {
            this.ChildCircles[i].Size = (Math.sqrt((((this.ChildCircles[i].Points * 10) + PointAdditon) * PointSize) / Math.PI) * 1.5) * ReduceSize;
            this.ChildCircles[i].PosX = (Width / 2) - (this.ChildCircles[i].Size / 2);
            this.ChildCircles[i].PosY = (Height / 2) - (this.ChildCircles[i].Size / 2);
        }

        setTimeout(() => {
            this.AnimEnabled = true;

            let Factor = 1;
            let CollisionCount = MaxiumCollision;

            for (let i = 0; i < this.ChildCircles.length; i++) {
                if (i != BiggestCircle) {
                    this.ChildCircles[i].PosX = null;
                    this.ChildCircles[i].PosY = null;
                }
            }

            for (let i = 0; i < this.ChildCircles.length; i++) {
                if (i != BiggestCircle) {
                    this.ChildCircles[i].PosX = Math.floor(Math.random() * (Width - this.ChildCircles[i].Size)) + 1;
                    this.ChildCircles[i].PosY = Math.floor(Math.random() * (Height - this.ChildCircles[i].Size)) + 1;

                    let Collision = false;
                    let CollisionWith = -1;

                    let R0 = this.ChildCircles[i].Size / 2 * Factor;
                    let X0 = this.ChildCircles[i].PosX;
                    let Y0 = this.ChildCircles[i].PosY;

                    for (let j = 0; j < this.ChildCircles.length; j++) {
                        if (i != j && this.ChildCircles[j].PosX != null && this.ChildCircles[j].PosY != null) {
                            let R1 = this.ChildCircles[j].Size / 2 * Factor;
                            let X1 = this.ChildCircles[j].PosX;
                            let Y1 = this.ChildCircles[j].PosY;

                            var dx = (X0 + R0) - (X1 + R1);
                            var dy = (Y0 + R0) - (Y1 + R1);
                            var distance = Math.sqrt(dx * dx + dy * dy);

                            if (distance < (R0 + R1)) {
                                Collision = true;
                                CollisionWith = j;
                                break;
                            }
                        }
                    }

                    if (Collision) {
                        if (CollisionCount % 1000 > 0) {
                            i--;
                            CollisionCount--;
                            continue;
                        }
                        else if (CollisionCount > 0) {
                            if (CollisionWith > i) {
                                this.ChildCircles[CollisionWith].PosX = null;
                                this.ChildCircles[CollisionWith].PosY = null;

                                i--;
                            }
                            else {
                                this.ChildCircles[i].PosX = null;
                                this.ChildCircles[i].PosY = null;

                                i = CollisionWith - 1;
                            }

                            CollisionCount--;

                            continue;
                        }

                        CollisionCount = MaxiumCollision;
                    }
                }
            }
        }, 100);
    }

    private TimerCounter(): void {
        if (this.TimerSeconds > 0) {
            this.TimerSeconds--;
        }
        else {
            if (this.TimerMinitues > 0) {
                this.TimerMinitues--;
                this.TimerSeconds = 59;
            }
            else {
                if (this.TimerHours > 0) {
                    this.TimerHours--;
                    this.TimerMinitues = 59;
                    this.TimerSeconds = 59;
                }
                else {
                    return;
                }
            }
        }

        this.TimerTM = setTimeout(() => {
            this.TimerCounter();
        }, 1000);
    }

    private Shuffle(Array: any[]): any[] {
        var CurrentIndex = Array.length, temporaryValue, RandomIndex;

        while (CurrentIndex !== 0) {

            RandomIndex = Math.floor(Math.random() * CurrentIndex);
            CurrentIndex -= 1;

            temporaryValue = Array[CurrentIndex];
            Array[CurrentIndex] = Array[RandomIndex];
            Array[RandomIndex] = temporaryValue;
        }

        return Array;
    }

    public GetFontSize(FontSizePercent: number, Size: number): number {
        return (FontSizePercent * Size) / 100;
    }

    public LoadChildrens(Index: any, Button: HTMLDivElement): void {
        this.SelectedIndex = Math.floor(Index);
        let DailyStudyItem = this.Circles[Math.floor(Index)];

        if (!DailyStudyItem.Fake) {
            this.Model.DailyStudy.GetDailyStudyItemChildrens(this.DailyStudyId, DailyStudyItem.Id).subscribe(p => {
                this.ChildCircles = p;

                if (this.ChildCircles.length > 0) {
                    let Fake = Math.floor(Math.random() * 6);

                    if (this.Circles.length == 1) {
                        for (let Circle of this.ChildCircles) {
                            Circle.Color.ChildColor = Colors[Math.floor(Math.random() * Colors.length)];
                        }
                    }

                    let KindId = this.ChildCircles[0].ParentKindId;
                    let ParentWidth = Button ? Button.clientWidth : this.Container.nativeElement.clientWidth;
                    let ParentHeight = Button ? Button.clientHeight : this.Container.nativeElement.clientHeight;
                    let ParentPosX = Button ? DailyStudyItem.PosX : 0;
                    let ParentPosY = Button ? DailyStudyItem.PosY : 0;

                    for (let i = 0; i < this.ChildCircles.length; i++) {
                        this.ChildCircles[i].OrgY = ParentPosY + (ParentHeight / 2);
                        this.ChildCircles[i].OrgX = ParentPosX + (ParentWidth / 2);
                    }
                }

                setTimeout(() => {
                    this.RefreshChildrens();
                }, 1);
            });
        }
        else {
            this.Circles[Math.floor(Index)].Explode = true;
        }
    }

    public OnChildCircleButtonClick(Index: any): void {
        let DailyStudyItemChildId = this.ChildCircles[Index].Id;

        if (DailyStudyItemChildId != -1) {
            this.ShowTimer = false;
            clearTimeout(this.TimerTM);

            this.Router.navigateByUrl("/" + this.App.AppId + "/Main/Home/" + this.DailyStudyId + "/DailyStudy/" + DailyStudyItemChildId);
        }
        else {
            this.ChildCircles[Index].Explode = true;
        }
    }

    public OnDone(event): void {
        var DailyStudyItemChild = this.ChildCircles.find(p => p.Id == event.DailyStudyItemChildId);
        var DailyStudyItem = this.Circles.find(p => p.Id == event.DailyStudyItemId);

        if (DailyStudyItemChild && !DailyStudyItemChild.Done) {
            DailyStudyItemChild.Done = true;
            DailyStudyItemChild.DoneAnim = true;

            if (DailyStudyItem && !DailyStudyItem.Done && event.DailyStudyItemDone) {
                DailyStudyItem.Done = true;

                if (event.DailyStudyDone) {
                    if (this.App.Mode == AppModes.PersonalProgress && this.BackChapter) {
                        this.BackChapter.Enabled = true;
                    }

                    this.ShowBonus = true;
                    this.BonusPopup.Open(event);
                }
            }

            this.Model.Users.GetUserPoints().subscribe(p => {
                this.TempPoint = this.TotalPoints;

                if (p.TotalPoints - this.TotalPoints > 0) {
                    this.AnimScore = true;
                }

                this.AddPointsAnim(p.TotalPoints - this.TotalPoints, p.TotalPoints);
            });
        }
    }

    public OnBonusClose(): void {
        this.ShowBonus = false;
        this.AnimScore = false;
    }

    public AddPointsAnim(MaxNum: number, Max: number): void {
        let Speed = 100;

        this.TempPoint += (MaxNum / Speed);
        this.TotalPoints = Math.floor(this.TempPoint);

        if (this.TempPoint > Max) {
            this.TempPoint = Max;
            this.TotalPoints = Max;
        }

        if (this.TempPoint < Max) {
            setTimeout(() => {
                this.AddPointsAnim(MaxNum, Max);
            }, 1);
        }
        else {
            this.AnimScore = false;
        }
    }

    public ToogleTimer(Id: number) {
        if (!this.ShowTimer) {
            if (Id && this.App.Mode == AppModes.ByDate) {
                this.Model.DailyStudy.GetDailyStudyRemainingTime(Id).subscribe(p => {
                    this.ShowTimer = true;

                    clearTimeout(this.TimerTM);

                    this.TimerHours = p.Hours;
                    this.TimerMinitues = p.Minitues;
                    this.TimerSeconds = p.Seconds;

                    this.TimerCounter();
                });
            }
        }
        else {
            clearTimeout(this.TimerTM);
            this.ShowTimer = false;
        }
    }

    public ToggleMenu(): void {
        this.Menu.next(0);
    }

    public GoToChapter(Id: number): void {
        if (Id) {
            this.ShowTimer = false;
            clearTimeout(this.TimerTM);
            this.Router.navigate(["/", this.App.AppId, "Main", "Home", Id]);
        }
    }

    public GoTodayDailyStudy(): void {
        this.Model.DailyStudy.GetTodayDailyStudy(true).subscribe(p => {
            this.Router.navigate(["/", this.App.AppId, "Main", "Home", p]);
        });
    }
}
