import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "NgForFilter",
    pure: false
})
export class NgForFilterPipe implements PipeTransform {
    public transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return items;
        }

        return items.filter(item => item.Title.indexOf(filter) != -1)
    }
}