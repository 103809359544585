import { Injectable, Inject } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppService } from "../Services/App.service";
import { Model } from "../Services/Model.service";
import { Observable } from "rxjs/Observable";
import { NugetFont } from "../Interfaces/Nugets.interfaces";

@Injectable()
export class AppResolver implements Resolve<Observable<number>> {

  constructor(public App: AppService, private Model: Model, private Title: Title, private meta: Meta, @Inject("SystemURL") private SystemURL: string) { }

  public resolve(Route: ActivatedRouteSnapshot, State: RouterStateSnapshot): Observable<number> {
    return Observable.create(o => {
      if (!this.App.AppId) {
        if (Route.params.App && parseInt(Route.params.App)) {
          this.App.AppId = parseInt(Route.params.App);
        }
        else {
          this.App.AppId = 104;
        }

        this.SetFavicon();

        this.Model.Nugets.GetNugetLang().subscribe(p => {
          // if(this.App.AppId == 247)
          // this.App.Lang.Id  = 4 ;
          // else
          this.App.Lang.Id = p.Id;
          this.App.Lang.Direction = p.Rtl ? "RTL" : "LTR";
          this.App.Mode = p.Mode;
          this.App.LogoColor = p.LogoColor;
          this.App.SplashColor = p.SplashColor;
          this.App.PlayGroups = p.PlayGroups;
          this.App.AllChapterBtn = p.AllChapterBtn;
          this.App.StyleId = p.StyleSet;
          this.App.ScoreBackground = p.ScoreBackground;
          this.App.ScoreTxtColor = p.ScoreTxtColor;
          this.App.ScoreTxtShadowColor = p.ScoreTxtShadowColor;
          this.App.NavTxtColor = p.NavTxtColor;
          this.App.DateTxtColor = p.DateTxtColor;
          this.App.Url = p.Url;
          this.App.AppName = p.Name;
          this.App.IsStyled = this.App.StyleId > 1;
          this.App.TrackId = p.TrackingId;
          this.App.ShowTitle = p.ShowTitle;
          this.App.ShowDate = p.ShowDate;
          this.App.ShowLogo = p.ShowLogo;
         
          
          this.Title.setTitle(p.Name);
          this.meta.updateTag({name: 'description', content: p.Description});
          this.SetFonts(p.Fonts);
          this.SetAnalytics(p.TrackingId);

          o.next(this.App.AppId);
          o.complete();
        });
      }
      else {
        o.next(this.App.AppId);
        o.complete();
      }
    });
  }

  private SetFavicon(): void {
    let Link: HTMLLinkElement = document.createElement("link");

    Link.type = "image/x-icon";
    Link.rel = "icon";
    Link.href = `${this.SystemURL}/favicons/${this.App.AppId}.ico?dt=${new Date().toUTCString()}`;

    document.head.appendChild(Link);
  };

  private SetFonts(Fonts: NugetFont[]): void {
    let Style: CSSStyleSheet = <CSSStyleSheet>window.document.styleSheets[0];

    if (Fonts.length > 0) {
      for (let Font of Fonts) {
        Style.insertRule(`@font-face {
                        font-family: "${Font.Name}";
                        src: url("${this.SystemURL}/AppFonts/${Font.Id}.ttf") format("truetype");
                        font-weight: bold;
                        font-style: normal;
                    }`, Style.cssRules.length);
      }
    }
  };

  private SetAnalytics(TrackId: string): void {
    if (TrackId && TrackId.trim() != "") {
      let Script: HTMLScriptElement = document.createElement('script');
      let Win: any = window;

      Script.async = true;
      Script.src = `https://www.googletagmanager.com/gtag/js?id=${TrackId}`;

      document.head.appendChild(Script);

      Win.dataLayer = Win.dataLayer || [];
      Win.gtag = function gtag() {
        Win.dataLayer.push(arguments);
      };

      Win.gtag('js', new Date());
      Win.gtag('config', TrackId);
    }
  };
}
