<div class="accessibility-close" id="accessibility-close" (click)="Close()">

</div>
<div class="accessibility-toggle" id="accessibility"  (click)="Open()">
    <img src="/assets/Images/Accessibility/disabled.png" alt="">
</div>
<div class="accessibility-tool"  id="stickySidebar">

    <h2>נגישות הגדרות</h2>
    <ul class="accessibility-tool-list">
       <!-- <li class="accessibility-tool-item first">
           <div class="accessibility-tool-top">
               <img src="/assets/Images/Accessibility/disabled.png" alt="">
               הצהרת נגישות
           </div>
       </li> -->
        <!-- <li class="accessibility-tool-item">
            <button id="screen-render" class="button-full" (click)="screenReader()">
            </button>
            <div class="accessibility-tool-info">
                <span>Screen reader</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/volume.png" alt=""></div>
            </div>
        </li> -->
        <li class="accessibility-tool-item">
            <button id="greyScale" class="button-full" (click)="greyScale()">
            </button>
            <div class="accessibility-tool-info">
                <span>Black / white screen</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/monitor-levels-range.png" alt=""></div>
            </div>
        </li>
        <!-- <li class="accessibility-tool-item">
            <button id="keyboard-navigation" class="button-full" (click)="keyboardNavigation()">
            </button>
            <div class="accessibility-tool-info">
                <span>Keyboard navigation</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/keyboard.png" alt=""></div>
            </div>
        </li> -->
        <!-- <li class="accessibility-tool-item">
            <button id="coloring-links" class="button-full" (click)="marklinks()">
            </button>
            <div class="accessibility-tool-info">
                <span>Coloring links</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/url.png" alt=""></div>
            </div>
        </li> -->
        <!-- <li class="accessibility-tool-item">
            <button id="highlighting-links" class="button-full" (click)="underline()">
            </button>
            <div class="accessibility-tool-info">
                <span>Marking links</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/underline.png" alt=""></div>
            </div>
        </li> -->
        <li class="accessibility-tool-item" id="dark-contrastd">
            <button id="dark-contrast" class="button-half" (click)="darkContrast()" >
            </button>
            <!-- (click)="darkContrast()" -->
            <div class="fonts-controls" style="background-color: rgba(0,0,0,dark) !important;color: white !important;">
                <button id="dark-con-in" (click)="changeDark('+')">+</button>
                <button id="dark-con-de" (click)="changeDark('-')">-</button>
            </div>
            <div class="accessibility-tool-info">
                <span>Level of contrast</span>
                <!-- <mat-slider min="0" max="10" step="0.5" value="1.5"></mat-slider> -->
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/brightness.png" alt=""></div>
            </div>
        </li>
       
         <li class="accessibility-tool-item" id="bright-contrastb">
            <button id="bright-contrast" class="button-half" (click)="brightContrast()">
            </button>
            <!-- (click)="brightContrast()" -->
            <!-- <div class="fonts-controls" >
                <button id="bright-con-in" (click)="changeBright('+')">+</button>
                <button id="bright-con-de" (click)="changeBright('-')">-</button>
            </div> -->
            <div class="accessibility-tool-info">
                <span>Bright contrast</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/brightness.png" alt=""></div>
            </div>
        </li>  
        <li class="accessibility-tool-item" id="dark-contrastf">
            <button id="btn-fonts" class="button-half" (click)="addFont()">
            </button>
            <div class="fonts-controls">
                <button id="enlarge-font" (click)="changeFont('+')">+</button>
                <button id="small-font" (click)="changeFont('-')">-</button>
            </div>
            <div class="accessibility-tool-info">
                <span>Font size</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/font-change.png" alt=""></div>
            </div>
        </li>  
        
         <li class="accessibility-tool-item">
            <button id="readable-font" class="button-full" (click)="readableFonts()">
            </button>
            <div class="accessibility-tool-info">
                <span>Readable font</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/readable.png" alt=""></div>
            </div>
        </li>
        <!-- <li class="accessibility-tool-item">
            <button id="restoration-souls" class="button-full" (click)="animation()">
            </button>
            <div class="accessibility-tool-info">
                <span>Animation</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/360-video.png" alt=""></div>
            </div>
        </li> -->
        <li class="accessibility-tool-item hide-onmobile">
            <button id="cu-black" class="button-full" (click)="blackCursor()">
            </button>
            <div class="accessibility-tool-info">
                <span>Black Marker</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/cursor-black.png" alt=""></div>
            </div>
        </li>
        <li class="accessibility-tool-item hide-onmobile">
            <button id="cu-white" class="button-full" (click)="whiteCursor()">
            </button>
            <div class="accessibility-tool-info">
                <span>White Marker</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/cursor-white.png" alt=""></div>
            </div>
        </li>   
        <li class="accessibility-tool-item">
            <button id="align-left" class="button-full" (click)="textLeftAlign()">
            </button>
            <div class="accessibility-tool-info">
                <span>Text align left</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/text-align-left.png" alt=""></div>
            </div>
        </li> 
        <li class="accessibility-tool-item">
            <button id="align-center" class="button-full" (click)="textCenterAlign()">
            </button>
            <div class="accessibility-tool-info">
                <span>Text align center</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/text-align-center.png" alt=""></div>
            </div>
        </li>
        <li class="accessibility-tool-item">
            <button id="align-right" class="button-full" (click)="textRightAlign()">
            </button>
            <div class="accessibility-tool-info">
                <span>Text align right</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/text-align-right.png" alt=""></div>
            </div>
        </li>   
        <!--<li class="accessibility-tool-item">
            <button id="li-height" class="button-full">
            </button>
            <div class="accessibility-tool-info">
                <span>Line height</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/line-height.png" alt=""></div>
            </div>
        </li> 
        <li class="accessibility-tool-item">
            <button id="le-space" class="button-full">
            </button>
            <div class="accessibility-tool-info">
                <span>Letter spacing</span>
                <div class="accessibility-icon"><img src="/assets/Images/Accessibility/letter-spacing.png" alt=""></div>
            </div>
        </li>-->          
        <!--<li class="accessibility-tool-item">
            <button id="enlarge-font" class="button-half" (click)="zoomdata()">
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/zoom.png" alt="" style="width:20px;height:20px;">
                <span>Enlarge screen</span>
            </button>
            <button id="reset-font-icon" class="button-half" (click)="clearFont()">
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/f_r.png" alt="">
                <span>Reset fonts and icons</span>
            </button>
        </li>
        
        <li class="accessibility-tool-item">
            <button id="restoration-souls" class="button-full">
                <i class="custom-color" style="background: #FFFFFF !important" data-color="#000000" data-backgroud="#FFFFFF" (click)="whiteBackground()"></i>
                <i class="custom-color" style="background: #F3FF3B !important" data-color="#FFFFFF" data-backgroud="#F3FF3B" (click)="yellowBackground()"></i>
                <i class="custom-color" style="background: #FF2644 !important" data-color="#FCFDFF" data-backgroud="#FF2644" (click)="redBackground()"></i>
                <span>color change</span>
            </button>
        </li>
        <li class="accessibility-tool-item">
            <button id="clean-color" class="button-full" (click)="cleanColor()">
                <span>Clean color</span>
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/f_r.png" alt="">
            </button>
        </li>
        <li class="accessibility-tool-item">
            <button id="clear-setings" class="button-full" (click)="cleanAll()">
                <span>Clear settings</span>
                <img src="https://coreandcode.co.il/web/babyclick/wp-content/plugins/acc2/public/images/site/f_r.png" alt="">
            </button>
        </li>-->
    </ul>

</div>
<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Main">
        <header>
            <div class="Logo" [style.background-color]="App.LogoColor">
                <img [src]="LogoImg" />
            </div>
        </header>
        <div class="Login">
            <form #form="ngForm" (ngSubmit)="Register(form)" autocomplete="off" class="Container" novalidate>
                <h1>{{ Resource.Get("Registeration") }}</h1>
                <div class="Txt">{{ Resource.Get("Registred") }} <a [routerLink]="['/', App.AppId, 'Login']">{{
                        Resource.Get("LogIn") }}</a></div>
                <input type="text" name="FirstName" [placeholder]="Resource.Get('FirstName')" #FirstName="ngModel"
                    [class.Error]="FirstName.invalid && Submitted || Errors['Data.FirstName']?.length > 0"
                    [(ngModel)]="User.FirstName" required minlength="2" />
                <input type="text" name="LastName" [placeholder]="Resource.Get('LastName')" [(ngModel)]="User.LastName"
                    #LastName="ngModel"
                    [class.Error]="LastName.invalid && Submitted || Errors['Data.LastName']?.length > 0" required
                    minlength="2" />
                <input type="email" name="Email" [placeholder]="Resource.Get('Email')" [(ngModel)]="User.Email"
                    #Email="ngModel" [class.Error]="Email.invalid && Submitted|| Errors['Data.Email']?.length > 0"
                    required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
                <input type="password" name="Password" [placeholder]="Resource.Get('Password')"
                    [(ngModel)]="User.Password" #Password="ngModel"
                    [class.Error]="Password.invalid && Submitted || Errors['Data.Password']?.length > 0" required
                    minlength="4" autocomplete="new-password" />
                <input *ngIf="App.AppId != 83" type="text" name="Mobile" [placeholder]="Resource.Get('Mobile')"
                    [(ngModel)]="User.Mobile" #Mobile="ngModel"
                    [class.Error]="Mobile.invalid && Submitted || Errors['Data.Mobile']?.length > 0"
                    pattern="^0\d([\d]{0,1})([-]{0,1})\d{7}$" autocomplete="off" />
                <AutoCompleteControl [SearchMethod]="SearchSchools"  (change)="ShoolSelected($event)"
                    *ngIf="!App.PlayGroups && App.Lang.Id!=4"></AutoCompleteControl>
                <select *ngIf="App.EnableClass == true && App.AppId != 163" name="Classroom" class="custom-select"
                    [(ngModel)]="User.Classroom" #Classroom="ngModel">
                    <option value="">
                        כיתה
                    </option>
                    <option value="א">
                        א
                    </option>
                    <option value="ב">
                        ב
                    </option>
                    <option value="ג">
                        ג
                    </option>
                    <option value="ד">
                        ד
                    </option>
                    <option value="ה">
                        ה
                    </option>
                    <option value="ו">
                        ו
                    </option>
                    <option value="ז">
                        ז
                    </option>
                    <option value="ח">
                        ח
                    </option>
                    <option value="ט">
                        ט
                    </option>
                    <option value="י">
                        י
                    </option>
                    <option value="יא">
                        יא
                    </option>
                    <option value="יב">
                        יב
                    </option>
                </select>
                <select *ngIf="App.EnableClass == true && App.AppId != 163" name="Classnumber" class="custom-select"
                    [(ngModel)]="User.Classnumber" #Classnumber="ngModel">
                    <option value="">מספר כיתה</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="1">11</option>
                    <option value="2">12</option>
                    <option value="3">13</option>
                    <option value="4">14</option>
                    <option value="5">15</option>
                    <option value="6">16</option>
                    <option value="7">17</option>
                    <option value="8">18</option>
                    <option value="9">19</option>
                    <option value="10">20</option>
                    <option value="1">21</option>
                    <option value="2">22</option>
                    <option value="3">23</option>
                    <option value="4">24</option>
                    <option value="5">25</option>
                </select>
                <select *ngIf="App.EnableClass == true && App.AppId == 163" name="Classnumber" class="custom-select"
                    [(ngModel)]="User.Classnumber" #Classnumber="ngModel">
                    <option value="">Clase</option>
                    <option value="5EP CAJOL">5EP CAJOL</option>
                    <option value="5EP LABAN">5EP LABAN</option>
                    <option value="6EP">6EP</option>
                    <option value="1ESO">1ESO</option>
                    <option value="2ESO">2ESO</option>
                    <option value="3ESO">3ESO</option>
                    <option value="4ESO">4ESO</option>
                </select>
                <!-- *ngIf="App.EnableClass == true && App.AppId != 163" -->
                <select name="Roles" class="custom-select" [(ngModel)]="User.Roles" #Classroom="ngModel">
                    <option value="תלמיד">
                        תלמיד
                    </option>
                    <option value="מורה">
                        מורה
                    </option>
                </select>
                <input #File type="file" name="ProfilePic" (change)="onFileChange($event)" accept="image/*"
                    capture="capture" />
                <div class="BtnUpload" (click)="UploadFile()" *ngIf="!UserImg"></div>
                <div class="Preview" (click)="UploadFile()" [style.background-image]="'url(' + UserImg + ')'"
                    *ngIf="UserImg != ''" [ngClass]="Rotate"></div>
                <ul *ngIf="ErrorsList?.length > 0">
                    <li *ngFor="let Err of ErrorsList">
                        {{ Err }}
                    </li>
                </ul>

                <input type="submit" class="BtnLogin" [value]="Resource.Get('Register')" *ngIf="!Working" />
                <div class="Loader">
                    <img src="assets/Images/loader.gif" [hidden]="!Working" />
                </div>
            </form>
        </div>
    </div>
</div>
<style>
    body.blackcursor, body.blackcursor * {/*cursor: url(/assets/Images/Accessibility/cursor.png),default !important;*/}
body.whitecursor, body.whitecursor * {/*cursor: url(/assets/Images/Accessibility/cursor2.png),default !important;*/}
</style>