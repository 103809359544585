import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Model } from "../../Shared/Services/Model.service";
import { LoginService } from "../../Shared/Services/Login.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";

@Component({
    templateUrl: "./Points.component.html",
    styleUrls: ["./Styles/Points.component.rtl.scss", "./Styles/Points.component.ltr.scss"]
})
export class PointsComponent {
    public DailyStudy: Array<any>;
    public TotalDailyStudyAvailable: number = 0;
    public TotalPoints: number = 0;
    public TotalGroupPoints: number = 0;
    public TotalLearned: number = 0;
    public UserName: string;
    public GroupName: string;
    public ProfilePicURL: SafeStyle;

    constructor(private Model: Model, private LoginService: LoginService, private Router: Router, public App: AppService, public Resource: ResourcesService, private Location: Location, private Sanitizer: DomSanitizer, @Inject("SystemURL") public SystemURL: string) { }

    private ngOnInit(): void {
        this.Model.Users.GetUserPointsReport().subscribe(p => {
            this.DailyStudy = p;
            this.TotalLearned = 0;

            for (let item of this.DailyStudy) {
                this.TotalLearned += item.TotalLearned;
            }
        });

        this.Model.DailyStudy.GetTotalDailyStudyAvailable().subscribe(p => {
            this.TotalDailyStudyAvailable = p;
        });

        this.Model.Users.GetUserPoints().subscribe(p => {
            this.TotalPoints = p.TotalPoints;
        });

        if (this.App.PlayGroups) {
            this.Model.PlayGroups.GetCurrentPlayGroup().subscribe(p => {
                this.GroupName = p.Name;
                this.TotalGroupPoints = p.Points;
            });
        }

        this.UserName = this.LoginService.UserName;
        this.ProfilePicURL = this.Sanitizer.bypassSecurityTrustStyle(`url('${this.SystemURL}/Uploads/UserProfile/${this.LoginService.UserId}.jpg'),url('/assets/Images/profile-man.png')`);
    }

    public Close(): void {
        this.Location.back();
    }

    public Logout(): void {
        this.LoginService.Logout();
    }
}