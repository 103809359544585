<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="AutoComplete">
        <div class="Icon"></div>
        <input #TextBox type="text" [(ngModel)]="Text" [placeholder]="Resource.Get('School')" (focus)="OnFocus()" (focusout)="OnFocusOut()" (input)="Search()" [class.Error]="Error" />
        <div class="ResultList" [hidden]="!Show || Items?.length < 1" [class.Error]="Error" [style.height]="(Items?.length * 48 > 320 ? 320 : Items?.length * 48) + 'px'" (touchmove)="OnTouchMove()" (touchend)="OnTouchEnd()">
            <div class="Content">
                <div class="Item" *ngFor="let Item of Items" (mousedown)="Select($event, TextBox, Item.Id)" (touchend)="Select($event, TextBox, Item.Id)">
                    {{ Item.Title || Item.Name }}
                </div>
                <div class="Item"  (mousedown)="Select($event, TextBox, '9999')" (touchend)="Select($event, TextBox, '9999')" *ngIf="App.Lang.Id==3">
                    Other
                </div>
            </div>
        </div>
    </div>
</div>