<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Page">
        <div class="BtnClose" (click)="Close()">X</div>
        <div class="Container">
            <div class="TopSection">
                <h1>{{ Page?.Title }}</h1>
            </div>
            <div class="Content">
                <div class="Text" [innerHTML]="Page?.Content"></div>
            </div>
        </div>
    </div>
</div>