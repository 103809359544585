import { Component, Input, ViewChild, ElementRef, SimpleChanges, Inject } from "@angular/core";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ResourcesService } from "../../Shared/Services/Resources.service";

@Component({
    selector: "CircleButton",
    templateUrl: "./CircleButtonControl.component.html",
    styleUrls: ["./CircleButtonControl.component.css"]
})
export class CircleButtonControlComponent {
    @ViewChild("Button")
    public Element: ElementRef;

    public IconSize: SafeStyle;
    public BackgroundColor: string;

    @Input("Color")
    public Color: any;

    @Input("Size")
    public Size: number;

    @Input("PositionX")
    public X: number;

    @Input("PositionY")
    public Y: number;

    @Input("ParentW")
    public W: number;

    @Input("ParentH")
    public H: number;

    @Input("Done")
    public Done: boolean;

    @Input("AnimEnabled")
    public AnimEnabled: boolean;

    @Input("IsStart")
    public Start: boolean;

    @Input("KindId")
    public KindId: number;
   
    constructor(private sanitizer: DomSanitizer,  @Inject("SystemURL") public SystemURL: string,private Resource: ResourcesService) { }

    public GetPosition() {
        return { Top: this.Element.nativeElement.getBoundingClientRect().top + this.Element.nativeElement.clientHeight, OffsetTop: this.Element.nativeElement.offsetTop + this.Element.nativeElement.clientHeight };
    }

    public ngAfterContentInit() {
        let Height = 33 + (((65.84 * ((this.Size - (this.Done ? 0 : 8)) / 2)) / 100) * 1.375);
        this.IconSize = this.sanitizer.bypassSecurityTrustStyle("calc(-" + Height + "px + 20%)");
    }

    public ngOnChanges(Changes: SimpleChanges) {
        if ((Changes["Color"] && Changes["Color"].currentValue != Changes["Color"].previousValue)) {
            this.BackgroundColor = `linear-gradient(to bottom, ${this.Color.StartColor} 1%, ${this.Color.MidColor} 50%, ${this.Color.EndColor} 100%)`;
        }

        if ((Changes["Size"] && Changes["Size"].currentValue != Changes["Size"].previousValue && !Changes["Size"].isFirstChange()) ||
            (Changes["Done"] && Changes["Done"].currentValue != Changes["Done"].previousValue && !Changes["Done"].isFirstChange())) {
            let Height = 33 + (((65.84 * ((this.Size - (this.Done ? 0 : 8)) / 2)) / 100) * 1.375);

            this.IconSize = this.sanitizer.bypassSecurityTrustStyle("calc(-" + Height + "px + 20%)");
        }
    }
}