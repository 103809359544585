import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MasterComponent } from "./Main/Master/Master.component";
import { HomeComponent } from "./Main/Home/Home.component";
import { LoginComponent } from "./Main/Login/Login.component";
import { SplashScreenComponent } from "./Main/SplashScreen/SplashScreen.component";
import { WelcomeScreenComponent } from "./Main/WelcomeScreen/WelcomeScreen.component";
import { RegisterComponent } from "./Main/Register/Register.component";
import { PointsComponent } from "./Main/Points/Points.component";
import { EpisodesComponent } from "./Main/Episodes/Episodes.component";
import { ForgatPasswordComponent } from "./Main/ForgatPassword/ForgatPassword.component";
import { DailyStudyItemPopupComponent } from "./Main/DailyStudyItemPopup/DailyStudyItemPopup.component";
import { PageComponent } from "./Main/Page/Page.component";
import { PlaceHolderComponent } from "./Main/PlaceHolder/PlaceHolder.component";
import { ChampionsComponent } from "./Main/Champions/Champions.component";
import { AddGroupComponent } from "./Main/AddGroup/AddGroup.component";
import { MyGroupComponent } from "./Main/MyGroup/MyGroup.component";

import { LoginGuard } from "./Shared/Guards/Login.guard";
import { NotFoundGuard } from "./Shared/Guards/NotFound.guard";
import { AppResolver } from "./Shared/Resolvers/App.resolver";
import { IsLoggedInResolver } from "./Shared/Resolvers/IsLoggedIn.resolver";
import { SplashGuard } from "./Shared/Guards/Splash.guard";

const routes: Routes = [
    {
        path: ":App/Main", component: MasterComponent,
        children: [
            {
                path: 'Home/:id', component: HomeComponent, children: [
                    { path: 'DailyStudy/:id', component: DailyStudyItemPopupComponent }
                ]
            },
            { path: 'Points', component: PointsComponent },
            { path: 'Episodes', component: EpisodesComponent },
            { path: 'About', component: PageComponent, data: { Type: 1 } },
            { path: 'Champions', component: ChampionsComponent },
            { path: 'MyGroup', component: MyGroupComponent }
        ],
        canActivate: [LoginGuard],
        resolve: { AppId: AppResolver }
    },
    { path: "", component: SplashScreenComponent, resolve: { AppId: AppResolver }, canActivate: [SplashGuard] },
    { path: ":App/Login", component: LoginComponent, resolve: { AppId: AppResolver } },
    { path: ":App/Register", component: RegisterComponent, resolve: { AppId: AppResolver } },
    { path: ":App/Forgat", component: ForgatPasswordComponent, resolve: { AppId: AppResolver } },
    { path: ":App/Welcome", component: WelcomeScreenComponent, resolve: { AppId: AppResolver, IsLoggedIn: IsLoggedInResolver } },
    { path: ":App/Welcome/:code", component: WelcomeScreenComponent, resolve: { AppId: AppResolver, IsLoggedIn: IsLoggedInResolver } },
    { path: ':App/AddGroup', component: AddGroupComponent, resolve: { AppId: AppResolver } },
    { path: ":App", component: SplashScreenComponent, resolve: { AppId: AppResolver }, canActivate: [NotFoundGuard, SplashGuard] }
];

export const RoutingModule: ModuleWithProviders = RouterModule.forRoot(routes);