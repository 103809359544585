import { Component, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Model } from "../../Shared/Services/Model.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { ScrollService } from "../../Shared/Services/Scroll.service";

@Component({
    templateUrl: "./Episodes.component.html",
    styleUrls: ["./Styles/Episodes.component.rtl.scss", "./Styles/Episodes.component.ltr.scss"]
})
export class EpisodesComponent {
    public Groups: any[];
    public MainGroups: any[];
    public SelectedGroup: number = 0;

    @ViewChildren("Table")
    public Tables: QueryList<ElementRef>;

    public Colors: Array<{ Regular: string, Bold: string }> = [
        { Regular: "#C5DFC4", Bold: "#82c980" },
        { Regular: "#D5CADB", Bold: "#9579a7" },
        { Regular: "#EFD1A4", Bold: "#fab249" },
        { Regular: "#C8E3E6", Bold: "#5bc8d6" },
        { Regular: "#F1E6B0", Bold: "#ffd719" },
        { Regular: "#C8DDDF", Bold: "#5eb0b5" },
        { Regular: "#F1D5CC", Bold: "#ffab91" },
        { Regular: "#BCD6E7", Bold: "#40a0db" }
    ];

    constructor(private Model: Model, private Router: Router, public App: AppService, private Location: Location, public Resource: ResourcesService, private Scroll: ScrollService) { }

    private ngOnInit(): void {
        this.Model.Groups.GetGroupsList().subscribe(p => {
            this.Groups = p;

            setTimeout(this.UpdateGroups.bind(this), 1);
        });

        this.Model.Groups.GetMainGroups().subscribe(p => {
            this.MainGroups = p;
        });
    }

    public Change(): void {
        if (this.SelectedGroup == 0)
            return;

        for (let item of this.Tables.toArray()) {
            if (item.nativeElement.getAttribute("id") == this.SelectedGroup) {
                let Elem: HTMLDivElement = item.nativeElement;

                this.Scroll.Position = Elem.offsetTop;
                window.scrollTo(0, Elem.offsetTop);

                break;
            }
        }
    }

    public GoToDailyStudyItem(DailyStudyId: number, Enabled: boolean): void {
        if (Enabled)
            this.Router.navigate(["/", this.App.AppId, "Main", "Home", DailyStudyId]);
    }

    public Close(): void {
        this.Location.back();       
    }

    private UpdateGroups(): void {
        for (let Group of this.Groups) {
            for (let SubGroup of Group.Childrens) {
                SubGroup.Enabled = SubGroup.Childrens.some(p => p.Enabled);
            }
        }
    }
}