import { Injectable } from "@angular/core";
import { Model } from "./Model.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class LoginService {
    private _UserId: number = -1;
    private _UserName: string = "";
    private _Anonymous: boolean = false;
    private _AddNewGroup: boolean;
    private RequestCode: string;

    public LoginInfo: {
        UserName: string,
        Password: string;
    };

    public get UserId(): number {
        return this._UserId;
    }

    public get UserName(): string {
        return this._UserName;
    }

    public get Anonymous(): boolean {
        return this._Anonymous;
    }

    constructor(private Model: Model, private Router: Router, private App: AppService) { }

    public LoginToNewGroup(): void {
        this._AddNewGroup = true;
        this.RequestCode = null;

        this.Router.navigate([this.App.AppId, "Register"]);
    };

    public LoginToGroup(Code: string): void {
        this._AddNewGroup = false;
        this.RequestCode = Code;

        this.Router.navigate([this.App.AppId, "Register"]);
    };

    public HaveLoginCode(): boolean {
        return this._AddNewGroup || (<any>this.RequestCode);
    };

    public Login(UserName: string, Password: string, School?: number, GroupName: string = null): Observable<any> {
        this.LoginInfo = { UserName: UserName, Password: Password };

        return Observable.create(o => {
            this.Model.Users.Login(UserName, Password, School, this.RequestCode || GroupName, GroupName != null).subscribe(p => {
                if (p.Success == LoginResults.Success) {
                    localStorage.setItem("Token" + this.App.AppId, p.Token);
                }

                if ((p.Success == LoginResults.Success || p.Success == LoginResults.UserDoesntHavePlayGroup) && this._AddNewGroup) {
                    /*if user want add new group and login success or login failed because no group*/
                    p.Success = LoginResults.AddNewGroup;

                    this._AddNewGroup = false;
                }
                else if (p.Success == LoginResults.Success) {
                    this.App.Lang = p.User.Lang;
                    this.RequestCode = null;
                    this._AddNewGroup = false;

                    this.LoginInfo = { UserName: '', Password: '' };
                }

                o.next(p);
            });
        });
    }

    public LoginAnonymous(): Observable<any> {
        return Observable.create(o => {
            this.Model.Users.LoginAnonymous().subscribe(p => {
                if (p.Success && localStorage) {
                    localStorage.setItem("Token" + this.App.AppId, p.Token);

                    this.App.Lang = p.User.Lang;
                }

                o.next(p.Success == "true");
            });
        });
    }

    public LoginAnonymous2(AppId: number): Observable<any> {
        return Observable.create(o => {
            this.Model.Users.LoginAnonymous2(AppId).subscribe(p => {
                if (p.Success && localStorage) {
                    localStorage.setItem("Token" + AppId, p.Token);

                    this.App.Lang = p.User.Lang;
                }

                o.next(p.Success == "true");
            });
        });
    }

    public Logout(): void {
        this.Model.Users.Logout().subscribe(p => {
            if (localStorage)
                localStorage.removeItem("Token" + this.App.AppId);

            this.Router.navigateByUrl("/" + this.App.AppId + "/Welcome");
        });
    }

    public IsLoggedIn(AppId: number): Observable<any> {
        return Observable.create(o => {
            this.Model.Users.IsLoggedIn(AppId).subscribe(p => {
                if (p.IsLogin) {
                    this._UserId = p.User.Id;
                    this._UserName = p.User.Name;
                    this._Anonymous = p.User.Anonymous;
                   
                    this.App.Lang = p.User.Lang;
                }

                o.next(p);
            }, p => {
                o.next(p);
            });
        });
    };
}

export enum LoginResults {
    Success = 1,
    AddNewGroup = 2,
    WrongUserNameOrPassword = -1,
    UserDoesntHaveNuget = -2,
    UserDoesntHavePlayGroup = -3,
    UserAlreadyBelongToGroup = -4,
    RequestedGroupNotFound = -5
}