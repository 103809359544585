<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div class="Popup" *ngIf="Show">
        <header>
            <div class="BtnClose" (click)="Close()">X</div>
            <img src="/assets/Images/Hand.png" />
            <h1>{{ Resource.Get("NewGroupCreatedMsg") }}</h1>
        </header>
        <div class="Content">
            <h1>{{ PlayGroup?.Name }}</h1>
            <div class="Text">
                <div class="Label">
                    {{ Resource.Get("GroupCode") }}
                </div>
                <div class="Code">
                    {{ PlayGroup?.Code }}
                </div>
                <div class="Copy" (click)="Copy(TxtCopy)">
                    <div class="Icon" *ngIf="App.Lang.Direction == 'LTR'"></div>
                    {{ Resource.Get("CopyCode") }}
                    <div class="Icon" *ngIf="App.Lang.Direction == 'RTL'"></div>
                </div>
            </div>
            <div class="Bottom">
                {{ Resource.Get("InviteFriendsByShare") }}
                <button class="Share" (click)="Share()">
                    <div class="Icon" *ngIf="App.Lang.Direction == 'LTR'"></div>
                    {{ Resource.Get("ShareGroupLink") }}
                    <div class="Icon" *ngIf="App.Lang.Direction == 'RTL'"></div>
                </button>
                <a (click)="GoToMyGroup()">
                    {{ Resource.Get("ShowGroup") }}
                </a>
            </div>
        </div>
        <SharePopup></SharePopup>
    </div>
    <input class="HiddenText" #TxtCopy type="text" [value]="PlayGroup?.Code" />
</div>

