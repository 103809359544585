import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { Observer } from "rxjs/Observer";

@Injectable()
export class PopupService {
    private Subject: Subject<any>;

    constructor() {
        this.Subject = new Subject<any>();
    }

    public GetListener(): Observable<any> {
        return this.Subject.asObservable();
    }

    public ShowSharePopup(Points: number): void {
        this.Subject.next({ id: 1, data: Points, mode: 2 });
    }

    public ShowShareGroupPopup(GroupCode: string, GroupName: string): void {
        this.Subject.next({ id: 1, data: { GroupCode, GroupName }, mode: 1 });
    }
}