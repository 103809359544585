import { Injectable } from "@angular/core";
import { AppService } from "../Services/App.service";

import { LANG_HEB_NAME, LANG_HEB_RES } from "../Resources/Hebrew";
import { LANG_ENG_NAME, LANG_ENG_RES } from "../Resources/English";
import { LANG_SPA_NAME, LANG_SPA_RES } from "../Resources/Spanish";
import { LANG_POR_NAME, LANG_POR_RES } from "../Resources/Portuguese";
import {  LANG_POR_RES1 } from "../Resources/Spanish";

@Injectable()
export class ResourcesService {
    private Resources = {
        [LANG_HEB_NAME]: LANG_HEB_RES,
        [LANG_ENG_NAME]: LANG_ENG_RES,
        [LANG_SPA_NAME]: LANG_SPA_RES,
        [LANG_SPA_NAME]: LANG_SPA_RES,
        [LANG_POR_NAME]: LANG_POR_RES
        // ,
        // LANG_POR_RES1
    }

    constructor(private App: AppService) { }

    public Get(Name: string): string {
        console.log(this.App.AppId);
        if(this.App.AppId == 247)
        // this.Resources["Lang-"  + 4][Name];
      return  this.Resources["LANG_POR_RES1"][Name];
        else 
        return this.Resources["Lang-"  + this.App.Lang.Id][Name];
    }
}