import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { Http, URLSearchParams, Headers } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";

@Injectable()
export class Groups {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private Router: Router, private App: AppService) { }

    public GetGroupsList(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/Groups/GetGroupsList`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetMainGroups(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/Groups/GetMainGroups`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }
}