<div class="Lang" [ngClass]="[App.Lang.Direction, 'Lang' + App.Lang.Id]">
    <div #Script id="Script"></div>
    <div class="DailyStudyPopup" [ngClass]="'Kind' + DailyStudyItem?.KindId" [class.Styled]="App.IsStyled">
        <div class="Container">
            <div class="TopSection">
                <div class="Top">
                    <div class="BtnBack" (click)="TogglePopup()">
                        <div class="Icon" *ngIf="App.Lang.Direction == 'LTR'"></div>
                        {{ Resource.Get("Back") }}
                        <div class="Icon" *ngIf="App.Lang.Direction == 'RTL'"></div>
                    </div>
                    <div class="Audio" *ngIf="DailyStudyItem?.HasAudio && DailyStudyItem?.KindId == 4">
                        <div class="AudioIcon" [class.Off]="!ShowAudio" (click)="ToggleAudio()"></div>
                        <div class="Player" *ngIf="ShowAudio">
                            <div class="BtnPlay" [class.Off]="AudioFile?.paused" (click)="Play()"></div>
                            <div class="Txt">
                                {{ Resource.Get("OmerFrankel") }}
                            </div>
                            <div class="PlayerIcon"></div>
                        </div>
                    </div>
                </div>
                <h1>{{ DailyStudyItem?.Title != "" ? DailyStudyItem?.Title : "&nbsp;"  }}</h1>
                
                <h2 *ngIf="DailyStudyItem?.KindId != 4">{{ DailyStudyItem?.SubTitle != "" ? DailyStudyItem?.SubTitle : "&nbsp;" }}</h2>
                <h2 *ngIf="DailyStudyItem?.KindId == 4">{{ DailyStudyItem?.Name2 != "" ? DailyStudyItem?.Name2 : "&nbsp;" }} >> {{ DailyStudyItem?.Name != "" ? DailyStudyItem?.Name : "&nbsp;" }}</h2>
            </div>
            <div class="Content">
                <div class="Text" [innerHTML]="DailyStudyItem?.Content"></div>
            </div>

            <!-- Comment Section : Start  -->
            <div *ngIf="DailyStudyItem?.IsCommentShow == 1">
            <div class="jumbotron shadow-comment-box" *ngIf="!isCommentSent" style="clear: both;" >
                <h1 class="header-margin text-center" style="margin-bottom: 10px;">{{DailyStudyItem.CommentHeadline}} </h1>
                <div class="text-center sub-header">לא נפרסם את שמך המלא</div>
                <textarea class="text-area d-rtl shadow-comment-listing-box" placeholder="יש לכתוב את התשובה כאן"  #commentText></textarea> 
                <br/>
                <button type="submit" value="Send" text="שלח" class="btn-block commentbtn" (click)="saveComments(commentText.value)">שליחה</button>
            </div>
            </div>
            <div class="text-center" *ngIf="isCommentSent" style="background-color: #ebebeb;box-shadow: 0px 4px 8px 2px #ccc; clear: both;">
                <h1 class="header-margin text-center">תודה על השיתוף!</h1>
                <div class="Txt text-center">התשובה שלך כבר מופיעה בתגובות</div>
                <img style="margin-top: 15px;" class="confirmation-img" src="assets/Images/confirmation_comment.gif" />
            </div>
            <div *ngIf="DailyStudyItem?.IsCommentList == 1 && DailyStudyItem?.IsCommentShow == 1">
            <div class=" d-rtl" *ngIf="Comments?.length > 0">
                <div class="listing shadow-comment-listing-box">
                    <h1 class="header-margin text-center" style="margin-bottom: 10px;"><b>{{DailyStudyItem.CommentHeadline}}</b></h1>
                    <div class="text-center show-more"><b>הנה מה שאחרים כתבו</b></div>
                    <!-- <h1 class="header-margin text-center" style="margin-bottom: 20px;margin-top: 5px !important;"><b>הנה מה שאחרים כתבו</b></h1> -->
                    <div class="listing-item shadow-comment-listing-box" *ngFor="let Comment of Comments">
                        <small *ngIf="Comment?.FirstName == null && Comment?.LastName == null" class="d-rtl">אורח</small>
                        <small *ngIf="Comment?.FirstName != null || Comment?.LastName != null" class="d-rtl">{{Comment?.LastName + ' ' + Comment?.FirstName}}</small>
                        <p class="word-w-break">
                        {{ Comment?.Comment }}
                        </p>
                    </div>
                    <br/>
                   <div *ngIf="TotalCommentCount > 10">
                    <div class="text-center show-more" (click)="getComments()" *ngIf="showMore">
                        <b>לקריאת תשובות נוספות</b>
                    </div>
                    <div class="text-center show-more" (click)="getComments()" *ngIf="showMore">
                    <img src="assets/Images/down-arrow.svg" />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div *ngIf="DailyStudyItem?.IsCommentList == 0 && IsCommentListFlag == true && DailyStudyItem?.IsCommentShow == 1">
                <div class=" d-rtl" *ngIf="Comments?.length > 0">
                    <div class="listing shadow-comment-listing-box">
                        <h1 class="header-margin text-center" style="margin-bottom: 10px;"><b>{{DailyStudyItem.CommentHeadline}}</b></h1>
                        <div class="text-center show-more" ><b>הנה מה שאחרים כתבו</b></div>
                        <!-- <h1 class="header-margin text-center" style="margin-bottom: 20px;margin-top: 5px !important;"><b>הנה מה שאחרים כתבו</b></h1> -->

                        <div class="listing-item shadow-comment-listing-box" *ngFor="let Comment of Comments">
                            <small *ngIf="Comment?.FirstName == null && Comment?.LastName == null" class="d-rtl">אורח</small>
                            <small *ngIf="Comment?.FirstName != null || Comment?.LastName != null" class="d-rtl">{{Comment?.LastName + ' ' + Comment?.FirstName}}</small>
                            <p class="word-w-break">
                            {{ Comment?.Comment }}
                            </p>
                        </div>
                        <br/>
                        <div *ngIf="TotalCommentCount > 10">
                        <div class="text-center show-more" (click)="getComments()" *ngIf="showMore">
                            <b>לקריאת תשובות נוספות</b>
                        </div>
                        <div class="text-center show-more" (click)="getComments()" *ngIf="showMore">
                        <img src="assets/Images/down-arrow.svg" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            <!-- Comment Section : End  -->


            <div class="Buttons" style="margin-top: 8px;" *ngIf="!DailyStudyItem?.Done">
                <div class="BtnRead" [class.Disabled]="DailyStudyItem?.Done" (click)="Done()" *ngIf="ShowBtnRead" style="width: 100% !important;">
                    <div class="Message" *ngIf="ShowTipMessage">
                        {{ Resource.Get("DoneClickHereMsg") }}
                        <div class="Arrow"></div>
                    </div>
                    <span *ngIf="App.Lang.Id == 1 && DailyStudyItem?.KindId == 4">
                        {{ Resource.Get("IReadChapter") }}
                    </span>
                    <span *ngIf="App.Lang.Id == 1 && (DailyStudyItem?.KindId == 2 || DailyStudyItem?.KindId == 7)">
                        {{ Resource.Get("Done") }}
                    </span>
                    <span *ngIf="App.Lang.Id == 1 && (DailyStudyItem?.KindId == 1 || DailyStudyItem?.KindId == 3 || DailyStudyItem?.KindId == 6 || DailyStudyItem?.KindId == 8 || DailyStudyItem?.KindId == 9 || DailyStudyItem?.KindId == 10 || DailyStudyItem?.KindId == 11 || DailyStudyItem?.KindId == 12)">
                        {{ Resource.Get("Like") }}
                    </span>
                    <div class="Icon"></div>
                </div>
            </div>
            <div class="Award" *ngIf="DailyStudyItem?.Done">
                <div class="Text">
                    <img src="assets/Images/gviah.png" />
                    {{ Resource.Get("YouRecivedPointsMsg").replace("{0}", DailyStudyItem?.EarnPoints) }}
                </div>
            </div>
            <div class="Links" *ngIf="DailyStudyItem?.Credit && DailyStudyItem?.Credit != ''" >
                <div class="Txt" [innerHTML]="DailyStudyItem.Credit | SafeHtml"></div>
            </div>
            
            </div>
        </div>
    </div>
<!-- </div> -->
