import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { AppService } from "../Services/App.service";
import { Model } from "../Services/Model.service";

@Injectable()
export class IsLoggedInResolver implements Resolve<Observable<boolean>> {
    constructor(public App: AppService, private Model: Model) { }

    public resolve(Route: ActivatedRouteSnapshot, State: RouterStateSnapshot): Observable<boolean> {
        return Observable.create(o => {
            this.Model.Users.IsLoggedIn(this.App.AppId).subscribe(response => {
                o.next(response.IsLogin);
                o.complete();
            }, err => {
                o.next(false);
                o.complete();
            });
        });
    }
}