import { Injectable, Inject, NgZone } from "@angular/core";
import { URLSearchParams, Headers, Http } from "@angular/http";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { Observer } from "rxjs/Observer";
import { AppService } from "../Services/App.service";

declare var LocalFileSystem: any;

@Injectable()
export class Users {
    private headers = new Headers({ "Content-Type": "application/json" });

    constructor(private Http: Http, @Inject("API") private API: string, private Router: Router, private App: AppService, private NgZone: NgZone) { }

    public Login(UserName: string, Password: string, School?: number, Code?: string, AddNewGroup?: boolean): Observable<any> {
        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.post(`${this.API}/Users/Login`, { App: this.App.AppId, UserName: UserName, Password: Password, School: School, AddNewGroup: AddNewGroup, Code: Code }, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public LoginAnonymous(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.post(`${this.API}/Users/LoginAnonymous`, this.App.AppId, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public LoginAnonymous2(AppId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.post(`${this.API}/Users/LoginAnonymous`, AppId, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public Logout(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.post(`${this.API}/Users/Logout`, {}, { headers: this.headers, withCredentials: true }).finally(() => this.headers.delete("Authorization")).map(p => p.json());
    }

    public Register(User: any): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        User.App = this.App.AppId;

        return this.Http.post(`${this.API}/Users/Register`, User, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public UploadUserProfilePicture(Image: File, RotationType: number, FileURL: string, UserName: string, Password: string): Observable<any> {
        let API = this.API;

        this.headers.set("Lang", this.App.Lang.Id.toString());

        if ((<any>navigator).camera) {
            let Obs = Observable.create((o: Observer<any>) => {
                (<any>window).resolveLocalFileSystemURL(FileURL, (fileEntry) => {
                    fileEntry.file((file) => {
                        (<any>window).myfreader = new FileReader();

                        if ((<any>window).myfreader.__zone_symbol__originalInstance) {
                            (<any>window).myfreader = (<any>window).myfreader.__zone_symbol__originalInstance;
                        }

                        (<any>window).myfreader.onloadend = function () {
                            // Create a blob based on the FileReader "result", which we asked to be retrieved as an ArrayBuffer
                            var blob = new Blob([new Uint8Array((<any>window).myfreader.result)], { type: "image/jpeg" });
                            var oReq = new XMLHttpRequest();

                            let PostData = new FormData();

                            PostData.append("file", blob, "ProfilePicture");
                            PostData.append("Rotation", "0");
                            PostData.append("UserName", UserName);
                            PostData.append("Password", Password);

                            oReq.open("POST", `${API}/Users/UploadUserProfilePicture`, true);

                            oReq.onload = (oEvent) => {
                                o.next(oEvent);
                            };

                            oReq.send(PostData);
                        };

                        if ((<any>window).myfreader.__zone_symbol__originalInstance) {
                            (<any>window).myfreader = (<any>window).myfreader.__zone_symbol__originalInstance;
                        }

                        (<any>window).myfreader.readAsArrayBuffer(file);

                    }, (err) => {
                        o.error(err);
                    });
                }, (err) => {
                    o.error(err);
                });

            });

            return Obs;
        }
        else {
            let PostData = new FormData();

            PostData.append("file", Image, "ProfileImage");
            PostData.append("Rotation", RotationType.toString());
            PostData.append("UserName", UserName);
            PostData.append("Password", Password);

            let Head = new Headers();

            Head.append("Lang", this.headers.get("Lang"));
            Head.append("AppId", this.headers.get("AppId"));

            return this.Http.post(`${this.API}/Users/UploadUserProfilePicture`, PostData, { headers: Head, withCredentials: true }).catch(p => {
                if (p.Status == 401) {
                    this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
                }

                return Observable.throw(p);
            });
        }
    }

    public ForgatPassword(Email: string): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        let Params = new URLSearchParams();
        Params.append("App", this.App.AppId.toString());

        this.headers.set("Lang", this.App.Lang.Id.toString());

      return this.Http.post(`${this.API}/Users/ForgatPassword`, '"' + Email + '"', { params: Params, headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public IsLoggedIn(AppId: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + AppId) != "" && localStorage.getItem("Token" + AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + AppId));
        }

        return this.Http.get(`${this.API}/Users/IsLoggedIn`, { headers: this.headers, withCredentials: true }).catch(p => {
            return Observable.throw(p);
        }).map(p => p.json());
    }

    public IsUserDone(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/Users/IsUserDone`, { headers: this.headers, withCredentials: true }).catch(p => {
            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetUserPoints(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/Users/GetUserPoints`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.Status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetUserPointsReport(): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/Users/GetUserPointsReportByGroups`, { headers: this.headers, withCredentials: true }).catch(p => {
            if (p.Status == 401) {
                this.Router.navigateByUrl("/" + this.App.AppId + "/Login");
            }

            return Observable.throw(p);
        }).map(p => p.json());
    }

    public GetLangs(): Observable<any> {
        this.headers.set("Lang", this.App.Lang.Id.toString());

        return this.Http.get(`${this.API}/Users/GetLangs`, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }

    public GetLang(Lang: number): Observable<any> {
        if (localStorage && localStorage.getItem("Token" + this.App.AppId) != "" && localStorage.getItem("Token" + this.App.AppId) != null) {
            this.headers.set("Authorization", "Bearer " + localStorage.getItem("Token" + this.App.AppId));
        }

        this.headers.set("Lang", Lang.toString());

        return this.Http.get(`${this.API}/Users/GetLang`, { headers: this.headers, withCredentials: true }).map(p => p.json());
    }
}
