import { Component, Inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { LoginService } from "../../Shared/Services/Login.service";
import { AppService } from "../../Shared/Services/App.service";
import { ResourcesService } from "../../Shared/Services/Resources.service";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";

@Component({
    selector: "SiteMenu",
    templateUrl: "./Menu.component.html",
    styleUrls: ["./Styles/Menu.component.rtl.scss", "./Styles/Menu.component.ltr.scss"]
})
export class MenuComponent {
    public MenuOpen: boolean = false;

    constructor( @Inject("MenuSubject") private MenuObserveable: Observable<any>, public LoginService: LoginService, public App: AppService, public Resource: ResourcesService, private Router: Router) {
        this.MenuObserveable.subscribe(p => {
            this.ToggleMenu();
        });

        this.Router.events.filter(p => p instanceof NavigationEnd).subscribe(p => {
            if (this.MenuOpen)
                this.ToggleMenu();
        });
    }

    public ToggleMenu(): void {
        this.MenuOpen = !this.MenuOpen;
    }

    public Logout(): void {
        this.LoginService.Logout();
    }
}