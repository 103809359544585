import { Component, Inject, HostListener, Renderer2 } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { ScrollService } from "../../Shared/Services/Scroll.service";
import { AppService } from "../../Shared/Services/App.service";

@Component({
    templateUrl: "./Master.component.html",
    styleUrls: ["./Master.component.css"]
})
export class MasterComponent {
    private Width: number = 0;
    private ScrollY: number = 0;

    constructor(private Renderer: Renderer2, @Inject("MasterSubject") private MasterObservable: Observable<any>, @Inject("SystemURL") private SystemURL: string, private App: AppService, public Scroll: ScrollService) { };

    @HostListener("window:resize")
    private OnResize(): void {
        if (window.innerWidth >= 640 && this.Width < 640) {
            window.scrollTo(0, this.Scroll.Position);
        }
        else if (window.innerWidth < 640 && this.Width > 640) {
            this.Scroll.Position = this.ScrollY;
        }

        this.ScrollY = window.scrollY;
        this.Width = window.innerWidth;
    };

    public OnScroll(Main: Element): void {
        this.Scroll.Position = Main.scrollTop;
    };
    
}