import { Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { LoginService } from "../../Shared/Services/Login.service";
import { Model } from "../../Shared/Services/Model.service";
import { Observer } from "rxjs/Observer";
import { AppService } from "../../Shared/Services/App.service";

@Component({
    templateUrl: "./Page.component.html",
    styleUrls: ["./Styles/Page.component.rtl.scss", "./Styles/Page.component.ltr.scss"]
})
export class PageComponent {
    public Page: any;
    public ProfilePicURL: SafeStyle;

    constructor( @Inject("MenuSubject") private Menu: Observer<any>, private LoginService: LoginService, public App: AppService, private ActivatedRoute: ActivatedRoute, private Model: Model, private Location: Location, private Sanitizer: DomSanitizer) {
        this.ActivatedRoute.data.subscribe(data => {
            this.Model.Pages.GetPage(data.Type).subscribe(p => {
                this.Page = p.Page;
            });
        });

        this.ProfilePicURL = this.Sanitizer.bypassSecurityTrustStyle(`url('http://herzognew.mailman.co.il/Uploads/UserProfile/${this.LoginService.UserId}.jpg')`);
    }

    public ToggleMenu(): void {
        this.Menu.next(0);
    }

    public Close(): void {
        this.Location.back();
    }

}