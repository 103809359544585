export const LANG_SPA_NAME = "Lang-3";


export const LANG_SPA_RES = {
    "AppName": "Vivir el Tanaj",
    "AppDesc": "Estudio diario de un capítulo de los Profetas",
    "LogIn": "Iniciar sesión",
    "GuestLogin": "Acceso de usuario invitado",
    "RegistredLogin": "Acceso de usuarios inscriptos",
    "LogOut": "Cerrar sesión",
    "Register": "Inscribirse",
    "Registeration": "Inscripción",
    "Unregister": "No registrado",
    "Registred": "Usuario registrado",
    "ClickHere": "Clic aquí",
    "StartHere": "Empieza aquí",
    "FirstName": "Nombre",
    "LastName": "Apellido",
    "Email": "E-mail",
    "Password": "Contraseña",
    "Mobile": "Móvil",
    "ForgatPassword": "Olvide mi contraseña",
    "Back": "Volver",
    "Continue": "Continuar",
    "Done": "Hecho",
    "Like": "Me gusta",
    "Nice": "Bien!",
    "VeryGood": "Muy bien!",
    "WellDone": "Muy bien hecho!",
    "Excellent": "Excelente!",
    "Share": "Compartir",
    "School": "Escuela",
    "Schools": "Escuelas",
    "SelectSchool": "Selecciona una escuela",
    "Chapter": "Capítulo",
    "Hours": "Horas",
    "Scores": "Puntuaciones",
    "Rating": "Clasificación",
    "Name": "Nombre",
    "AllChapters": "Todos los capitulos",
    "PersonalArea": "Area personal",
    "HighScores": "Los que lideran son",
    "About": "Acerca de",
    "Points": "Puntos",
    "OutOf": "De un total de",
    "Players": "Jugadores",
    "OmerFrankel": "Omer Frenkel",
    "IReadChapter": "Leí el capitulo",
    "LearnNextChapter": "Estudia el próximo capítulo",
    "SelectBook": "Seleciona un libro",
    "JoinGroup": "Unirse a un grupo",
    "Join": "Asociarse",
    "Code": "Código",
    "AddComment": "Agregar comentario",
    "Publish": "Publicar",
    "Next": "Siguiente",
    "Backward": "Hacia atrás",
    "NewUser": "Nuevo usuario",
    "D1": "Domingo'",
    "D2": "Lunes'",
    "D3": "Martes'",
    "D4": "Miercoles'",
    "D5": "Jueves'",
    "D6": "Viernes'",
    "D7": "Sabado'",
    "ShowMore": "Mostrar más",
    "ShowCommentsBtn": "Mostrar <strong>{0}</strong> comentarios",
    "WeekDoneMsg": "Finalizamos otra semana de  <br /> estudio!",
    "TypeYourCommentMsg": "Escribe aquí tu comentario",
    "DoneClickHereMsg": "¿Has concluido? Clic aquí para sumar puntos",
    "PasswordSentToEmailMsg": "Detalles de como entrar al sistema fueron enviados a tu e-mail!",
    "EmailNotExistMsg": "El e-mail no se encuentra en el sistema!",
    "WrongUserNameOrPasswordMsg": "Usuario o contraseña equivocados!",
    "YouRecivedPointsMsg": "Has recibido {0} puntos por este capítulo",
    "MoreIdeasMsg": "Ideas y materiales adicionales",
    "CreditsMsg": "Las preguntas las compusieron Doron Kadosh, Eitan Iair Shtresberg y la dirección de saieret jotem",
    "JoinBibleQuizMsg": "Sumarse al Jidon Hatanaj",
    "YouHaveFinishedAnotherChapterMsg": "Has terminado un capítulo más en el libro",
    "NextChapterInMsg": "El próximo capítulo se abrira en",
    "YouHaveLearnedMsg": "Has estudiado {0} capítulos",
    "OfPublishedMsg": "de {0} publicados",
    "LoginErrorOccurred": "Ocurrio un error al entrar al sistema, intenta nuevamente!",
    "RegisterErrorOccurred": "Ocurrio un error al realizar la inscripción, intenta nuevamente",
    "JoinedGroupMsg": "Te uniste con éxito al grupo {0}",
    "UserPlayGroupExistMsg": "Tu ya perteneces a este grupo {0}",
    "GroupNotFoundMsg": "No se encontro el grupo!",
    "EnterGroupCode": "Ingrese un código de grupo",
    "ToLearning": "A aprender",
    "CreateGroupAndGoMsg": "Crear un grupo de estudio y comiencen",
    "WhatIsYourGroupName": "¿Cuál es el nombre de tu grupo?",
    "CreateGroup": "Crear un grupo",
    "CreatePlayGroup": "Crear un grupo de estudio",
    "YouCantJoinMultipleGroupsMsg": "No puedes unirte a más de un grupo.",
    "YouMustJoinGroupMsg": "Debes unirte o crear un grupo de estudio.",
    "PlayGroupCodeNotFound": "¡El código de grupo no existe en el sistema!",
    "NewGroupCreatedMsg": "¡Has creado un nuevo grupo de estudio!",
    "GroupCode": "Código de grupo",
    "CopyCode": "Copiar código",
    "InviteFriendsByShare": "Invita amigos compartiendo el grupo.",
    "ShareGroupLink": "Comparte un enlace a un grupo",
    "ShowGroup": "Ver grupo",
    "CodeCopied": "¡Código copiado!",
    "Group": "Grupo",
    "TotalGroupPoints": "Puntaje total del grupo",
    "GroupMembers": "Miembros del grupo",
    "GroupAdmin": "Administrador del grupo",
    "MyPoints": "Mis puntos",
    "GroupPoints": "Puntos del grupo",
    "You": "Tú",
    "MyGroup": "Mi grupo",
    "ForgatGroupCode": "¿Olvidó su código de grupo? Haga click aqui",
    "Month": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ]
};

export const LANG_POR_RES1 = {
    "AppName": "Vivir el Tanaj",
    "AppDesc": "Estudio diario de un capítulo de los Profetas",
    "LogIn": "Iniciar seção",
    "GuestLogin": "Acceso de usuario invitado",
    "RegistredLogin": "Acesso de usuários inscritos",
    "LogOut": "Encerrar seção",
    "Register": "Inscrever-se",
    "Registeration": "Inscrição",
    "Unregister": "Não está inscrito? ",
    "Registred": "Acesso de usuários registrados",
    "ClickHere": "Clique aqui",
    "StartHere": "Empieza aquí",
    "FirstName": "Nome",
    "LastName": "Sobrenome",
    "Email": "E-mail",
    "Password": "Senha",
    "Mobile": "Celular",
    "ForgatPassword": "Esqueci minha senha",
    "Back": "Voltar",
    "Continue": "Continuar",
    "Done": "Hecho",
    "Like": "Me gusta",
    "Nice": "Bien!",
    "VeryGood": "Muy bien!",
    "WellDone": "Muy bien hecho!",
    "Excellent": "Excelente!",
    "Share": "Compartir",
    "School": "Escola",
    "Schools": "Escolas",
    "SelectSchool": "Selecciona una escuela",
    "Chapter": "Capítulo",
    "Hours": "Horas",
    "Scores": "Pontuação",
    "Rating": "Classificação",
    "Name": "Nome",
    "AllChapters": "Todos capítulos",
    "PersonalArea": "Área pessoal",
    "HighScores": "Ranking",
    "About": "Sobre",
    "Points": "pontos",
    "OutOf": "de um total de",
    "Players": "Jogadores",
    "OmerFrankel": "Omer Frenkel",
    "IReadChapter": "Leí el capitulo",
    "LearnNextChapter": "Estudia el próximo capítulo",
    "SelectBook": "Escolha um livro",
    "JoinGroup": "Unirse a un grupo",
    "Join": "Asociarse",
    "Code": "Código",
    "AddComment": "Agregar comentario",
    "Publish": "Publicar",
    "Next": "Siguiente",
    "Backward": "Hacia atrás",
    "NewUser": "Novo usuário",
    "D1": "Domingo'",
    "D2": "Lunes'",
    "D3": "Martes'",
    "D4": "Miercoles'",
    "D5": "Jueves'",
    "D6": "Viernes'",
    "D7": "Sabado'",
    "ShowMore": "Mostrar más",
    "ShowCommentsBtn": "Mostrar <strong>{0}</strong> comentarios",
    "WeekDoneMsg": "Finalizamos otra semana de  <br /> estudio!",
    "TypeYourCommentMsg": "Escribe aquí tu comentario",
    "DoneClickHereMsg": "Terminou? Clique para somar pontos",
    "PasswordSentToEmailMsg": "Detalhes de como entrar no sistema foram enviados ao seu E-mail",
    "EmailNotExistMsg": "E-mail não cadastrado no sistema",
    "WrongUserNameOrPasswordMsg": "Usuario o contraseña equivocados!",
    "YouRecivedPointsMsg": "Você recebeu {0} pontos neste capítulo",
    "MoreIdeasMsg": "Ideas y materiales adicionales",
    "CreditsMsg": "Las preguntas las compusieron Doron Kadosh, Eitan Iair Shtresberg y la dirección de saieret jotem",
    "JoinBibleQuizMsg": "Sumarse al Jidon Hatanaj",
    "YouHaveFinishedAnotherChapterMsg": "Has terminado un capítulo más en el libro",
    "NextChapterInMsg": "El próximo capítulo se abrira en",
    "YouHaveLearnedMsg": "Voçê estudou {0} capítulos",
    "OfPublishedMsg": "de {0} publicações",
    "LoginErrorOccurred": "Ocurrio un error al entrar al sistema, intenta nuevamente!",
    "RegisterErrorOccurred": "Ocurrio un error al realizar la inscripción, intenta nuevamente",
    "JoinedGroupMsg": "Te uniste con éxito al grupo {0}",
    "UserPlayGroupExistMsg": "Tu ya perteneces a este grupo {0}",
    "GroupNotFoundMsg": "No se encontro el grupo!",
    "EnterGroupCode": "Ingrese un código de grupo",
    "ToLearning": "A aprender",
    "CreateGroupAndGoMsg": "Crear un grupo de estudio y comiencen",
    "WhatIsYourGroupName": "¿Cuál es el nombre de tu grupo?",
    "CreateGroup": "Crear un grupo",
    "CreatePlayGroup": "Crear un grupo de estudio",
    "YouCantJoinMultipleGroupsMsg": "No puedes unirte a más de un grupo.",
    "YouMustJoinGroupMsg": "Debes unirte o crear un grupo de estudio.",
    "PlayGroupCodeNotFound": "¡El código de grupo no existe en el sistema!",
    "NewGroupCreatedMsg": "¡Has creado un nuevo grupo de estudio!",
    "GroupCode": "Código de grupo",
    "CopyCode": "Copiar código",
    "InviteFriendsByShare": "Invita amigos compartiendo el grupo.",
    "ShareGroupLink": "Comparte un enlace a un grupo",
    "ShowGroup": "Ver grupo",
    "CodeCopied": "¡Código copiado!",
    "Group": "Grupo",
    "TotalGroupPoints": "Puntaje total del grupo",
    "GroupMembers": "Miembros del grupo",
    "GroupAdmin": "Administrador del grupo",
    "MyPoints": "Mis puntos",
    "GroupPoints": "Puntos del grupo",
    "You": "Tú",
    "MyGroup": "Mi grupo",
    "ForgatGroupCode": "¿Olvidó su código de grupo? Haga click aqui",
    "Month": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ]
};
